import React, { useEffect } from "react";
import { BigTitle, TitleMobile } from "../title";
import { 
    WALL_SIZES,
    WALL_SIZES_TITLE,
    WALL_PERIMETER,
    WALL_HEIGHT,
    WINDOW_HALL,
    WIDTH_HALL,
    HEIGHT_HALL,
    DOOR_HALL,
    LEVELING_LAYER,
} from "../constants";
import { Box } from "@material-ui/core";
import { BlackText, LabelTextBig } from "../text";
import { TextInput } from "../text-input";
import { 
    PlusButton,
    MinusButton
} from "../plus-minus-button";
import { useDispatch, useSelector } from "react-redux";
import { getInputsValue, getWindowHoles, getDoorHoles } from "../../store/selectors";
import { 
    INPUT_CHANGE, 
    ADD_WINDOW_HOLE, 
    REMOVE_WINDOW_HOLE,
    WINDOW_INPUT_CHANGE,
    ADD_DOOR_HOLE,
    REMOVE_DOOR_HOLE,
    DOOR_INPUT_CHANGE
 } from "../../store/sizes/sizes-constants"
 import { getSquareWall } from "../../utils";
import { SliderBox } from "../floor-sizes/styled";
import { PrettoSlider } from "../slider";
import { MARKS } from "./constants";




export const WallSizes = () => {

    const dispatch = useDispatch();

    const inputsValue = useSelector(getInputsValue)
    const winndowHoles = useSelector(getWindowHoles);
    const doorHoles = useSelector(getDoorHoles);

    useEffect(() => {
        dispatch({
            type: INPUT_CHANGE,
            name: "wallSquare",
            value: getSquareWall({
                perimeter: parseInt(inputsValue.wallPerimeter),
                HWall: parseInt(inputsValue.wallHeight),
                windows: winndowHoles,
                doors: doorHoles,
            })
        })
    },[dispatch, doorHoles, inputsValue.wallHeight, inputsValue.wallPerimeter, winndowHoles])

    const inputHandler = (value: string | number, name: string) => {
        dispatch({
            type: INPUT_CHANGE,
            name,
            value,
        })
    } 

    const holeInputHandler = (id: number, value: string | number, name: string) => {
        dispatch({
            type: WINDOW_INPUT_CHANGE,
            id,
            name,
            value,
        })
    } 

    const doorHoleInputHandler = (id: number, value: string | number, name: string) => {
        dispatch({
            type: DOOR_INPUT_CHANGE,
            id,
            name,
            value,
        })
    } 

    return (
        <>
        <Box alignSelf="center"> <BigTitle mt={10} mb={30}>{WALL_SIZES_TITLE}</BigTitle></Box>
        <TitleMobile mt={10} mb={30}>{WALL_SIZES_TITLE}</TitleMobile>
        <Box width="100%">
            <BlackText mb={9}>{WALL_SIZES}</BlackText>
            <Box display="flex" width="80%" justifyContent="space-between" flexWrap="wrap" marginBottom="30px" >
                <Box display="flex" marginBottom="10px" flex="1" marginRight={10} alignItems="center">
                    <LabelTextBig mr={15} mb={7}>{WALL_PERIMETER}(м)</LabelTextBig>  
                    <TextInput 
                        type="number"
                        name="wallPerimeter" 
                        value={inputsValue.wallPerimeter} 
                        onChange={(value) => inputHandler(value, "wallPerimeter")}
                        
                    />
                </Box>
                <Box display="flex" marginBottom="10px" flex="1" alignItems="center">
                    <LabelTextBig mr={15} mb={7}>{WALL_HEIGHT}(м)</LabelTextBig>  
                    <TextInput 
                        type="number"
                        name="wallHeight" 
                        value={inputsValue.wallHeight}
                        onChange={(value) => inputHandler(value, "wallHeight")}
                        
                    />
                </Box>
            </Box>
            <Box marginBottom="27px">
                <LabelTextBig mb={7}>{LEVELING_LAYER}(мм)</LabelTextBig>
                <SliderBox  marginTop ="40px" marginBottom="10px">
                    <PrettoSlider
                        aria-label="Temperature"
                        valueLabelDisplay="off"
                        step={5}
                        min={5}
                        max={50}
                        marks={MARKS}
                        value={inputsValue.levelingLayer}
                        onChange={(e,value) =>  dispatch({
                            type: INPUT_CHANGE,
                            name: "levelingLayer",
                            value: value,
                        })}
                    />
                </SliderBox>
                
            </Box>
            <Box display="flex" flex="1" alignItems="center" marginBottom="30px">
                    <BlackText mb={9} mr={30}>{WINDOW_HALL}</BlackText>
                    <Box marginRight="17px"><PlusButton onClick={()=> dispatch({
                        type: ADD_WINDOW_HOLE
                    })}/></Box>
                    <Box><MinusButton onClick={()=> dispatch({
                        type: REMOVE_WINDOW_HOLE
                    })}/></Box>
            </Box>
            {winndowHoles.map((windowHole, i)=> 
                <Box key={`${WALL_SIZES_TITLE}${i}`} display="flex" flexWrap="wrap" width="80%" justifyContent="space-between" marginBottom="30px">
                    <Box display="flex" marginBottom="10px" flex="1" marginRight={10} alignItems="center">
                        <LabelTextBig mr={15} mb={7}>{WIDTH_HALL}(м)</LabelTextBig>  
                        <TextInput 
                            type="number"
                            name="windowWidth"
                            value={windowHole.windowWidth} 
                            onChange={(value) => holeInputHandler(i, value, "windowWidth")}
                            
                        />
                    </Box>
                    <Box display="flex" marginBottom="10px" flex="1" alignItems="center">
                        <LabelTextBig mr={15} mb={7}>{HEIGHT_HALL}(м)</LabelTextBig>  
                        <TextInput 
                            type="number"
                            name="windowHeight"
                            value={windowHole.windowHeight} 
                            onChange={(value) => holeInputHandler(i, value, "windowHeight")}
                            
                        />
                    </Box>
                </Box>
            )}
             <Box display="flex" flex="1" alignItems="center" marginBottom="30px">
                    <BlackText mb={9} mr={30}>{DOOR_HALL}</BlackText>
                    <Box marginRight="17px"><PlusButton onClick={()=> dispatch({
                        type: ADD_DOOR_HOLE
                    })}/></Box>
                    <Box><MinusButton onClick={()=> dispatch({
                        type: REMOVE_DOOR_HOLE
                    })}/></Box>
            </Box>
             {doorHoles.map((doorHole, i)=> 
                <Box key={`${WALL_SIZES_TITLE}${i}`} display="flex" flexWrap="wrap" width="80%" justifyContent="space-between" marginBottom="30px">
                    <Box display="flex" marginBottom="10px" flex="1" marginRight={10} alignItems="center">
                        <LabelTextBig mr={15} mb={7}>{WIDTH_HALL}(м)</LabelTextBig>  
                        <TextInput 
                            type="number"
                            name="doorWidth"
                            value={doorHole.doorWidth} 
                            onChange={(value) => doorHoleInputHandler(i, value, "doorWidth")}
                            
                        />
                    </Box>
                    <Box display="flex" marginBottom="10px" flex="1" alignItems="center">
                        <LabelTextBig mr={15} mb={7}>{HEIGHT_HALL}(м)</LabelTextBig>  
                        <TextInput 
                            type="number"
                            name="doorHeight"
                            value={doorHole.doorHeight} 
                            onChange={(value) => doorHoleInputHandler(i, value, "doorHeight")}
                            
                        />
                    </Box>
                </Box>
            )}
        </Box>
        </>
    )
    
}