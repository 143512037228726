import React from "react";
import { PageWrapper } from "../../components/page-wrapper";
import { Content } from "../../components/page-content";
import { SelectLayers } from "../../components/select-layers";
import { SelectGrout } from "../../components/select-grout";
import { useSelector } from "react-redux";
import { groutIsChecked } from "../../store/selectors";
import { ButtonNext } from "../../components/button-next";
import { Box } from "@material-ui/core";

export const LayersPage = () => {
    const showGrout = useSelector(groutIsChecked)
    return (
        <PageWrapper>
            <Content>
                <SelectLayers />
                {showGrout && <SelectGrout />}
                <Box display="flex" justifyContent="stretch" width="100%">
                    <ButtonNext path="/calculate"/>
                    <Box flex={1}/>
                    <Box flex={1}/>
                </Box>
            </Content>
        </PageWrapper>
    )
}