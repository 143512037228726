import { Box } from "@material-ui/core";
import styled from "styled-components";

interface ILayersImage {
    img: string;
}

export const SelectItemsContainer = styled.div`
    width: auto;

    @media (min-width: 320px){
        width: auto;
    }

    @media (min-width: 1490px){
        width: 480px;
    }


`;


export const SelectItemsBox = styled(Box)`
    flex-direction: row;
    justify-content: center;
    display: flex; 
    flex-wrap: wrap;
`;

export const LayresImage = styled.div<ILayersImage>`
    background: url(${({img}) => img}) no-repeat center/100%;
    position: relative;

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 286px;
            height: 212px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 336px;
            height: 249px;   
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 371px;
            height: 275px;
        }
    };


    @media (min-width: 480px){
        width: 635px;
        height: 470px;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 430px;
            height: 319px;
        }
    };

    @media (min-width: 768px){
        width: 650px;
        height: 470px;
    };

    @media (min-width: 1024px){
        width: 841px;
        height: 600px;      
    };

    @media (min-width: 1280px){
        width: 777px;
        height: 580px;
    };

    @media (min-width: 1920px){
        width: 1164px;
        height: 800;
    };
`;