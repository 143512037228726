import styled from "styled-components";
import { IWrapperProps } from "./types"

export const Wrapper = styled.div<IWrapperProps>`
    height: 183px;
    display: flex;
    padding: 17px;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #0070BA;
    box-sizing: border-box;
    box-shadow: 7px 7px 11px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    position: absolute;
    ${({position}) => position === "left" 
        ? "right: calc(100% + 10px)"
        : "left: calc(100% + 10px)"
    };
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    @media (min-width: 320px){
        @media (min-height: 480px){
            height: 53px;
            font-weight: 400;
            font-size: 10px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            height: 59px;
            font-weight: 400;
            font-size: 11px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            height: 65px;
            font-weight: 400;
            font-size: 12px;
        }
    };

    @media (min-width: 480px){
        height: 101px;
        font-weight: 400;
        font-size: 16px;
    }; 

    @media (min-width: 480px){
        @media (min-height: 800px){
            height: 75px;
            font-weight: 400;
            font-size: 14px;
        }
    };

    @media (min-width: 768px){
        height: 101px;
        font-weight: 400;
        font-size: 16px;
    }; 

    @media (min-width: 1024px){
        height: 136px;
        font-weight: 400;
        font-size: 24px;
    };  

    @media (min-width: 1280px){
        height: 122px;
        font-weight: 400;
        font-size: 24px;
    };  

    @media (min-width: 1920px){
        height: 184px;
        font-weight: 400;
        font-size: 24px;
    };  
`;

export const Image = styled.img`

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 27px;
            height: 24px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 30px;
            height: 26px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 34px;
            height: 29px;
        }
    };

    @media (min-width: 480px){
        width: 53px;
        height: 46px;
    }; 

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 39px;
            height: 34px;
        }
    };

    @media (min-width: 768px){
        width: 53px;
        height: 46px;
    }; 

    @media (min-width: 1024px){
        width: 72px;
        height: 62px;
    };  

    @media (min-width: 1280px){
        width: 64px;
        height: 56px;
    };  

    @media (min-width: 1920px){
        width: 96px;
        height: 84px;
    };  
`;