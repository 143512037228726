import { 
    BATHROOM,
    LIVINGROOM,
    KITCHEN,
    CHILDREN_ROOM,
    BEDROOM,
    HALLWAY,
    BALCONY,
    LOGGIA,
    PAINT,
    TILE_HOT_FLOOR,
    LVT_TILE,
    PALLETE,
    PARQUET,
    CARPET,
    LAMINATE,
    WALLPERS,
    TABLE_TOP_TITLE,
    SKIRTING_TITLE,
    LVT,
    TILE,
    FLOOR_TILE,
} from "./constants";
import { 
    square,
    wallSquare,
    elevationDifferences,
    tailLength,
    tailWidth,
    tailThickness,
    jointWidth,
    floorLength,
    floorWidth,
    palleteLength,
    palleteWidth,
    palleteWallPerimetr,
    tableTopWidth,
    tableTopHeight,
    skirtingHeight,
    skirtingLenght,
    levelingLayer,
 } from "../store/sizes/sizes-constants";
import {
    getGlueForTail,
    getPriming, getSelfLevelingFloor, getSutureFiller,
    getLevelingCompaund,
    getPrimingInHalf,
    getSelfLevelingFloorForRooms,
    getPlaster,
    getSelfLevelingFloorForBedRoom,
    getPaint,
    getSealantCount,
    getSealantCountSquare,
    getSealantCountForSkirt,
    getGlueTailForLogia,
    getPlasterWithlevelingLayer
} from "../utils";
import material2 from "../static/img/material2.png"



export const data = {
    [BATHROOM]: {
        [LVT_TILE]: [
            {
                uid: 0,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 1,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                heatFloor: false,
                id: 4,
                units: "шт",
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "coeff"]
            },
            {
                uid: 3,
                type: "Гидроизоляция",
                material: "Mapelastic aquaDefense + лента mapeband easy",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-aquadefense-15kg-int.jpg?sfvrsn=68cce777_0",
                id: 5,
                units: "шт",
                coeff: 1,
                subMaterial:[
                    {
                        uid: 4,
                        type: "Гидроизоляция",
                        material: "Mapelastic aquaDefense",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-aquadefense-15kg-int.jpg?sfvrsn=68cce777_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapelastic-aquadefense"],
                        id: 5,
                        coeff: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"],
                    },
                    {
                        uid: 5,
                        type: "Гидроизоляция",
                        material: "лента mapeband easy",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/7631-mapeband-easy-30m-int582a9d7879c562e49128ff01007028e9.png?sfvrsn=4158339b_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapeband-easy"],
                        id: 6,
                        coeff: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"],
                    }
                ],
            },
            {
                uid: 6,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 6,
                units: "шт",
                formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 7,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 8,
                type: "Герметик силиконовый",
                material: "Mapesil AC",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                id: 8,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [TILE_HOT_FLOOR]: [
            {
                uid: 9,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 10,
                type: "Наливной пол",
                material: "Novoplan Maxi R",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                coeff: 1.8,
                id: 4,
                units: "шт",
                heatFloor: true,
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "coeff"]
            },
            {
                uid: 11,
                type: "Гидроизоляция",
                material: "Mapelastic aquaDefense + лента mapeband easy",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-aquadefense-15kg-int.jpg?sfvrsn=68cce777_0",
                id: 5,
                units: "шт",
                coeff: 1,
                subMaterial:[
                    {
                        uid: 12,
                        type: "Гидроизоляция",
                        material: "Mapelastic aquaDefense",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-aquadefense-15kg-int.jpg?sfvrsn=68cce777_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapelastic-aquadefense"],
                        id: 5,
                        coeff: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"],
                    },
                    {
                        uid: 13,
                        type: "Гидроизоляция",
                        material: "лента mapeband easy",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/7631-mapeband-easy-30m-int582a9d7879c562e49128ff01007028e9.png?sfvrsn=4158339b_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapeband-easy"],
                        id: 6,
                        coeff: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"],
                    }
                ],
            },
            {
                uid: 14,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 6,
                units: "шт",
                formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 15,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 16,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 8,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [PALLETE]: [
            {
                uid: 17,
                type: "Адгезивный раствор",
                material: "topcem+planicrete",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                id: 1,
                units: "шт",
                subMaterial:[
                    {
                        uid: 18,
                        type: "Адгезивный раствор",
                        material: "planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        coeff: 0.3,
                        id: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, palleteLength, palleteWidth, "coeff"]
                    },
                    {
                        uid: 19,
                        type: "Адгезивный раствор",
                        material: "topcem",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/207-topcem-20kg-int_0c49e07c6465466b9c3cb70556a26ff2.png?sfvrsn=a0e19510_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem"],
                        coeff: 0.9,
                        id: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, palleteLength, palleteWidth, "coeff"]
                    },
                ]
            },
            {
                uid: 20,
                type: "Полусухая стяжка",
                material: "Topcem Pronto",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto"],
                coeff: 20,
                id: 2,
                units: "шт",
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, palleteLength, palleteWidth, "heatFloor", "coeff"]
            },
            {
                uid: 21,
                type: "Гидроизоляция",
                material: "Mapelastic aquaDefense+лента mapeband easy",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-aquadefense-15kg-int.jpg?sfvrsn=68cce777_0",
                id: 3,
                units: "шт",
                coeff: 1,
                subMaterial:[
                    {
                        uid: 22,
                        type: "Гидроизоляция",
                        material: "Mapelastic aquaDefense",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-aquadefense-15kg-int.jpg?sfvrsn=68cce777_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapelastic-aquadefense"],
                        id: 5,
                        coeff: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, palleteLength, palleteWidth, "coeff"],
                    },
                    {
                        uid: 23,
                        type: "Гидроизоляция",
                        material: "лента mapeband easy",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/7631-mapeband-easy-30m-int582a9d7879c562e49128ff01007028e9.png?sfvrsn=4158339b_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapeband-easy"],
                        id: 5,
                        coeff: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [palleteWallPerimetr, null, null, "coeff"],
                    }
                ],
            },
            // {
            //     uid: 24,
            //     type: "Клей для плитки",
            //     material: "Adesilex P9",
            //     img: material2,
            //     id: 4,
            //     units: "шт",
            //     formula: getGlueForTail,
            //     params: [square, floorLength, floorWidth, tailWidth, tailLength]
            // },
            // {
            //     uid: 25,
            //     type: "Заполнитель шовный",
            //     material: "Ultracolor Plus",
            //     img: material2,
            //     coeff: 1.6,
            //     id: 6,
            //     units: "шт",
            //     formula: getSutureFiller,
            //     params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            // },
            {
                uid: 26,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 7,
                coeff: 25,
                units: "шт",
                formula: getSealantCount,
                params: [palleteWallPerimetr, "coeff"]
            },
        ],
        [PAINT]: [
            {
                uid: 27,
                type: "Штукатурный состав",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                material: "Nivoplan Plus+Planicrete",
                id: 12,
                units: "шт",
                subMaterial:[
                    {
                        uid: 28,
                        type: "Штукатурный состав",
                        material: "Nivoplan Plus",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus"],
                        coeff: 16,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                    {
                        uid: 29,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        coeff: 0.75,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                ]
            },
            {
                uid: 30,
                type: "Декоративная штукатурка",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/4_planitop_mineral_graphito_2-5_white_2021_f0f855810b584b1bad7318eae91a64b5.png?sfvrsn=87ffb32b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planitop-mineral-graffiato"],
                material: "Planitop Mineral Graffiato",
                coeff: 2.8,
                id: 13,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 31,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/malech.jpg?sfvrsn=53619d99_2",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/malech"],
                material: "Malech",
                coeff: 0.15,
                id: 10,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 32,
                type: "Краска",
                material: "ELASTOCOLOR PAINT/DURSILITE",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/329-elastocolor-pittura.png?sfvrsn=8164f7a3_0",
                link: [
                    "https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/elastocolor-paint",
                    "https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/dursilite"
                ],
                coeff: 0.4,
                id: 11,
                units: "шт",
                formula: getPaint,
                params: [wallSquare, "coeff"]
            },
        ],
        [TILE]: [
            {
                uid: 9,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 11,
                type: "Гидроизоляция",
                material: "Mapelastic aquaDefense + лента mapeband easy",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-aquadefense-15kg-int.jpg?sfvrsn=68cce777_0",
                id: 5,
                units: "шт",
                coeff: 1,
                subMaterial:[
                    {
                        uid: 12,
                        type: "Гидроизоляция",
                        material: "Mapelastic aquaDefense",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-aquadefense-15kg-int.jpg?sfvrsn=68cce777_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapelastic-aquadefense"],
                        id: 5,
                        coeff: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"],
                    },
                    {
                        uid: 13,
                        type: "Гидроизоляция",
                        material: "лента mapeband easy",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/7631-mapeband-easy-30m-int582a9d7879c562e49128ff01007028e9.png?sfvrsn=4158339b_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapeband-easy"],
                        id: 6,
                        coeff: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"],
                    }
                ],
            },
            {
                uid: 14,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 6,
                units: "шт",
                formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 15,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 16,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 8,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
    },
    [LIVINGROOM]: {
        [LVT_TILE]: [
            {
                uid: 33,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 34,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                id: 4,
                units: "шт",
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "coeff"]
            },
            {
                uid: 35,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 5,
                units: "шт",
                 formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 36,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 37,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 8,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [TILE_HOT_FLOOR]: [
            {
                uid: 38,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 39,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                heatFloor: true,
                id: 4,
                units: "шт",
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "coeff"]
            },
            {
                uid: 40,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 5,
                units: "шт",
                 formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 41,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 42,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 8,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [PARQUET]: [
            {
                uid: 43,
                type: "Полусухая стяжка",
                material: "Topcem Pronto",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto"],
                coeff: 20,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 44,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 4,
                units: "шт",
                formula: getPrimingInHalf,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 45,
                type: "Наливной пол",
                material: "Ultraplan Eco",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/513-ultraplan-eco_4210f4ad31a24873a84cfa60f17aea17.png?sfvrsn=3d72afbf_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultraplan-eco'],
                coeff: 1.6,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForRooms,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 46,
                type: "Клей для деревянных покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/279-ultrabond-p990-1k.png?sfvrsn=3abfa0c8_0',
                material: "Ultrabond P990 1K/ Ultrabond S9550 1K",
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-p990-1k',
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-s955-1k'
                ],
                coeff: 1.2,
                id: 7,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [CARPET]: [
            {
                uid: 47,
                type: "Полусухая стяжка",
                material: "Topcem Pronto",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto"],
                coeff: 20,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 48,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 4,
                units: "шт",
                formula: getPrimingInHalf,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 49,
                type: "Наливной пол",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/513-ultraplan-eco_4210f4ad31a24873a84cfa60f17aea17.png?sfvrsn=3d72afbf_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultraplan-eco'],
                material: "Ultraplan Eco",
                coeff: 1.6,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForRooms,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 50,
                type: "Клей для гибких покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/5855-ultrabond-eco-vs90-plus-14kg-int-9458632_83c6689a14fb4276b0f3e9313d70793e.png?sfvrsn=3d72f593_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-vs90-plus'],
                material: "Ultrabond Eco VS90 Plus",
                coeff: 0.35,
                id: 4,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [LAMINATE]: [
            {
                uid: 51,
                type: "Полусухая стяжка",
                material: "Topcem Pronto",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto"],
                coeff: 20,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 52,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 4,
                units: "шт",
                formula: getPrimingInHalf,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 53,
                type: "Наливной пол",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/513-ultraplan-eco_4210f4ad31a24873a84cfa60f17aea17.png?sfvrsn=3d72afbf_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultraplan-eco'],
                material: "Ultraplan Eco",
                coeff: 1.6,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForRooms,
                params: [square, floorLength, floorWidth,  elevationDifferences, "coeff"]
            },
            {
                uid: 54,
                type: "Клей для деревянных покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/279-ultrabond-p990-1k.png?sfvrsn=3abfa0c8_0',
                material: "Ultrabond P990 1K/ Ultrabond S9550 1K",
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-p990-1k',
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-s955-1k'
                ],
                coeff: 1.2,
                id: 7,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [WALLPERS]: [
            {
                uid: 55,
                type: "Штукатурный состав",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                material: "Nivoplan Plus+Planicrete",
                id: 7,
                subMaterial:[
                    {
                        uid: 1000,
                        type: "Штукатурный состав",
                        material: "Nivoplan Plus",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus"],
                        coeff: 16,
                        id: 7,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                    {
                        uid: 1001,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        coeff: 0.75,
                        id: 7,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                ]
            },
            {
                uid: 56,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-rtu_5kg_2019.jpg?sfvrsn=1eaffa89_0',
                material: "Primer RTU",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-rtu'],
                coeff: 0.2,
                id: 10,
                units: "шт",
                formula: getPrimingInHalf,
                params: [wallSquare, "coeff"]
            },
            {
                uid: 57,
                type: "Клей для обоев",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/281-adesilex-mt32-10kg-int.png?sfvrsn=50ef69f6_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-mt32"],
                material: "Adesilex MT32",
                coeff: 0.25,
                id: 11,
                units: "шт",
                formula: getPlaster,
                params: [wallSquare, "coeff"]
            },
        ],
        [PAINT]: [
            {
                uid: 58,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 10,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 59,
                type: "Выравнивающий состав",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapetherm-ar2_5798e442139849d2846f65a36db455cb.jpg?sfvrsn=e8545772_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapetherm-ar2'],
                material: "MAPETHERM AR2",
                coeff: 1.5,
                id: 11,
                units: "шт",
                formula: getLevelingCompaund,
                params: [wallSquare, "coeff"]
            },
            {
                uid: 60,
                type: "Cетка",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/941-mapetherm-net_17d3cbb2b0184b34afbff65b3be12784.png?sfvrsn=aa8f748b_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapetherm-net'],
                material: "MAPETHERM net",
                id: 12,
                coeff: 1,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 61,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 13,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 62,
                type: "Краска",
                material: "ELASTOCOLOR PAINT/DURSILITE",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/329-elastocolor-pittura.png?sfvrsn=8164f7a3_0',
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/elastocolor-paint',
                    "https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/dursilite"
                ],
                id: 14,
                units: "шт",
                coeff: 0.4,
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
        ],
    },
    [KITCHEN]: {
        [LVT_TILE]: [
            {
                uid: 63,
                type: "Полусух стяжка",
                material: "Topcem Pronto",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto"],
                coeff: 20,
                id: 2,
                units: "шт",
                additionParametr: 1,
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "additionParametr", "coeff"]
            },
            {
                uid: 64,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.1,
                id: 3,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 65,
                type: "Наливной пол",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultraplan-eco20.jpg?sfvrsn=fd43ddde_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultraplan-eco-20'],
                material: "Ultraplan Eco 20",
                coeff: 1.6,
                id: 4,
                units: "шт",
                additionParametr: 1,
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "additionParametr", "coeff"]
            },
            {
                uid: 66,
                type: "Клей для LVT",
                material: "Ultrabond ECO 4 LVT",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/6504-ultrabond-eco-4-lvt.png?sfvrsn=843ea2df_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-4-lvt'],
                id: 5,
                units: "шт",
                coeff: 0.3,
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [TILE_HOT_FLOOR]: [
            {
                uid: 67,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 68,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                heatFloor: true,
                id: 4,
                units: "шт",
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "coeff"]
            },
            {
                uid: 69,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 5,
                units: "шт",
                 formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 70,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 71,
                type: "Герметик силиконовый",
                material: "Mapesil AC",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                id: 8,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [PAINT]: [
            {
                uid: 72,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 10,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 73,
                type: "Выравнивающий состав",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapetherm-ar2_5798e442139849d2846f65a36db455cb.jpg?sfvrsn=e8545772_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapetherm-ar2'],
                material: "MAPETHERM AR2",
                coeff: 1.5,
                id: 11,
                units: "шт",
                formula: getLevelingCompaund,
                params: [wallSquare, "coeff"]
            },
            {
                uid: 74,
                type: "Cетка",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/941-mapetherm-net_17d3cbb2b0184b34afbff65b3be12784.png?sfvrsn=aa8f748b_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapetherm-net'],
                material: "MAPETHERM net",
                id: 12,
                coeff: 1,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 75,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 13,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 76,
                type: "Краска",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/329-elastocolor-pittura.png?sfvrsn=8164f7a3_0',
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/elastocolor-paint',
                    "https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/dursilite"
                ],
                material: "ELASTOCOLOR PAINT/DURSILITE",
                id: 14,
                units: "шт",
                coeff: 0.4,
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
        ],
        [WALLPERS]: [
            {
                uid: 77,
                type: "Штукатурный состав",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                material: "Nivoplan Plus+Planicrete",
                id: 7,
                subMaterial:[
                    {
                        uid: 78,
                        type: "Штукатурный состав",
                        material: "Nivoplan Plus",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus"],
                        coeff: 16,
                        id: 7,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                    {
                        uid: 79,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        coeff: 0.75,
                        id: 7,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                ]
            },
            {
                uid: 80,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-rtu_5kg_2019.jpg?sfvrsn=1eaffa89_0',
                material: "Primer RTU",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-rtu'],
                coeff: 0.2,
                id: 10,
                units: "шт",
                formula: getPrimingInHalf,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 81,
                type: "Клей для обоев",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/281-adesilex-mt32-10kg-int.png?sfvrsn=50ef69f6_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-mt32"],
                material: "Adesilex MT32",
                coeff: 0.25,
                id: 11,
                units: "шт",
                formula: getPlaster,
                params: [wallSquare, "coeff"]
            },
        ],
        [TABLE_TOP_TITLE]: [
            {
                uid: 82,
                type: "Клей для плитки",
                material: "Ultrabond Eco PU 2K",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2719-ultrabond-eco-pu-2k.png?sfvrsn=1df335c2_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-pu-2k"],
                id: 8,
                units: "шт",
                coef: 2.5,
                formula: getPriming,
                params: [null, tableTopWidth, tableTopHeight, "coeff"]
            },
            {
                uid: 83,
                type: "Заполнитель шовный",
                material: "kerapoxy easy design",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/4_4_9001-kerapoxy-easy-design-a-b-3kg-int-finale_d982159b1af544e48c7ceff9cde89525_63d54672be7246d680f86379aeec940c.png?sfvrsn=69725928_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/kerapoxy-easy-design"],
                coeff: 0.51,
                id: 10,
                units: "шт",
                formula: getSutureFiller,
                tailWidth: 10,
                tailLength: 10, 
                tailThickness: 8,
                jointWidth: 2,
                params: [square, tableTopHeight, tableTopWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
        ],    
        [SKIRTING_TITLE]: [
            {
                uid: 84,
                type: "Штукатурный состав",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                material: "Nivoplan Plus+Planicrete",
                coeff: 20,
                id: 2,
                units: "шт",
                subMaterial:[
                    {
                        uid: 85,
                        type: "Штукатурный состав",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus"],
                        material: "Nivoplan Plus",
                        id: 5,
                        coeff: 16,
                        units: "шт",
                        formula: getPriming,
                        params: [null, skirtingHeight, skirtingLenght, "coeff"],
                    },
                    {
                        uid: 86,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        id: 6,
                        coeff: 7.5,
                        units: "шт",
                        formula: getPriming,
                        params: [null, skirtingHeight, skirtingLenght, "coeff"],
                    }
                ]
            },
            {
                uid: 87,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                id: 3,
                units: "шт",
                formula: getPriming,
                params: [null, skirtingHeight, skirtingLenght, "coeff"],
            },
            {
                uid: 88,
                type: "Заполнитель шовный",
                material: "kerapoxy easy design",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/4_4_9001-kerapoxy-easy-design-a-b-3kg-int-finale_d982159b1af544e48c7ceff9cde89525_63d54672be7246d680f86379aeec940c.png?sfvrsn=69725928_0",
                link: ["]https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/kerapoxy-easy-design"],
                coeff: 0.51,
                id: 5,
                units: "шт",
                formula: getSutureFiller,
                tailWidth: 10,
                tailLength: 10, 
                tailThickness: 8,
                jointWidth: 2,
                params: [square, skirtingHeight, skirtingLenght, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 89,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 6,
                units: "шт",
                formula: getSealantCountForSkirt,
                params: [skirtingHeight, skirtingLenght],
            },
        ],        
    },
    [CHILDREN_ROOM]: {
        // [LVT_TILE]: [
        //     {
        //         uid: 90,
        //         type: "Полусухая стяжка",
        //         material: "Topcem Pronto",
        //         img: material2,
        //         coeff: 20,
        //         id: 2,
        //         units: "шт",
        //         formula: getPriming,
        //         params: [square, floorLength, floorWidth, "coeff"]
        //     },
        //     {
        //         uid: 91,
        //         type: "Грунт",
        //         img: material1,
        //         material: "Primer G",
        //         coeff: 0.2,
        //         id: 4,
        //         units: "шт",
        //         formula: getPrimingInHalf,
        //         params: [square, floorLength, floorWidth, "coeff"]
        //     },
        //     {
        //         uid: 92,
        //         type: "Наливной пол",
        //         img: material2,
        //         material: "Ultraplan Eco",
        //         coeff: 1.6,
        //         id: 5,
        //         units: "шт",
        //         formula: getSelfLevelingFloorForRooms,
        //         params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
        //     },
        //     {
        //         uid: 93,
        //         type: "Клей для плитки",
        //         material: "Adesilex P9",
        //         img: material2,
        //         id: 5,
        //         units: "шт",
        //          formula: getGlueForTail,
        //         params: [square, floorLength, floorWidth, tailWidth, tailLength]
        //     },
        //     {
        //         uid: 94,
        //         type: "Заполнитель шовный",
        //         material: "Ultracolor Plus",
        //         img: material2,
        //         coeff: 1.6,
        //         id: 7,
        //         units: "шт",
        //         formula: getSutureFiller,
        //         params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
        //     },
        // ],
        // [TILE_HOT_FLOOR]: [
        //     {
        //         uid: 95,
        //         type: "Полусухая стяжка",
        //         material: "Topcem Pronto",
        //         img: material2,
        //         coeff: 20,
        //         id: 2,
        //         units: "шт",
        //         formula: getPriming,
        //         params: [square, floorLength, floorWidth, "coeff"]
        //     },
        //     {
        //         uid: 96,
        //         type: "Грунт",
        //         img: material1,
        //         material: "Primer G",
        //         coeff: 0.2,
        //         id: 4,
        //         units: "шт",
        //         formula: getPrimingInHalf,
        //         params: [square, floorLength, floorWidth, "coeff"]
        //     },
        //     {
        //         uid: 97,
        //         type: "Наливной пол",
        //          img: material2,
        //         material: "Ultraplan Eco",
        //         coeff: 1.6,
        //         id: 5,
        //         units: "шт",
        //         formula: getSelfLevelingFloorForRooms,
        //         params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
        //     },
        //     {
        //         uid: 98,
        //         type: "Клей для плитки",
        //         material: "Adesilex P9",
        //         img: material2,
        //         id: 5,
        //         units: "шт",
        //          formula: getGlueForTail,
        //         params: [square, floorLength, floorWidth, tailWidth, tailLength]
        //     },
        //     {
        //         uid: 99,
        //         type: "Заполнитель шовный",
        //         material: "Ultracolor Plus",
        //         img: material2,
        //         coeff: 1.6,
        //         id: 7,
        //         units: "шт",
        //         formula: getSutureFiller,
        //         params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
        //     },
        // ],
        [LVT]: [
            {
                uid: 47,
                type: "Полусухая стяжка",
                material: "Topcem Pronto",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto"],
                coeff: 20,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 48,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 4,
                units: "шт",
                formula: getPrimingInHalf,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 49,
                type: "Наливной пол",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/513-ultraplan-eco_4210f4ad31a24873a84cfa60f17aea17.png?sfvrsn=3d72afbf_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultraplan-eco'],
                material: "Ultraplan Eco",
                coeff: 1.6,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForRooms,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 50,
                type: "Клей для гибких покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/5855-ultrabond-eco-vs90-plus-14kg-int-9458632_83c6689a14fb4276b0f3e9313d70793e.png?sfvrsn=3d72f593_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-vs90-plus'],
                material: "Ultrabond Eco VS90 Plus",
                coeff: 0.35,
                id: 4,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [PARQUET]: [
            {
                uid: 100,
                type: "Полусухая стяжка",
                material: "Topcem Pronto",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto'],
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0',
                coeff: 20,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 101,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                material: "Primer G",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                coeff: 0.2,
                id: 4,
                units: "шт",
                formula: getPrimingInHalf,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 102,
                type: "Наливной пол",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/513-ultraplan-eco_4210f4ad31a24873a84cfa60f17aea17.png?sfvrsn=3d72afbf_0',
                material: "Ultraplan Eco",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultraplan-eco'],
                coeff: 1.6,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForRooms,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 103,
                type: "Клей для деревянных покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/279-ultrabond-p990-1k.png?sfvrsn=3abfa0c8_0',
                material: "Ultrabond P990 1K/ Ultrabond S9550 1K",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-p990-1k'],
                coeff: 1.2,
                id: 7,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [CARPET]: [
            {
                uid: 104,
                type: "Полусухая стяжка",
                material: "Topcem Pronto",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto'],
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0',
                coeff: 20,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 105,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                material: "Primer G",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                coeff: 0.2,
                id: 4,
                units: "шт",
                formula: getPrimingInHalf,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 106,
                type: "Наливной пол",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/513-ultraplan-eco_4210f4ad31a24873a84cfa60f17aea17.png?sfvrsn=3d72afbf_0',
                material: "Ultraplan Eco",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultraplan-eco'],
                coeff: 1.6,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForRooms,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 107,
                type: "Клей для гибких покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/5855-ultrabond-eco-vs90-plus-14kg-int-9458632_83c6689a14fb4276b0f3e9313d70793e.png?sfvrsn=3d72f593_0',
                material: "Ultrabond Eco VS90 Plus",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-vs90-plus'],
                coeff: 0.35,
                id: 4,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [LAMINATE]: [
            {
                uid: 108,
                type: "Полусухая стяжка",
                material: "Topcem Pronto",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto'],
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0',
                coeff: 20,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 109,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                material: "Primer G",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                coeff: 0.2,
                id: 4,
                units: "шт",
                formula: getPrimingInHalf,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 110,
                type: "Наливной пол",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/513-ultraplan-eco_4210f4ad31a24873a84cfa60f17aea17.png?sfvrsn=3d72afbf_0',
                material: "Ultraplan Eco",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultraplan-eco'],
                coeff: 1.6,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForRooms,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 111,
                type: "Клей для деревянных покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/279-ultrabond-p990-1k.png?sfvrsn=3abfa0c8_0',
                material: "Ultrabond P990 1K/ Ultrabond S9550 1K",
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-p990-1k',
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-s955-1k'
                ],
                coeff: 1.2,
                id: 7,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [PAINT]: [
            {
                uid: 112,
                type: "Штукатурный состав",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0',
                material: "Nivoplan Plus+Planicrete",
                coeff: 20,
                id: 2,
                units: "шт",
                subMaterial:[
                    {
                        uid: 113,
                        type: "Штукатурный состав",
                        material: "Nivoplan Plus",
                        link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus'],
                        id: 5,
                        coeff: 16,
                        units: "шт",
                        formula: getPlasterWithlevelingLayer,
                        params: [wallSquare, levelingLayer, "coeff"],
                    },
                    {
                        uid: 114,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        id: 6,
                        coeff: 7.5,
                        units: "шт",
                        formula: getPlasterWithlevelingLayer,
                        params: [wallSquare, levelingLayer, "coeff"],
                    }
                ]
            },
            {
                uid: 115,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                material: "Primer G",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                coeff: 1.5,
                id: 10,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 116,
                type: "Краска",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/329-elastocolor-pittura.png?sfvrsn=8164f7a3_0',
                material: "ELASTOCOLOR PAINT/DURSILITE",
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/elastocolor-paint',
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/dursilite'
                ],
                coeff: 0.4,
                id: 11,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
        ],
        [WALLPERS]: [
            {
                uid: 117,
                type: "Штукатурный состав",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0',
                material: "Nivoplan Plus+Planicrete",
                id: 7,
                subMaterial:[
                    {
                        uid: 118,
                        type: "Штукатурный состав",
                        material: "Nivoplan Plus",
                        link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus'],
                        img: material2,
                        coeff: 16,
                        id: 7,
                        units: "шт",
                        formula: getPlasterWithlevelingLayer,
                        params: [wallSquare, levelingLayer, "coeff"],
                    },
                    {
                        uid: 119,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        coeff: 0.75,
                        id: 7,
                        units: "шт",
                        formula: getPlasterWithlevelingLayer,
                        params: [wallSquare, levelingLayer, "coeff"],
                    },
                ]
            },
            {
                uid: 120,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-rtu_5kg_2019.jpg?sfvrsn=1eaffa89_0',
                material: "Primer RTU",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-rtu'],
                coeff: 0.2,
                id: 10,
                units: "шт",
                formula: getPrimingInHalf,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 121,
                type: "Клей для обоев",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/281-adesilex-mt32-10kg-int.png?sfvrsn=50ef69f6_0',
                material: "Adesilex MT32",
                link:['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-mt32'],
                coeff: 0.25,
                id: 11,
                units: "шт",
                formula: getPlaster,
                params: [wallSquare, "coeff"]
            },
        ],
    },
    [BEDROOM]: {
        [LVT_TILE]:[
            {
                uid: 122,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 4,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 123,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForBedRoom,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 124,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 5,
                units: "шт",
                 formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 125,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
        ],
        [TILE_HOT_FLOOR]: [
            {
                uid: 126,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 4,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 127,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForBedRoom,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 128,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 5,
                units: "шт",
                 formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 129,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
        ],
        [PARQUET]: [
            {
                uid: 130,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 4,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 131,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForBedRoom,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 132,
                type: "Клей для деревянных покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/279-ultrabond-p990-1k.png?sfvrsn=3abfa0c8_0',
                material: "Ultrabond P990 1K/ Ultrabond S9550 1K",
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-p990-1k',
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-s955-1k'
                ],
                coeff: 1.2,
                id: 7,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [CARPET]: [
            {
                uid: 133,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 4,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 134,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForBedRoom,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 135,
                type: "Клей для гибких покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/5855-ultrabond-eco-vs90-plus-14kg-int-9458632_83c6689a14fb4276b0f3e9313d70793e.png?sfvrsn=3d72f593_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-vs90-plus'],
                material: "Ultrabond Eco VS90 Plus",
                coeff: 0.35,
                id: 4,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [LAMINATE]: [
            {
                uid: 136,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 4,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 137,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                id: 5,
                units: "шт",
                formula: getSelfLevelingFloorForBedRoom,
                params: [square, floorLength, floorWidth, elevationDifferences, "coeff"]
            },
            {
                uid: 138,
                type: "Клей для деревянных покрытий",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/279-ultrabond-p990-1k.png?sfvrsn=3abfa0c8_0',
                material: "Ultrabond P990 1K/ Ultrabond S9550 1K",
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-p990-1k',
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultrabond-eco-s955-1k'
                ],
                coeff: 1.2,
                id: 7,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [WALLPERS]: [
            {
                uid: 139,
                type: "Штукатурный состав",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                material: "Nivoplan Plus+Planicrete",
                id: 7,
                subMaterial:[
                    {
                        uid: 140,
                        type: "Штукатурный состав",
                        material: "Nivoplan Plus",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus"],
                        coeff: 16,
                        id: 7,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                    {
                        uid: 141,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        coeff: 0.75,
                        id: 7,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                ]
            },
            {
                uid: 142,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-rtu_5kg_2019.jpg?sfvrsn=1eaffa89_0',
                material: "Primer RTU",
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-rtu'],
                coeff: 0.2,
                id: 10,
                units: "шт",
                formula: getPrimingInHalf,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 143,
                type: "Клей для обоев",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/281-adesilex-mt32-10kg-int.png?sfvrsn=50ef69f6_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-mt32"],
                material: "Adesilex MT32",
                coeff: 0.25,
                id: 11,
                units: "шт",
                formula: getPlaster,
                params: [wallSquare, "coeff"]
            },
        ],
        [PAINT]: [
            {
                uid: 190,
                type: "Штукатурный состав",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                material: "Nivoplan Plus+Planicrete",
                coeff: 20,
                id: 2,
                units: "шт",
                subMaterial:[
                    {
                        uid: 191,
                        type: "Штукатурный состав",
                        material: "Nivoplan Plus",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus"],
                        id: 5,
                        coeff: 16,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"],
                    },
                    {
                        uid: 192,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        id: 6,
                        coeff: 7.5,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"],
                    }
                ]
            },
            {
                uid: 193,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 1.5,
                id: 10,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 194,
                type: "Краска",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/329-elastocolor-pittura.png?sfvrsn=8164f7a3_0',
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/elastocolor-paint',
                    "https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/dursilite"
                ],
                material: "ELASTOCOLOR PAINT/DURSILITE",
                coeff: 0.4,
                id: 11,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
        ],
    },
    [HALLWAY]: {
        [LVT_TILE]: [
            {
                uid: 144,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 145,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                id: 4,
                units: "шт",
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "coeff"]
            },
            {
                uid: 146,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 5,
                units: "шт",
                 formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 147,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.13,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 148,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 8,
                units: "шт",
                coeff: 25,
                formula: getSealantCountSquare,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [TILE_HOT_FLOOR]: [
            {
                uid: 149,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/2916-eco-prim-gripacb2207879c562e49128ff01007028e9.png?sfvrsn=80942712_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/eco-prim-grip"],
                material: "Eco Prim Grip",
                coeff: 0.3,
                id: 2,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 150,
                type: "Наливной пол",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanmaxir_ru_2020_43b879fc6ef34e50bdaa68a11141335a.png?sfvrsn=48e03638_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/novoplan-maxi-r"],
                material: "Novoplan Maxi R",
                coeff: 1.8,
                heatFloor: true,
                id: 4,
                units: "шт",
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "coeff"]
            },
            {
                uid: 151,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 5,
                units: "шт",
                formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 152,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 153,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 8,
                units: "шт",
                coeff: 25,
                formula: getSealantCountSquare,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],
        [PAINT]: [
            {
                uid: 154,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 10,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 155,
                type: "Выравнивающий состав",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapetherm-ar2_5798e442139849d2846f65a36db455cb.jpg?sfvrsn=e8545772_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapetherm-ar2'],
                material: "MAPETHERM AR2",
                coeff: 1.5,
                id: 11,
                units: "шт",
                formula: getLevelingCompaund,
                params: [wallSquare, "coeff"]
            },
            {
                uid: 156,
                type: "Cетка",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/941-mapetherm-net_17d3cbb2b0184b34afbff65b3be12784.png?sfvrsn=aa8f748b_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapetherm-net'],
                material: "MAPETHERM net",
                id: 12,
                coeff: 1,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 157,
                type: "Грунт",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/primer-g-10kg_017bc6e696fb4f388da023d1ec25e482.jpg?sfvrsn=21945f9a_0',
                link: ['https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/primer-g'],
                material: "Primer G",
                coeff: 0.2,
                id: 13,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 158,
                type: "Краска",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/329-elastocolor-pittura.png?sfvrsn=8164f7a3_0',
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/elastocolor-paint',
                    "https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/dursilite"
                ],
                material: "ELASTOCOLOR PAINT/DURSILITE",
                id: 14,
                units: "шт",
                coeff: 0.4,
                formula: getPriming,
                params: [wallSquare, "coeff"]
            },
        ],
    },
    [BALCONY]: {
        [LVT_TILE]: [
            {
                uid: 159,
                type: "Адгезивный раствор",
                material: "topcem+planicrete",
                id: 1,
                units: "шт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                subMaterial:[
                    {
                        uid: 160,
                        type: "Адгезивный раствор",
                        material: "planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        coeff: 0.3,
                        id: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"]
                    },
                    {
                        uid: 161,
                        type: "Адгезивный раствор",
                        material: "topcem",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/207-topcem-20kg-int_0c49e07c6465466b9c3cb70556a26ff2.png?sfvrsn=a0e19510_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem"],
                        coeff: 0.9,
                        id: 1,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"]
                    },
                ]
            },
            {
                uid: 162,
                type: "Полусух стяжка",
                material: "Topcem Pronto",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto"],
                coeff: 20,
                id: 3,
                units: "шт",
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "coeff"]
            },
            {
                uid: 163,
                type: "Гидроизоляция",
                material: "Mapelastic +сетка Mapenet 150",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-a-b-32kg-rus_6b6652ec58844c9eb9295c9b02929f32.png?sfvrsn=c2ca2a91_0",
                id: 4,
                units: "шт",
                subMaterial:[
                    {
                        uid: 200,
                        type: "Гидроизоляция",
                        material: "Mapelastic",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-a-b-32kg-rus_6b6652ec58844c9eb9295c9b02929f32.png?sfvrsn=c2ca2a91_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapelastic"],
                        coeff: 1.7,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"]
                    },
                    {
                        uid: 201,
                        type: "Гидроизоляция",
                        material: "Cетка Mapenet 150",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/914-mapenet-150_87fcebd19ed34725ad0fdfd5f5869518.png?sfvrsn=cba84d72_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapenet-150"],
                        coeff: 1,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"]
                    },
                ]
            },
            {
                uid: 164,
                type: "Лента",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/7631-mapeband-easy-30m-int582a9d7879c562e49128ff01007028e9.png?sfvrsn=4158339b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapeband-easy"],
                material: "mapeband easy",
                coeff: 1,
                id: 7,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 165,
                type: "Сливной трап",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/566-drain-front_20c6fd6bad994fe08fa8d1690c1840d0_5f57236365c54af4be7deb858673816a.png?sfvrsn=8d263824_0",
                material: "DRAIN front+ Adesilex PG4",
                id: 8,
                units: "шт",
                formula: () => 1,
                params: [],
                subMaterial:[
                    {
                        uid: 202,
                        type: "Сливной трап",
                        material: "DRAIN front",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/566-drain-front_20c6fd6bad994fe08fa8d1690c1840d0_5f57236365c54af4be7deb858673816a.png?sfvrsn=8d263824_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/drain-front"],
                        id: 8,
                        units: "шт",
                        formula: () => 1,
                        params: [],
                    },
                    {
                        uid: 203,
                        type: "Клей",
                        material: "Adesilex PG4",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/370-adesilex-pg4-a-b-6kg-int.png?sfvrsn=767634f9_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-pg4"],
                        id: 8,
                        units: "шт",
                        formula: () => 1,
                        params: [],
                    }
                ]
            },
            {
                uid: 166,
                type: "Клей для плитки",
                material: "Adesilex P9",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p9-gray_rus_2021_e412d15bdd544032947cd3e4d9602109.png?sfvrsn=e5acc5b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p9"],
                glue: true,
                id: 5,
                units: "шт",
                formula: getGlueForTail,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 167,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 7,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 168,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 8,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],  
        [PAINT]: [
            {
                uid: 169,
                type: "Штукатурный состав",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                material: "Nivoplan Plus+Planicrete",
                id: 12,
                units: "шт",
                subMaterial:[
                    {
                        uid: 170,
                        type: "Штукатурный состав",
                        material: "Nivoplan Plus",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus"],
                        coeff: 16,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                    {
                        uid: 171,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        coeff: 0.75,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                ]
            },
            {
                uid: 172,
                type: "Декоративная штукатурка",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/4_planitop_mineral_graphito_2-5_white_2021_f0f855810b584b1bad7318eae91a64b5.png?sfvrsn=87ffb32b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planitop-mineral-graffiato"],
                material: "Planitop Mineral Graffiato",
                coeff: 2.8,
                id: 13,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 173,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/malech.jpg?sfvrsn=53619d99_2",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/malech"],
                material: "Malech",
                coeff: 0.15,
                id: 10,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 174,
                type: "Краска",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/329-elastocolor-pittura.png?sfvrsn=8164f7a3_0',
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/elastocolor-paint',
                    "https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/dursilite"
                ],
                material: "ELASTOCOLOR PAINT/DURSILITE",
                coeff: 0.4,
                id: 11,
                units: "шт",
                formula: getPaint,
                params: [wallSquare, "coeff"]
            },
        ]
    },
    [LOGGIA]: {
        [FLOOR_TILE]: [
            {
                uid: 175,
                type: "Полусух стяжка",
                material: "Topcem Pronto",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/topcem-pronto.jpg?sfvrsn=ac846777_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/topcem-pronto"],
                coeff: 20,
                id: 3,
                units: "шт",
                formula: getSelfLevelingFloor,
                params: [square, elevationDifferences, floorLength, floorWidth, "heatFloor", "coeff"]
            },
            {
                uid: 176,
                type: "Сливной трап",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/drain-lateral-1pz-int.jpg?sfvrsn=3d30140e_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/drain-vertical-drain-lateral"],
                material: "DRAIN Lateral",
                id: 8,
                units: "шт",
                formula: () => 1,
                params: []
            },
            {
                uid: 177,
                type: "Гидроизоляция",
                material: "Mapelastic +сетка Mapenet 150",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-a-b-32kg-rus_6b6652ec58844c9eb9295c9b02929f32.png?sfvrsn=c2ca2a91_0",
                id: 4,
                units: "шт",
                subMaterial:[
                    {
                        uid: 178,
                        type: "Гидроизоляция",
                        material: "Mapelastic",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/mapelastic-a-b-32kg-rus_6b6652ec58844c9eb9295c9b02929f32.png?sfvrsn=c2ca2a91_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapelastic"],
                        coeff: 1.7,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"]
                    },
                    {
                        uid: 179,
                        type: "Гидроизоляция",
                        material: "Cетка Mapenet 150",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/914-mapenet-150_87fcebd19ed34725ad0fdfd5f5869518.png?sfvrsn=cba84d72_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapenet-150"],
                        coeff: 1,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [square, floorLength, floorWidth, "coeff"]
                    },
                ]
            },
            {
                uid: 180,
                type: "Лента",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/7631-mapeband-easy-30m-int582a9d7879c562e49128ff01007028e9.png?sfvrsn=4158339b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapeband-easy"],
                material: "mapeband easy",
                coeff: 1,
                id: 7,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
            {
                uid: 181,
                type: "Клей для плитки",
                material: "Keraflex Maxi S1",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/keraflexmaxi-2019-gray.png?sfvrsn=44d2cb6f_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/keraflex-maxi-s1"],
                id: 5,
                units: "шт",
                formula: getGlueTailForLogia,
                params: [square, floorLength, floorWidth, tailWidth, tailLength]
            },
            {
                uid: 182,
                type: "Заполнитель шовный",
                material: "Ultracolor Plus",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultracolor-plus.png?sfvrsn=e77c9da3_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultracolor-plus"],
                coeff: 1.6,
                id: 9,
                units: "шт",
                formula: getSutureFiller,
                params: [square, floorLength, floorWidth, tailWidth, tailLength, tailThickness, jointWidth, "coeff"]
            },
            {
                uid: 183,
                type: "Герметик силиконовый",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/401-mapesil-ac.png?sfvrsn=edbae443_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/mapesil-ac"],
                material: "Mapesil AC",
                id: 10,
                units: "шт",
                formula: getPriming,
                params: [square, floorLength, floorWidth, "coeff"]
            },
        ],  
        [PAINT]: [
            {
                uid: 184,
                type: "Штукатурный состав",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                material: "Nivoplan Plus+Planicrete",
                id: 12,
                units: "шт",
                subMaterial:[
                    {
                        uid: 185,
                        type: "Штукатурный состав",
                        material: "Nivoplan Plus",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/nivoplanplus_25rg_ru_2020_698c61d1cd184eee8f79eda120a2d895.png?sfvrsn=59aac8e_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/nivoplan-plus"],
                        coeff: 16,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                    {
                        uid: 186,
                        type: "Адгезионный раствор",
                        material: "Planicrete",
                        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/701-planicrete502c207879c562e49128ff01007028e9.png?sfvrsn=1352d1a4_0",
                        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planicrete"],
                        coeff: 0.75,
                        id: 12,
                        units: "шт",
                        formula: getPriming,
                        params: [wallSquare, null, null, "coeff"]
                    },
                ]
            },
            {
                uid: 187,
                type: "Декоративная штукатурка",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/4_planitop_mineral_graphito_2-5_white_2021_f0f855810b584b1bad7318eae91a64b5.png?sfvrsn=87ffb32b_0",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/planitop-mineral-graffiato"],
                material: "Planitop Mineral Graffiato",
                coeff: 2.8,
                id: 13,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 188,
                type: "Грунт",
                img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/malech.jpg?sfvrsn=53619d99_2",
                link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/malech"],
                material: "Malech",
                coeff: 0.15,
                id: 10,
                units: "шт",
                formula: getPriming,
                params: [wallSquare, null, null, "coeff"]
            },
            {
                uid: 189,
                type: "Краска",
                img: 'https://cdnmedia.mapei.com/images/librariesprovider50/products-images/329-elastocolor-pittura.png?sfvrsn=8164f7a3_0',
                link: [
                    'https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/elastocolor-paint',
                    "https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/dursilite"
                ],
                material: "ELASTOCOLOR PAINT/DURSILITE",
                coeff: 0.4,
                id: 11,
                units: "шт",
                formula: getPaint,
                params: [wallSquare, "coeff"]
            },
        ]
    },
}