import styled from "styled-components";
import { IStyledInput } from "./types";

export const Unit = styled.span`
    margin-left: -30px;
    margin-top: 3px;
`; 


export const Input = styled.input<IStyledInput>`
    background: #F9F9F9;
    box-shadow: 6px 6px 9px rgba(0, 0, 0, 0.25);
    border-radius: 7.5px;
    border: none;
    padding: 0 18px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    margin-right: ${({mr}) => mr ? mr : 0 }px;
    margin-left: ${({ml}) => ml ? ml : 0 }px;
    margin-top: ${({mt}) => mt ? mt : 0 }px;
    margin-bottom: ${({mb}) => mb ? mb : 0 }px;

    height: ${({height}) => height ? height : 45}px;
    width: ${({width}) => width ? width : 372}px;

    @media (min-width: 320px){
        height: ${({height}) => height ? height : 20}px;
        width: ${({width}) => width ? width : 166}px;
        font-size: 12px;
    };

    @media (min-width: 375px){
        height: ${({height}) => height ? height : 24}px;
        width: ${({width}) => width ? width : 200}px;
        font-size: 14px;
    };

    @media (min-width: 414px){
        height: ${({height}) => height ? height : 26}px;
        width: ${({width}) => width ? width : 250}px;
        font-size: 16px;
    };

    @media (min-width: 480px){
        height: ${({height}) => height ? height : 30}px;
        width: ${({width}) => width ? width : 280}px;
        font-size: 18px;
    };

    @media (min-width: 768px){
        height: ${({height}) => height ? height : 30}px;
        width: ${({width}) => width ? width : 248}px;
        font-size: 14px;
    };

    @media (min-width: 1920px){
        height: ${({height}) => height ? height : 45}px;
        width: ${({width}) => width ? width : 372}px;
        font-size: 21px;
    };
`;