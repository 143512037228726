import { Select, MenuItem  } from '@material-ui/core';
import styled from "styled-components";

export const MySelect = styled(Select)`
    *{
        background: #F9F9F9;
    }

    padding: 0;

    &.MuiInput-underline {
        &:before {
            display: none;
        }
        &:after {
            display: none;
        }
    }

    & .MuiSelect-root {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 0 0 16px;

        @media (min-width: 320px){
                height: 21px;
                width: 230px;
                font-size: 12px;
        };



        @media (min-width: 768px){
            height: 30px;
            width: 284px;
            font-size: 14px;
        };

        @media (min-width: 1920px){
            height: 45px;
            width: 372px;
            font-size: 21px;
        };
    }

    & .MuiSelect-select.MuiSelect-select {
        padding-right: 0;
    }

    & .MuiSvgIcon-root {
        background: #0070BA;
        border-radius: 5px;
        top: 0;
        color: #ffffff;
        @media (min-width: 320px){
            @media (min-height: 480px){
                width: 31px;
                height: 21px; 
            }
        };

        @media (min-width: 375px){
            @media (min-height: 667px){
            }
        };

        @media (min-width: 414px){
            @media (min-height: 736px){
            }
        };


        @media (min-width: 480px){

        };

        @media (min-width: 480px){
            @media (min-height: 800px){
    
            }
        };

        @media (min-width: 768px){
            width: 44px;
            height: 30px;   
        };

        @media (min-width: 1920px){
            width: 66px;
            height: 45px;
        };
    
    }

    & .MuiSelect-selectMenu {
        background: #F9F9F9;
        box-shadow: 6px 6px 9px rgb(0 0 0 / 25%);
        border-radius: 7.5px;
    }
`;

export const MyMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        @media (min-width: 768px){
            font-size: 14px;
        };

        @media (min-width: 1920px){
            font-size: 21px;
        };
    }

`;
