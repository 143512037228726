import { BATHROOM } from "../../components/constants";
import { ROOMS_SELECT } from "./rooms-constants";
import { IRoomsState, IRoomsSelectAction } from "./types"


const defaultState: IRoomsState = {
    activeRoomId: BATHROOM,
}

export const rooms = (
    state: IRoomsState = defaultState,
    action: IRoomsSelectAction
) => {
    switch (action.type) {
        case ROOMS_SELECT: {
            return {
                ...state,
                activeRoomId: action.selectedId 
            }
        }
        default:
            return state;
    }
};