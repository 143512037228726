import React from 'react';
import './static/App.css';
import { Header } from "./components/header";
import { NavBar } from './components/navbar';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { CalculatePage } from "./pages/calculate-page"
import { ElementsPage } from "./pages/elemets-page"
import { LayersPage } from "./pages/layers-page"
import { SizesPage } from "./pages/sizes-page"
import { RoomPage } from "./pages/room-page"



function App() {
  const location = useLocation();
  const pathname = location.pathname
  return (
    <>
      <Header />
      <NavBar pathname={pathname}/>
      <Routes>
        <Route path="/" element={<Navigate to="/room" replace />} />
        <Route path="/room" element={<RoomPage />} />
        <Route path="/elements" element={<ElementsPage/>} />
        <Route path="/sizes" element={<SizesPage/>} />
        <Route path="/layers" element={<LayersPage/>} />
        <Route path="/calculate" element={<CalculatePage />} />
      </Routes>
      
    </>
  );
}

export default App;
