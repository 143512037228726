import React from "react";
import { ISVGProps } from "./types"


export const DeleteXIcon = ({
    width = 18,
    height = 18,
    fill = "#006FB9",
}:ISVGProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 2.29006L16.2099 0.5L8.95028 7.66022L1.79006 0.5L0 2.29006L7.16022 9.45028L0 16.7099L1.79006 18.5L8.95028 11.2403L16.1105 18.4006L17.9006 16.6105L10.7403 9.45028L18 2.29006Z" fill="#FF4C50"/>
        </svg>

    )
}