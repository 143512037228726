import { TILE } from "../../components/constants";
import { ELEMENTS_PAGE__CHOOSE_SELECT } from "./elements-constants";
import { IElementsState, IElementSelectAction } from "./types"


const defaultState: IElementsState = {
    activeSelectId: TILE,
}

export const elements = (
    state: IElementsState = defaultState,
    action: IElementSelectAction
) => {
    switch (action.type) {
        case ELEMENTS_PAGE__CHOOSE_SELECT: {
            return {
                ...state,
                activeSelectId: action.selectedId 
            }
        }
        default:
            return state;
    }
};