import styled from "styled-components";
import { PlusIcon } from "../../static/icons"; 

export const TableWrapper = styled.div`
    background: #F9F9F9;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 540px
    padding: 9px 9px 13px;
    margin-bottom: 22px;
    margin-top: 5px;
`;


export const TableHeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    width: 100%;
`;

export const RowWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    width: 100%;
    background-color: #FFFFFF;
    margin-top: 12px;
    height: 39px;
    padding: 0 12px 0 9px ;
`;

export const Marker = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #C4C4C4;
    margin-right: 10px;
`;

export const Plus = styled(PlusIcon)``