import React, { useMemo, useState} from "react";
import { BigTitle, TitleMobile } from "../title";
import { 
    SIZES_TITLE,
    SIZE_CHOISE,
    SIZE,
    SQUARE,
    TYPE_BASE,
    HEIGHT_DIFFERENCE,
    TYPE_TYLE,
    SIZE_TILE,
    WIDTH,
    HEIGHT,
    WEIGHT,
    JOINT_WIDTH,
    LVT_TILE,
    TILE_HOT_FLOOR,
    FLOOR_TILE
} from "../constants";
import { Box } from "@material-ui/core";
import { BlackText, LabelTextBig } from "../text";
import { BigCheckBox } from "../check-box";
import { SizeIcon, SquareIcon } from "../../static/icons";
import { TextInput } from "../text-input";
import { PrettoSlider } from "../slider"; 
import { SelectComponent } from "../select";
import { MARKS, MARKS_JOINT } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { getInputsValue, getSelectedId } from "../../store/selectors";
import { INPUT_CHANGE } from "../../store/sizes/sizes-constants";
import { TActiveSelectedId } from "../../store/elements/types";
import { basses } from "./constants"
import { SizesBox, SliderBox } from "./styled";

const isFloorWithotTail = (type: TActiveSelectedId) =>{
    return type !== TILE_HOT_FLOOR && type !== LVT_TILE && type !== FLOOR_TILE
} 



export const FloorSizes = () => {
    const dispatch = useDispatch();
    const [squareOrSizes, setSquareOrSizes] = useState("square")

    const onChangeSquareSizes = () => {
        if(squareOrSizes === "square"){
            setSquareOrSizes("sizes")
        } else {
            setSquareOrSizes("square")
        }
    }

    const inputsValue = useSelector(getInputsValue)
    const selectedElement = useSelector(getSelectedId)

    const withoutTail = isFloorWithotTail(selectedElement)

    const inputHandler = (value: string | number, name: string) => {
        dispatch({
            type: INPUT_CHANGE,
            name,
            value,
        })
    } 

    const sliderHandler = (value: number) => {
        const newValue = value < 4 ? value*5 : (value-2)*10
        dispatch({
            type: INPUT_CHANGE,
            name: "elevationDifferences",
            value: newValue,
        })
    }

    const joinTailHandler =(value: number) => {
        dispatch({
            type: INPUT_CHANGE,
            name: "jointWidth",
            value: value,
        })
    }

    const sliderValue = useMemo(() => {
        const value = parseInt(inputsValue.elevationDifferences)
        return value < 20 
            ? value / 5 
            : value / 10 + 2;
    },[inputsValue.elevationDifferences])


    return (
        <>
        <Box alignSelf="center"> <BigTitle mt={10} mb={30}>{SIZES_TITLE}</BigTitle></Box>
        <TitleMobile mt={10} mb={30}>{SIZES_TITLE}</TitleMobile>
        <Box width="100%">
            <BlackText mb={9}>{SIZE_CHOISE}</BlackText>
            <Box display="flex" width="50%" justifyContent="space-between" flexWrap="wrap" >
                <BigCheckBox
                    icon={<SquareIcon/>} 
                    value={SQUARE} 
                    text={SQUARE} 
                    checked={squareOrSizes === "square"} 
                    onChange={onChangeSquareSizes}/>
                <BigCheckBox 
                    icon={<SizeIcon/>} 
                    value={SIZE} 
                    text={SIZE} 
                    checked={squareOrSizes === "sizes"} 
                    onChange={onChangeSquareSizes}/>
            </Box>
            {squareOrSizes === "square" && <SizesBox>
                <LabelTextBig mb={7} mr={20}>{SQUARE}(м&sup2;)</LabelTextBig>
                <TextInput 
                    type="number" 
                    name="square" 
                    value={inputsValue.square} 
                    onChange={(value) => inputHandler(value, "square")}
                    />
            </SizesBox>}
            {squareOrSizes === "sizes" && 
            <>
                <SizesBox  display="flex">
                    <LabelTextBig mb={7} mr={20}>{WIDTH}(м)</LabelTextBig>
                    <TextInput 
                        type="number" 
                        name="floorLength" 
                        value={inputsValue.floorLength} 
                        onChange={(value) => inputHandler(value, "floorLength")}
                        />
                </SizesBox>
                <SizesBox  display="flex">
                    <LabelTextBig mb={7} mr={20}>{HEIGHT}(м)</LabelTextBig>
                    <TextInput 
                        type="number" 
                        name="floorWidth" 
                        value={inputsValue.floorWidth} 
                        onChange={(value) => inputHandler(value, "floorWidth")}
                        />
                </SizesBox>
            </>}
            <Box marginBottom="25px">
                <LabelTextBig mb={7}>{TYPE_BASE}</LabelTextBig>
                <SelectComponent options={basses} />
            </Box>
            <Box marginBottom="27px">
                <LabelTextBig mb={7}>{HEIGHT_DIFFERENCE}(мм)</LabelTextBig>
                <TextInput 
                    type="number"
                    name="elevationDifferences" 
                    value={inputsValue.elevationDifferences} 
                    onChange={(value) => inputHandler(value, "elevationDifferences")}
                />
                <SliderBox  marginTop ="40px" marginBottom="10px">
                    <PrettoSlider
                        aria-label="Temperature"
                        valueLabelDisplay="off"
                        step={1}
                        min={1}
                        max={12}
                        marks={MARKS}
                        value={sliderValue}
                        onChange={(e,value) => sliderHandler(value as number)}
                    />
                </SliderBox>
                
            </Box>
            {!withoutTail && 
                <>
                    <Box marginBottom="27px">
                        <LabelTextBig mb={7}>{TYPE_TYLE}</LabelTextBig>
                        <SelectComponent options={["Стеклянная мозаика", "Другая Плитка"]} />
                    </Box>
                    <Box marginBottom="23px">
                        <BlackText mb={20}>{SIZE_TILE}</BlackText>
                        <Box display="flex" width="100%" flexWrap="wrap" justifyContent="space-around">
                            <Box display="flex" flex={1} marginBottom="27px" marginRight="10px">
                                <LabelTextBig mb={7} mr={20}>{WIDTH}(мм)</LabelTextBig>
                                <TextInput 
                                    name="tailLength" 
                                    type="number"
                                    value={inputsValue.tailLength} 
                                    onChange={(value) => inputHandler(value, "tailLength")}
                                    />
                            </Box>
                            <Box display="flex" marginBottom="27px" flex={1}>
                                <LabelTextBig mb={7} mr={20}>{HEIGHT}(мм)</LabelTextBig>
                                <TextInput 
                                    name="tailWidth" 
                                    type="number"
                                    value={inputsValue.tailWidth} 
                                    onChange={(value) => inputHandler(value, "tailWidth")}
                                    
                                />
                            </Box>
                            <Box display="flex" flex={1}>
                                <LabelTextBig mb={7} mr={20}>{WEIGHT}(мм)</LabelTextBig>
                                <TextInput 
                                    name="tailThickness" 
                                    type="number"
                                    value={inputsValue.tailThickness}
                                    onChange={(value) => inputHandler(value, "tailThickness")}
                                    
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <LabelTextBig mb={6}>{JOINT_WIDTH} (мм)</LabelTextBig>
                        <SliderBox  marginTop ="40px" marginBottom="10px">
                            <PrettoSlider
                                aria-label="Temperature"
                                value={inputsValue.jointWidth}
                                onChange={(e,value) => joinTailHandler(value as number)}
                                valueLabelDisplay="off"
                                step={1}
                                min={1}
                                max={20}
                                marks={MARKS_JOINT}
                            />
                        </SliderBox>
                    </Box>
                </>
            }
        </Box>
        </>
    )
    
}