import React from "react";
import { TableHeaderWrapper } from "./styled";
import { LabelTextSmall } from "../text";
import { Box } from "@material-ui/core";



export const TableHeader = () => {
    return (
        <TableHeaderWrapper>
            <Box display="flex" justifyContent="center">
                <LabelTextSmall>Название</LabelTextSmall>
            </Box>
            <Box display="flex" justifyContent="center">
                <LabelTextSmall>Количество</LabelTextSmall>
            </Box>
            <Box display="flex" justifyContent="center">
                <LabelTextSmall>Номер в системе</LabelTextSmall>
            </Box>
            <Box display="flex" justifyContent="center">
            </Box>
           
        </TableHeaderWrapper>
    )
    
}