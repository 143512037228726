import React from "react";
import { IToolTipProps } from "./types" 
import { Wrapper, Image } from "./styled"
import { Text } from "../text";
import { RIGHT } from "../constants"

export const ToolTip = ({
    position = RIGHT,
    shiftTop = 0,
    shiftLeft = 0,
    image,
    title
}: IToolTipProps) => {
    return (
        <Wrapper className="tooltip" position={position}>
            <Image src={image}/>
            <Text ml={20}>{title}</Text>
        </Wrapper>
    )

}