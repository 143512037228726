import React from "react";
import { ISVGProps } from "./types"


export const CalculateIcon = ({
    width = 28,
    height = 28,
    fill = "#FFFFFF",
}:ISVGProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M27.4031 13.5382C27.722 13.5382 27.9725 13.7887 27.9725 14.1076C27.9725 14.4264 27.722 14.6769 27.4031 14.6769H26.8337V13.5382H27.4031Z" fill={fill}/>
        <path d="M14.8772 13.5382H26.8338V14.6769H14.8772V13.5382Z" fill={fill}/>
        <path d="M25.1255 27.7723H14.3076C14.6265 27.7723 14.877 27.5217 14.877 27.2029V26.6335H25.1255C26.0707 26.6335 26.8336 25.8706 26.8336 24.9254V14.6769H27.403C27.7218 14.6769 27.9723 14.4264 27.9723 14.1075V24.9254C27.9723 26.4969 26.697 27.7723 25.1255 27.7723Z" fill={fill}/>
        <path d="M27.9723 3.28969V14.1076C27.9723 13.7887 27.7218 13.5382 27.403 13.5382H26.8336V3.28969C26.8336 2.34454 26.0707 1.5816 25.1255 1.5816H14.877V1.01223C14.877 0.693391 14.6265 0.442871 14.3076 0.442871H25.1255C26.697 0.442871 27.9723 1.71824 27.9723 3.28969Z" fill={fill}/>
        <path d="M23.4175 7.27527C23.7363 7.27527 23.9868 7.52579 23.9868 7.84463C23.9868 8.16348 23.7363 8.414 23.4175 8.414H18.8626C18.5437 8.414 18.2932 8.16348 18.2932 7.84463C18.2932 7.52579 18.5437 7.27527 18.8626 7.27527H23.4175Z" fill={fill}/>
        <path d="M23.4175 18.6624C23.7363 18.6624 23.9868 18.9129 23.9868 19.2317C23.9868 19.5506 23.7363 19.8011 23.4175 19.8011H18.8626C18.5437 19.8011 18.2932 19.5506 18.2932 19.2317C18.2932 18.9129 18.5437 18.6624 18.8626 18.6624H23.4175Z" fill={fill}/>
        <path d="M23.4175 22.0785C23.7363 22.0785 23.9868 22.329 23.9868 22.6479C23.9868 22.9667 23.7363 23.2172 23.4175 23.2172H18.8626C18.5437 23.2172 18.2932 22.9667 18.2932 22.6479C18.2932 22.329 18.5437 22.0785 18.8626 22.0785H23.4175Z" fill={fill}/>
        <path d="M14.877 26.6335V27.2029C14.877 27.5218 14.6265 27.7723 14.3076 27.7723C13.9888 27.7723 13.7383 27.5218 13.7383 27.2029V26.6335H14.877Z" fill={fill}/>
        <path d="M13.7383 14.677H14.877V26.6336H13.7383V14.677Z" fill={fill}/>
        <path d="M13.7383 13.5382H14.877V14.6769H13.7383V13.5382Z" fill={fill}/>
        <path d="M13.7383 1.58154H14.877V13.5382H13.7383V1.58154Z" fill={fill}/>
        <path d="M14.877 1.01223V1.5816H13.7383V1.01223C13.7383 0.693391 13.9888 0.442871 14.3076 0.442871C14.6265 0.442871 14.877 0.693391 14.877 1.01223Z" fill={fill}/>
        <path d="M1.78174 13.5382H13.7384V14.6769H1.78174V13.5382Z" fill={fill}/>
        <path d="M9.75301 7.27527C10.0719 7.27527 10.3224 7.52579 10.3224 7.84463C10.3224 8.16348 10.0719 8.414 9.75301 8.414H8.04492V7.27527H9.75301Z" fill={fill}/>
        <path d="M9.58199 22.2495C9.80974 22.4658 9.80974 22.8302 9.58199 23.0466C9.47951 23.1605 9.33147 23.2174 9.18344 23.2174C9.0354 23.2174 8.88737 23.1605 8.78488 23.0466L7.47534 21.7371L8.27245 20.9399L9.58199 22.2495Z" fill={fill}/>
        <path d="M8.78488 18.8332C9.00124 18.6054 9.36563 18.6054 9.58199 18.8332C9.80974 19.0495 9.80974 19.4139 9.58199 19.6303L8.27245 20.9398L7.47534 20.1427L8.78488 18.8332Z" fill={fill}/>
        <path d="M6.67505 20.9387L7.4722 20.1416L8.26935 20.9387L7.4722 21.7359L6.67505 20.9387Z" fill={fill}/>
        <path d="M8.04474 8.41394V10.122C8.04474 10.4409 7.79422 10.6914 7.47537 10.6914C7.15653 10.6914 6.90601 10.4409 6.90601 10.122V8.41394H8.04474Z" fill={fill}/>
        <path d="M6.90601 7.27527H8.04474V8.414H6.90601V7.27527Z" fill={fill}/>
        <path d="M8.04474 5.56704V7.27513H6.90601V5.56704C6.90601 5.2482 7.15653 4.99768 7.47537 4.99768C7.79422 4.99768 8.04474 5.2482 8.04474 5.56704Z" fill={fill}/>
        <path d="M7.47521 20.1427L6.6781 20.9398L5.36856 19.6303C5.14082 19.4139 5.14082 19.0495 5.36856 18.8332C5.58492 18.6054 5.94932 18.6054 6.16567 18.8332L7.47521 20.1427Z" fill={fill}/>
        <path d="M6.90611 7.27527V8.414H5.19802C4.87918 8.414 4.62866 8.16348 4.62866 7.84463C4.62866 7.52579 4.87918 7.27527 5.19802 7.27527H6.90611Z" fill={fill}/>
        <path d="M6.16567 23.0466C6.06319 23.1605 5.91515 23.2174 5.76712 23.2174C5.61908 23.2174 5.47105 23.1605 5.36856 23.0466C5.14082 22.8302 5.14082 22.4658 5.36856 22.2495L6.6781 20.9399L7.47521 21.7371L6.16567 23.0466Z" fill={fill}/>
        <path d="M1.78179 13.5382V14.6769H1.21243C0.893586 14.6769 0.643066 14.4264 0.643066 14.1076C0.643066 13.7887 0.893586 13.5382 1.21243 13.5382H1.78179Z" fill={fill}/>
        <path d="M0.643066 3.28969C0.643066 1.71824 1.91844 0.442871 3.48988 0.442871H14.3078C13.9889 0.442871 13.7384 0.693391 13.7384 1.01223V1.5816H3.48988C2.54474 1.5816 1.78179 2.34454 1.78179 3.28969V13.5382H1.21243C0.893586 13.5382 0.643066 13.7887 0.643066 14.1076V3.28969Z" fill={fill}/>
        <path d="M1.78179 24.9254C1.78179 25.8706 2.54474 26.6335 3.48988 26.6335H13.7384V27.2029C13.7384 27.5217 13.9889 27.7723 14.3078 27.7723H3.48988C1.91844 27.7723 0.643066 26.4969 0.643066 24.9254V14.1075C0.643066 14.4264 0.893586 14.6769 1.21243 14.6769H1.78179V24.9254Z" fill={fill}/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="27.3294" height="27.3294" fill={fill} transform="translate(0.643066 0.442871)"/>
        </clipPath>
        </defs>
        </svg>
    )
}