import React from "react";
import { ISVGProps } from "./types"


export const ElementsIcon = ({
    width = 28,
    height = 28,
    fill = "#FFFFFF",
}:ISVGProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M26.5356 18.8036H18.4286V10.6901C18.4286 10.454 18.2377 10.2631 18.0016 10.2631H9.89498V2.14968C9.89498 1.91354 9.7041 1.72266 9.46796 1.72266H0.927511C0.691367 1.72266 0.500488 1.91354 0.500488 2.14968V10.6901V19.2306V27.771C0.500488 28.0072 0.691367 28.198 0.927511 28.198H9.46113H9.46796H17.9952H18.002H26.5356C26.7718 28.198 26.9626 28.0072 26.9626 27.771V19.2306C26.9626 18.9944 26.7713 18.8036 26.5356 18.8036ZM1.35453 11.1172H9.03411V18.8036H1.35453V11.1172ZM9.89498 19.6576H17.5682V27.344H9.89498V19.6576ZM17.5746 18.8036H9.89498V11.1172H17.5746V18.8036ZM1.35453 2.5767H9.04094V10.2631H1.35453V2.5767ZM1.35453 19.6576H9.03411V27.344H1.35453V19.6576ZM26.1086 27.344H18.4286V19.6576H26.1082V27.344H26.1086Z" fill={fill}/>
        <path d="M8.18683 3.00403C7.95069 3.00403 7.75981 3.19491 7.75981 3.43105V8.98234H2.20852C1.97237 8.98234 1.78149 9.17322 1.78149 9.40937C1.78149 9.64551 1.97237 9.83639 2.20852 9.83639H8.18683C8.42298 9.83639 8.61385 9.64551 8.61385 9.40937V3.43105C8.61385 3.19491 8.42298 3.00403 8.18683 3.00403Z" fill={fill}/>
        <path d="M8.18683 11.5446C7.95069 11.5446 7.75981 11.7354 7.75981 11.9716V17.5229H2.20852C1.97237 17.5229 1.78149 17.7137 1.78149 17.9499C1.78149 18.186 1.97237 18.3769 2.20852 18.3769H8.18683C8.42298 18.3769 8.61385 18.186 8.61385 17.9499V11.9716C8.61385 11.7354 8.42298 11.5446 8.18683 11.5446Z" fill={fill}/>
        <path d="M8.18683 20.0848C7.95069 20.0848 7.75981 20.2757 7.75981 20.5119V26.0632H2.20852C1.97237 26.0632 1.78149 26.254 1.78149 26.4902C1.78149 26.7263 1.97237 26.9172 2.20852 26.9172H8.18683C8.42298 26.9172 8.61385 26.7263 8.61385 26.4902V20.5119C8.61385 20.2757 8.42298 20.0848 8.18683 20.0848Z" fill={fill}/>
        <path d="M10.749 18.3769H16.7274C16.9635 18.3769 17.1544 18.186 17.1544 17.9499V11.9716C17.1544 11.7354 16.9635 11.5446 16.7274 11.5446C16.4912 11.5446 16.3003 11.7354 16.3003 11.9716V17.5229H10.749C10.5129 17.5229 10.322 17.7137 10.322 17.9499C10.322 18.186 10.5129 18.3769 10.749 18.3769Z" fill={fill}/>
        <path d="M16.7274 20.0848C16.4912 20.0848 16.3003 20.2757 16.3003 20.5119V26.0632H10.749C10.5129 26.0632 10.322 26.254 10.322 26.4902C10.322 26.7263 10.5129 26.9172 10.749 26.9172H16.7274C16.9635 26.9172 17.1544 26.7263 17.1544 26.4902V20.5119C17.1544 20.2757 16.9635 20.0848 16.7274 20.0848Z" fill={fill}/>
        <path d="M19.2893 26.9172H25.2676C25.5038 26.9172 25.6947 26.7263 25.6947 26.4902V20.5119C25.6947 20.2757 25.5038 20.0848 25.2676 20.0848C25.0315 20.0848 24.8406 20.2757 24.8406 20.5119V26.0632H19.2893C19.0532 26.0632 18.8623 26.254 18.8623 26.4902C18.8623 26.7263 19.0532 26.9172 19.2893 26.9172Z" fill={fill}/>
        <path d="M10.7405 2.99552H17.5724C17.8086 2.99552 17.9995 2.80464 17.9995 2.5685C17.9995 2.33236 17.8086 2.14148 17.5724 2.14148H10.7405C10.5044 2.14148 10.3135 2.33236 10.3135 2.5685C10.3135 2.80464 10.5048 2.99552 10.7405 2.99552Z" fill={fill}/>
        <path d="M10.7405 4.70451H17.5724C17.8086 4.70451 17.9995 4.51363 17.9995 4.27749C17.9995 4.04134 17.8086 3.85046 17.5724 3.85046H10.7405C10.5044 3.85046 10.3135 4.04134 10.3135 4.27749C10.3135 4.51363 10.5048 4.70451 10.7405 4.70451Z" fill={fill}/>
        <path d="M10.7405 6.41337H17.5724C17.8086 6.41337 17.9995 6.22249 17.9995 5.98635C17.9995 5.75021 17.8086 5.55933 17.5724 5.55933H10.7405C10.5044 5.55933 10.3135 5.75021 10.3135 5.98635C10.3135 6.22249 10.5048 6.41337 10.7405 6.41337Z" fill={fill}/>
        <path d="M10.7405 8.12236H17.5724C17.8086 8.12236 17.9995 7.93148 17.9995 7.69534C17.9995 7.45919 17.8086 7.26831 17.5724 7.26831H10.7405C10.5044 7.26831 10.3135 7.45919 10.3135 7.69534C10.3135 7.93148 10.5048 8.12236 10.7405 8.12236Z" fill={fill}/>
        <path d="M10.7405 9.83049H17.5724C17.8086 9.83049 17.9995 9.63961 17.9995 9.40346C17.9995 9.16732 17.8086 8.97644 17.5724 8.97644H10.7405C10.5044 8.97644 10.3135 9.16732 10.3135 9.40346C10.3135 9.63961 10.5048 9.83049 10.7405 9.83049Z" fill={fill}/>
        <path d="M24.0832 1.01583C23.7668 1.38392 20.9907 4.66217 20.9907 6.42108C20.9907 7.93188 21.9776 9.21466 23.3398 9.66431V11.1166H22.9128C22.6766 11.1166 22.4857 11.3075 22.4857 11.5436C22.4857 11.7798 22.6766 11.9707 22.9128 11.9707H22.9132V15.5991C22.9132 15.5999 22.9128 15.6004 22.9128 15.6012C22.9128 16.4254 23.5832 17.0958 24.4073 17.0958C25.2315 17.0958 25.9019 16.4254 25.9019 15.6012C25.9019 15.6004 25.9015 15.5999 25.9015 15.5991V11.9707C26.1376 11.9707 26.3285 11.7798 26.3285 11.5436C26.3285 11.3075 26.1376 11.1166 25.9015 11.1166H25.4745V9.66431C26.8362 9.21423 27.8231 7.93146 27.8231 6.42108C27.8231 4.66217 25.047 1.38392 24.7306 1.01583C24.5687 0.827082 24.2451 0.827082 24.0832 1.01583ZM25.0479 15.6025C25.047 15.9552 24.76 16.2417 24.4073 16.2417C24.0546 16.2417 23.7676 15.9552 23.7668 15.6025C23.7668 15.6021 23.7672 15.6016 23.7672 15.6012V11.9707H25.0474V15.6012C25.0474 15.6016 25.0479 15.6021 25.0479 15.6025ZM24.6204 11.1166H24.1938V9.82658C24.2647 9.83128 24.3352 9.83726 24.4069 9.83726C24.4791 9.83726 24.5495 9.83085 24.6204 9.82658V11.1166ZM24.4069 8.98321C22.9939 8.98321 21.8448 7.83409 21.8448 6.42108C21.8448 5.33857 23.4751 3.10781 24.4069 1.96125C25.3391 3.10781 26.969 5.33772 26.969 6.42108C26.969 7.83409 25.8199 8.98321 24.4069 8.98321Z" fill={fill}/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="27.3294" height="27.3294" fill={fill} transform="translate(0.49707 0.868164)"/>
        </clipPath>
        </defs>
        </svg>
    )
}