import React from "react";
import { ISVGProps } from "./types"


export const RoomIcon = ({
    width = 28,
    height = 28,
    fill = "#FFFFFF",
}:ISVGProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M17.8057 17.5324V13.387C17.8057 12.6336 17.1926 12.0205 16.4392 12.0205H3.68551C2.93212 12.0205 2.31903 12.6336 2.31903 13.387V17.5324C1.28097 17.7442 0.49707 18.6643 0.49707 19.7638V22.4968V27.0517C0.49707 27.3031 0.70113 27.5072 0.952561 27.5072H2.77452C3.02595 27.5072 3.23001 27.3031 3.23001 27.0517V25.6852H16.8947V27.0517C16.8947 27.3031 17.0988 27.5072 17.3502 27.5072H19.1722C19.4236 27.5072 19.6277 27.3031 19.6277 27.0517V22.4968V19.7638C19.6277 18.6643 18.8438 17.7442 17.8057 17.5324ZM3.23001 13.387C3.23001 13.136 3.43453 12.9315 3.68551 12.9315H16.4392C16.6902 12.9315 16.8947 13.136 16.8947 13.387V17.4864H15.9838V15.6644C15.9838 14.9111 15.3707 14.298 14.6173 14.298H11.8843C11.131 14.298 10.5179 14.9111 10.5179 15.6644V17.4864H9.60688V15.6644C9.60688 14.9111 8.99379 14.298 8.24041 14.298H5.50747C4.75409 14.298 4.141 14.9111 4.141 15.6644V17.4864H3.23001V13.387ZM15.0728 15.6644V17.4864H11.4288V15.6644C11.4288 15.4135 11.6334 15.2089 11.8843 15.2089H14.6173C14.8683 15.2089 15.0728 15.4135 15.0728 15.6644ZM8.6959 15.6644V17.4864H5.05198V15.6644C5.05198 15.4135 5.25649 15.2089 5.50747 15.2089H8.24041C8.49139 15.2089 8.6959 15.4135 8.6959 15.6644ZM18.7167 26.5962H17.8057V25.2297C17.8057 24.9783 17.6017 24.7742 17.3502 24.7742H2.77452C2.52309 24.7742 2.31903 24.9783 2.31903 25.2297V26.5962H1.40805V22.9523H18.7167V26.5962ZM18.7167 22.0413H1.40805V19.7638C1.40805 19.0105 2.02114 18.3974 2.77452 18.3974H4.59649H9.15139H10.9734H15.5283H17.3502C18.1036 18.3974 18.7167 19.0105 18.7167 19.7638V22.0413Z" fill={fill}/>
        <path d="M27.3709 17.4865H20.9941C20.7426 17.4865 20.5386 17.6905 20.5386 17.9419V21.5859V24.7743H21.4496V22.0414H26.9154V24.7743H27.8264V21.5859V17.9419C27.8264 17.6905 27.6224 17.4865 27.3709 17.4865ZM26.9154 21.1304H21.4496V18.3974H26.9154V21.1304Z" fill={fill}/>
        <path d="M25.0934 19.3083H23.2715V20.2193H25.0934V19.3083Z" fill={fill}/>
        <path d="M26.4368 13.6982L25.5259 10.9652C25.4635 10.7794 25.2899 10.6541 25.0936 10.6541H23.2717C23.0753 10.6541 22.9018 10.7794 22.8394 10.9657L21.9284 13.6986C21.882 13.8375 21.9056 13.9901 21.9913 14.109C22.0769 14.2279 22.2145 14.298 22.3607 14.298H23.7271V15.6645H22.8162V16.5755H25.5491V15.6645H24.6381V14.298H26.0046C26.1508 14.298 26.2884 14.2279 26.374 14.1086C26.4596 13.9897 26.4829 13.8371 26.4368 13.6982ZM22.9924 13.3871L23.6001 11.5651H24.7657L25.3728 13.3871H22.9924Z" fill={fill}/>
        <path d="M27.5573 6.1388L14.3481 0.217423C14.2292 0.164586 14.0939 0.164586 13.9755 0.217423L0.766265 6.1388C0.602289 6.21259 0.49707 6.3752 0.49707 6.55466V12.0206H1.40805V6.84937L14.1618 1.1325L26.9155 6.84982V12.0206H27.8265V6.55466C27.8265 6.3752 27.7213 6.21259 27.5573 6.1388Z" fill={fill}/>
        <path d="M17.3061 6.47911L16.4074 6.63033C16.4288 6.75468 16.4393 6.88222 16.4393 7.01021C16.4393 8.266 15.4176 9.28767 14.1618 9.28767C12.906 9.28767 11.8844 8.266 11.8844 7.01021C11.8844 5.75442 12.906 4.73276 14.1618 4.73276C14.4224 4.73276 14.6783 4.77649 14.9216 4.86257L15.2249 4.00352C14.8838 3.88281 14.5262 3.82178 14.1618 3.82178C12.4036 3.82178 10.9734 5.25202 10.9734 7.01021C10.9734 8.76841 12.4036 10.1986 14.1618 10.1986C15.92 10.1986 17.3503 8.76841 17.3503 7.01021C17.3503 6.83166 17.3352 6.65311 17.3061 6.47911Z" fill={fill}/>
        <path d="M17.0281 3.9552L14.1617 6.8216L13.5728 6.23265L12.9287 6.87671L13.8397 7.78769C13.9285 7.87651 14.0451 7.92115 14.1617 7.92115C14.2783 7.92115 14.3949 7.87651 14.4838 7.78769L17.6722 4.59926L17.0281 3.9552Z" fill={fill}/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="27.3294" height="27.3294" fill={fill} transform="translate(0.49707 0.177795)"/>
        </clipPath>
        </defs>
        </svg>

    )
}