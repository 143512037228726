import { Box } from "@material-ui/core";
import styled from "styled-components";

import { IRoomImage } from "./types";

export const MobileElementsWrapper = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 768px){
        display: none;
    };
`;

export const RoomImage = styled.div<IRoomImage>`
    background: url(${({image}) => image}) no-repeat center/100%;
    position: relative;
    transition: 0.5s;

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 286px;
            height: 212px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 336px;
            height: 249px;   
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 371px;
            height: 275px;
        }
    };


    @media (min-width: 480px){
        width: 635px;
        height: 470px;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 430px;
            height: 319px;
        }
    };

    @media (min-width: 768px){
        width: 635px;
        height: 470px;
    };

    @media (min-width: 1024px){
        width: 822px;
        height: 608px;      
    };

    @media (min-width: 1280px){
        width: 750px;
        height: 555px;
    };

    @media (min-width: 1920px){
        width: 1119px;
        height: 828px;
    };

`