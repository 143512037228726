import React from "react";
import LogoSVG from "../../static/img/logo.svg";
import MailIcon from "../../static/img/mail.png";
import PhoneIcon from "../../static/img/phone.png"
import {
    Wrapper,
    Logo,
    Title,
    Сontacts,
    СontactItem,
    ContactImg
} from "./styled"
import { 
    TITLE,
    EMAIL,
    NUMBER
 } from "../constants";
import { NavLink } from "react-router-dom";


export const Header = () => {
    return (
        <Wrapper>
            <NavLink to={"/room"}>
                <Logo >
                    <img src={LogoSVG} alt={"LOGO"}/>
                </Logo>
            </NavLink>
            <Title>{TITLE}</Title>
            <Сontacts>
                <a href={`mailto:${EMAIL}`}>
                    <СontactItem>
                        <ContactImg src={MailIcon} alt={"mail"}/>
                        <span>{EMAIL}</span>
                    </СontactItem>
                </a>
                <a href={`tel:${NUMBER}`}>
                    <СontactItem>
                        <ContactImg src={PhoneIcon} alt={"phone"}/>
                        <span>{NUMBER}</span>
                    </СontactItem>
                </a>
                
            </Сontacts>
        </Wrapper>
    )
}
