import React from "react";
import { 
    Wrapper,
    NavItem,
    Text
} from "./styled";
import {
    CalculateIcon,
    ElementsIcon,
    LayersIcon,
    RoomIcon,
    SizesIcon,
} from "../../static/icons";
import {
    ROOMS,
    ELEMENTS,
    SIZES,
    LAYERS,
    CALCULATE,
} from "../constants";
import { NavLink } from 'react-router-dom';


export const NavBar = ({ pathname }: { pathname: string }) => {

    return (
        <Wrapper>
            <NavLink to={"/room"}>
                <NavItem selected={pathname === "/room"}>
                    <RoomIcon fill={pathname === "/room" ? "#0070BA" : "#FFFFFF"} />
                    <Text color={pathname === "/room" ? "#0070BA" : "#FFFFFF"}>{ROOMS}</Text>
                </NavItem>
            </NavLink>
            <NavLink to={"/elements"}>
                <NavItem selected={pathname === "/elements"} >
                    <ElementsIcon fill={pathname === "/elements" ? "#0070BA" : "#FFFFFF"}/>
                    <Text color={pathname === "/elements" ? "#0070BA" : "#FFFFFF"}>{ELEMENTS}</Text>
                </NavItem>
                </NavLink>
            <NavLink to={"/sizes"}>
                <NavItem selected={pathname === "/sizes"}>
                    <SizesIcon fill={pathname === "/sizes" ? "#0070BA" : "#FFFFFF"}/>
                    <Text color={pathname === "/sizes" ? "#0070BA" : "#FFFFFF"}>{SIZES}</Text>
                </NavItem>
            </NavLink>
            <NavLink to={"/layers"}>
                <NavItem  selected={pathname === "/layers"}>
                    <LayersIcon fill={pathname === "/layers" ? "#0070BA" : "#FFFFFF"}/>
                    <Text color={pathname === "/layers" ? "#0070BA" : "#FFFFFF"}>{LAYERS}</Text>
                </NavItem>
            </NavLink>
            <NavLink to={"/calculate"}>
                <NavItem selected={pathname === "/calculate"}>
                    <CalculateIcon fill={pathname === "/calculate" ? "#0070BA" : "#FFFFFF"}/>
                    <Text color={pathname === "/calculate" ? "#0070BA" : "#FFFFFF"}>{CALCULATE}</Text>
                </NavItem>
            </NavLink>
        </Wrapper>
    )
}