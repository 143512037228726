import styled from "styled-components";
import { IBlockProps } from "./types";

export const Block = styled.div<IBlockProps>`
    padding-right: ${({pr}) => pr ? pr : 0 }px;
    padding-left: ${({pl}) => pl ? pl : 0 }px;
    padding-top: ${({pt}) => pt ? pt : 0 }px;
    padding-bottom: ${({pb}) => pb ? pb : 0 }px;
    margin-right: ${({mr}) => mr ? mr : 0 }px;
    margin-left: ${({ml}) => ml ? ml : 0 }px;
    margin-top: ${({mt}) => mt ? mt : 0 }px;
    margin-bottom: ${({mb}) => mb ? mb : 0 }px;
    display: ${({flex}) => flex ? "flex" : "block" };
    width: ${({width}) => width ? `${width}px` : "auto" };
    height: ${({height}) => height ? `${height}px` : "auto" };
    ${({flexDirection}) => flexDirection ? `flex-direction: ${flexDirection};` : ""}
    ${({justifyContent}) => justifyContent ? `justify-content: ${justifyContent};`: ""}
`;