
 import { 
    BATHROOM,
    LIVINGROOM,
    KITCHEN,
    CHILDREN_ROOM,
    BEDROOM,
    HALLWAY,
    BALCONY,
    LOGGIA,
    PARQUET,
    CARPET,
    LAMINATE,
} from "../constants";
import BalconyClearImg from "../../static/img/balkon-clear.jpg";
import LogiaClearImg from "../../static/img/logia-clear.jpg";
import HallwayClearImg from "../../static/img/hallway-clear.jpg";
import LivingroomClearImg from "../../static/img/livingroom-clear.jpg";
import BedroomClearImg from "../../static/img/bedroom-clear.jpg";
import childrenLaminat from "../../static/img/children-laminat.jpg";
import childrenParket from "../../static/img/children-parket.jpg";
import childrenKarpet from "../../static/img/children-carpet.jpg";
import { TActiveRoomSelectedId } from "../../store/room-selector/types";
import { TActiveSelectedId } from "../../store/elements/types";

export const getRoomImage = (
    selectRoom: TActiveRoomSelectedId, 
    selectCheck: TActiveSelectedId
) => {
    switch (selectRoom) {
        case CHILDREN_ROOM: {
            switch (selectCheck){
                case PARQUET:
                    return childrenParket
                case CARPET:
                    return childrenKarpet
                case LAMINATE:
                    return childrenLaminat
                default:
                    return childrenParket
            }
        }
        case LIVINGROOM: {
            return LivingroomClearImg
        }
        case BATHROOM: {
            return "";
        }
        case KITCHEN: {
            return "";
        }
        case BEDROOM: {
            return BedroomClearImg
        }
        case HALLWAY: {
            return HallwayClearImg
        }
        case BALCONY: {
            return BalconyClearImg
        }
        case LOGGIA: {
            return LogiaClearImg
        }
        default:
            return "";
    }
   
}