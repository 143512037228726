import styled from "styled-components";

export const BorderBox = styled.div`
    border: 0.5px solid #0070BA;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    border-radius: 15px;
    position: relative;
    display: flex;
    padding: 10px;
    max-height: 70px;
    max-width: 300px;
    overflow: hidden;
`;

export const Wrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
`;

export const Image = styled.img `
    width: auto;
    height: 65px;
    border-radius: 50%;
    right: 57px;
    top: 10px;
    margin-right: 15px;
    background-color: #ffffff;
`;

export const DescriptionBlock = styled.div `
    display: flex;
    flex-direction: column;
    left: 54px;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
`;
export const Title = styled.div `
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    color: #0061AA;
    margin-bottom: 5px;
    text-overflow: ellipsis;
`;
export const Description = styled.div `
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: #474747;
    margin-bottom: 9px;
    text-overflow: ellipsis;
`;
