import React from "react";
import { Content } from "../../components/page-content";
import { PageWrapper } from "../../components/page-wrapper";
import { Title, TitleMobile } from "../../components/title";
import { FINAL_CALCULATE } from "../../components/constants";
import { FinalCalculate } from "../../components/final-calculate"
import { Box } from "@material-ui/core";
import { LabelTextBig } from "../../components/text";

export const CalculatePage = () => {
    return (
        <PageWrapper>
        <Content>
            <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                <Title mt={10} mb={30}><LabelTextBig>{FINAL_CALCULATE}</LabelTextBig></Title>
                <TitleMobile mt={10} mb={30}>{FINAL_CALCULATE}</TitleMobile>
            </Box>
            
            <FinalCalculate />
        </Content>
    </PageWrapper>
    )
}