import styled from "styled-components";
import { WallIcon } from "../../static/icons";

export const Wrapper = styled.div`
    position: absolute;
    display: flex;
    padding: 10px;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 3px 3px 81px rgba(0, 0, 0, 0.25);
    border-radius: 7px;

    @media (max-width: 768px){
        display: none;
    };
`;

export const WallIconStyled = styled(WallIcon)`
    width: 124px;
    height: 74px;  

    @media (min-width: 1024px){
        width: 160px;
        height: 95px;      
    };

    @media (min-width: 1280px){
        width: 146px;
        height: 87px;
    };

    @media (min-width: 1920px){
        width: 218px;
        height: 130px;
    };

`;

export const WallWrapper = styled(Wrapper)`
    top: 80px;
    left: 60px;

    @media (min-width: 1024px){
        top: 103px;
        left: 78px; 
    };

    @media (min-width: 1280px){
        top: 94px;
        left: 71px;
    };

    @media (min-width: 1920px){
        top: 141px;
        left: 107px;
    };
`;

export const FloorWrapper = styled(Wrapper)`
    top: 351px;
    left: 64px;

    @media (min-width: 1024px){
        top: 456px;
        left: 83px;
    };

    @media (min-width: 1280px){
        top: 415px;
        left: 76px;
    };

    @media (min-width: 1920px){
        top: 619px;
        left: 114px;
    };
`;

export const FloorWrapperBathroom = styled(Wrapper)`
    top: 380px;
    left: 260px;
    padding: 7px;

    @media (min-width: 1024px){
        top: 490px;
        left: 335px;
    };

    @media (min-width: 1280px){
        top: 450px;
        left: 305px;
    };

    @media (min-width: 1920px){
        top: 680px;
        left: 460px;
    };
`;

export const LivingFloorWrapper = styled(Wrapper)`
        top: 320px;
        left: 64px;

    @media (min-width: 1024px){
        top: 420px;
        left: 83px;
    };

    @media (min-width: 1280px){
        top: 375px;
        left: 76px;
    };

    @media (min-width: 1920px){
        top: 570px;
        left: 114px;
    };
`;

export const TrayWrapper = styled(Wrapper)`
        top: 350px;
        left: 80px;

    @media (min-width: 1024px){
        top: 455px;
        left: 110px;
    };

    @media (min-width: 1280px){
        top: 415px;
        left: 100px;
    };

    @media (min-width: 1920px){
        top: 620px;
        left: 165px;
    };
`;

export const TableTopWrapper = styled(Wrapper)`
        top: 239px;
        left: 136px;

    @media (min-width: 1024px){
        top: 310px;
        left: 180px;
    };

    @media (min-width: 1280px){
        top: 280px;
        left: 170px;
    };

    @media (min-width: 1920px){
        top: 425px;
        left: 279px;
    };
`;

export const SkirtingWrapper = styled(Wrapper)`
        top: 145px;
        left: 322px;

    @media (min-width: 1024px){
        top: 185px;
        left: 415px;
    };

    @media (min-width: 1280px){
        top: 165px;
        left: 388px;
    };

    @media (min-width: 1920px){
        top: 235px;
        left: 579px;
    };
`;

export const Icon = styled.img`
    width: 38px;

    @media (min-width: 1024){
        width: 38px;
    };

    @media (min-width: 1280){
        width: 45px;
    };

    @media (min-width: 1920px){
        width: 58px;
    };
`;