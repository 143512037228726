import styled from "styled-components";
import checkMark from "../../static/img/check-mark.svg"

export const BorderBox = styled.div`
    border: 1.5px solid #FFFFFF;
    width: 100%;
    height: 100%;
    background: #EDEDED;
    border-radius: 12px;
    position: relative;
`;

export const Wrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
`;

export const Number = styled.div`

    position: absolute;
    left: 0;
    top: 0;
    font-weight: bold;
    font-size: 100px;
    line-height: 82px;
    font-family: Roboto;
    color: #0070BA;
    transform: translate(-14px,-21px);
`;

export const CheckInput = styled.input`
    position: absolute;
	z-index: -1;
	opacity: 0;
    visibility: hidden;
	display: block;
	width: 0;
	height: 0;
`;

export const Check = styled.div`
	position: absolute; 
    width: 33px;
    height: 33px;
    right: 9px;
    top: 11px;
    z-index: 1;
	background: #FFFFFF;
    border: 2.50355px solid #0070BA;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    

    ::after {
        content: "";
        visibility: hidden;
        width: 30px;
        height: 30px;
        background: url(${checkMark}) no-repeat center;
    }
`;


export const Label = styled.label`
    display: block;
    margin: 0 0;
	cursor: pointer;
	user-select: none;
	position: relative;
    width: 468px;
    height: 123px;
    margin-top: 30px;

    ${CheckInput}:checked + ${Check}:after{
        visibility: visible;
    }

    ${CheckInput}:checked ${BorderBox}{
        border: 1.5px solid #0070BA;
    }

    :hover ${BorderBox} {
        border: 1.5px solid #0070BA;
    }
`;

export const Image = styled.img `
    position: absolute;
    width: 102px;
    height: 102px;
    border-radius: 50%;
    right: 57px;
    top: 10px;
    background-color: #ffffff;
`;

export const DescriptionBlock = styled.div `
    position: absolute;
    display: flex;
    flex-direction: column;
    transform: translatey(-25px);
    left: 54px;
    width: 80%;

`;
export const Title = styled.div `
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #0061AA;
    margin-bottom: 15px;
`;
export const Description = styled.div `
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #1F1F1F;
    max-width: 70%;
    margin-bottom: 9px;
`;
export const Name = styled.div `
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #0061AA;
    max-width: 70%;
`;
