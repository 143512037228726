import React from "react";
import { ButtonText, LabelTextBig } from "../text"
import { Box } from "@material-ui/core";
import { REZULTS_CALCULATE, CALCULATE_NEXT_ELEMENT, SHARE, WHERE_BUY, DOWNLOAD_PDF, TILE, TILE_HOT_FLOOR, LVT_TILE ,} from "../constants";
import { Button } from "../button";
import { Table } from "../table";
import { getSelectedId, getSelectedRoom, selectCalculate } from "../../store/selectors";
import { useSelector } from "react-redux";
import { getImage, pdfCreator } from "../../utils";
import { useNavigate } from "react-router-dom";
import { VideoBox } from "./styled";
import { AddonItem } from "../addon";

const addon = {
    title: "Набор для очистки остатков эпоксидной затирки",
    description: "Набор для очистки остатков затирки: шпатель со сменными абразивными блоками для удаления остатков эпоксидной затирки с поверхности облицовки + два сменных блока для удаления остатков эпоксидной затирки с поверхности облицовки (жесткие/ мягкие блоки)."
}


const floors = [ TILE, TILE_HOT_FLOOR, LVT_TILE ]

export const FinalCalculate = () => {
    const navigate = useNavigate();

    const selectedId = useSelector(getSelectedId);
    const selectedRoom = useSelector(getSelectedRoom);
    const results = useSelector(selectCalculate);
    const image = getImage(selectedRoom, selectedId );


    const openLink = () => window.open('https://www.mapei.com/ru/ru/-de-kup-ty', '_blank');
    const pdfHandler = () => pdfCreator(selectedRoom, selectedId);

    return (
        <>
            <Box width="100%" display="flex" justifyContent="flex-start" flexWrap="wrap">
               <img src={image} alt="" width="520px"/>
               <Box marginTop="20px" marginLeft="20px" display="flex" flexDirection="column" flex="1 1 40%" alignItems="flex-start">
                    <LabelTextBig>
                        {REZULTS_CALCULATE}
                    </LabelTextBig>
                    <Table results={results} />
                </Box>
            </Box>
            <Box display="flex" justifyContent="stretch" width="100%" marginTop="20px" flexWrap="wrap">
                    <Button onClick={() => navigate("/elements")}><ButtonText>{CALCULATE_NEXT_ELEMENT}</ButtonText></Button>
                    <Button><ButtonText>{SHARE}</ButtonText></Button>
                    <Button onClick={openLink}><ButtonText>{WHERE_BUY}</ButtonText></Button>
                    <Button onClick={pdfHandler}><ButtonText>{DOWNLOAD_PDF}</ButtonText></Button>
            </Box>
            <Box display="flex" flexWrap="wrap" width="100%">
                <Box flex="1" marginRight={15}>
                    <LabelTextBig mb={15}>Дополнительно</LabelTextBig>
                        <VideoBox>
                            <iframe 
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/sh8ymgB9I8k" 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>
                            </iframe>
                        </VideoBox>
                        
                </Box>
                { floors.includes(selectedId) && <Box flex="1" maxHeight={410} overflow="hidden">
                    <LabelTextBig mb={15}>Доп. материалы</LabelTextBig>
                    <Box display="flex" flexWrap="wrap">
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                        <AddonItem title={addon.title} description={addon.description} img="https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kit-frattazzo-3_4207fc540b8f40ecba8eea306604dd59.jpg?sfvrsn=22c6f2cf_0"/>
                    </Box>
                </Box> }
            </Box>
            
        </>
        
    )
}