export const GROUT_COLORS = [
    {
        color: "#FFFFFF",
        name: "100 Белый",
        id: 1,
    },
    {
        color: "#D7DED6",
        name: "103 Белая Луна",
        id: 2,
    },
    {
        color: "#D3D3D6",
        name: "110 Макхеттен 2000",
        id: 3,
    },
    {
        color: "#DDE1E2",
        name: "111 Светло-серый",
        id: 4,
    },
    {
        color: "#898D8C",
        name: "112 Серый",
        id: 5,
    },
    {
        color: "#6A6F69",
        name: "113 Темно-серый",
        id: 6,
    },
    {
        color: "#495058",
        name: "114 Антрацит",
        id: 7,
    },
    {
        color: "#323433",
        name: "120 Черный",
        id: 8,
    },
    {
        color: "#EDDDC4",
        name: "130 Жасмин",
        id: 9,
    },
    {
        color: "#FEECC6",
        name: "131 Ваниль",
        id: 10,
    },
    {
        color: "#E3C9AA",
        name: "132 Бежевый 200",
        id: 11,
    },
    {
        color: "#A39686",
        name: "133 Песочный",
        id: 12,
    }
]