import React from "react";
import { IMaterialItemProps } from "./types";
import {
    Image,
    BorderBox,
    Number,
    CheckInput,
    Check,
    Label,
    DescriptionBlock,
    Title,
    Description,
    Name,
} from "./styled"
import { Box } from "@material-ui/core";

export const MaterialItem = ({
    number,
    value,
    checked,
    onChange,
    title,
    description,
    name,
    img
}: IMaterialItemProps) => {

    return (
        <Box paddingTop="7px" marginBottom="15px" marginRight="15px">
            <Label>
                <CheckInput type="checkbox" value={value} checked={checked} onChange={(e) => onChange(e.target.value) }/>
                <Check/>
                <BorderBox>
                    <Number>{number}</Number>
                    <DescriptionBlock>
                        <Title>{ title }</Title>
                        <Description>{ description }</Description>
                        <Name>{ name }</Name>
                    </DescriptionBlock>
                    <Image src={img}/>
                </BorderBox>
            </Label>
        </Box>
        
    )
}