import styled from "styled-components";

export const  Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    height: 30px;
    background: #0070BA;
    border-radius: 4px;
    width: 100%;
    max-width: 370px;
    margin-bottom: 10px;
    margin-right: 15px;
    cursor: pointer;


    @media (min-width: 320px){
        @media (min-height: 480px){
            height: 32px;
        }
    };


    @media (min-width: 1920px){
        height: 45px;
    }

`;