import { Box } from "@material-ui/core";
import styled from "styled-components";
import { Title } from "../../components/title"

export const MobileButton = styled(Box)`
    @media (min-width: 480px){
        display: none;
    }
`;

export const RoomPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;


    @media (min-width: 320px){
        @media (min-height: 480px){
            left: -18px;
            top: 66px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            left: -11px;
            top: 139px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            left: -12px;
            top: 159px;
        }
    };


    @media (min-width: 480px){
        top: 156px;
        left: 40px;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            left: -15px;
            top: 157px;
        }
    };

    @media (min-width: 768px){
        top: 127px;
        left: 40px;
    };

    @media (min-width: 1024px){
        top: 87px;
        left: 40px;     
        ${Title} {
            margin-left: 75px;
        }   
    };

    @media (min-width: 1280px){
        top: 92px;
        left: 220px;
    };

    @media (min-width: 1920px){
        top: 138px;
        left: 330px;
    };
`;