import React from "react";
import {
    Color,
    Name,
    Block,
} from "./styled"
import { ICheckBlockProps } from "./types";

export const CheckBlock = ({
    name,
    color,
    checked,
    onClick
}: ICheckBlockProps) => {

    return(
        <Block checked={checked} onClick={onClick} >
            <Color color={color}/>
            <Name>{name}</Name>
        </Block>
    )

}