import { StoreType } from "./reducers"
export const getSelectedId = (state: StoreType) => state.elements.activeSelectId;

export const getSelectedRoom = (state: StoreType) => state.rooms.activeRoomId;

export const getCheckedLayers = (state: StoreType) => state.layers.layerSelectIds;

export const groutIsChecked = (state: StoreType) =>{
    const SkirtingsIds = ["7","15","25","36","41","70","83","88","94","99","125","129","147","152","167","182"];
    return state.layers.layerSelectIds.find(el=> SkirtingsIds.includes(el));
} 

export const getInputsValue = (state: StoreType) => state.sizes.inputs;

export const getWindowHoles = (state: StoreType ) => state.sizes.windowHoles;

export const getDoorHoles = (state: StoreType ) => state.sizes.doorHoles;

export const selectCalculate = (state: StoreType) => state.calculate.results;