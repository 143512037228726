import React from "react";
import { ISelectProps } from "./types"
import { MySelect, MyMenuItem } from "./styled";

export const SelectComponent = ({
    options = [],
    value,
    onChange,
}: ISelectProps) => {

    return (

        <MySelect defaultValue={value || options[0]} value={value} onChange={onChange} >
            {options.map(el => {
                return (
                    <MyMenuItem key={el} value={el}>{el}</MyMenuItem>
                ) 
            })}
        </MySelect>
    )
}