import styled from "styled-components";

export const CheckInput = styled.input`
    position: absolute;
	z-index: -1;
	opacity: 0;
    visibility: hidden;
	display: block;
	width: 0;
	height: 0;
`;

export const Check = styled.div`
    display: inline-block;
	position: relative; 
	padding: 0 0 0 35px; 
	line-height: 22px; 
    display: flex;

    @media (min-width: 768px) {
        padding: 0 0 0 23px;
        line-height: 21px;
    }
    
    @media (min-width: 1024px) {
        padding: 0 0 0 32px;
        line-height: 20px;
    }

    @media (min-width: 1280px) {
        padding: 0 0 0 35px; 
	    line-height: 22px; 
    }
    
        
    ::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        border: 2px solid #0070BA;
        border-radius: 1px;

        @media (min-width: 320px){
            @media (min-height: 480px){
                width: 14px;
                height: 14px; 
            }
        }

        @media (min-width: 320px){
            width: 14px;
            height: 14px;      
        };

        @media (min-width: 375px){
            width: 21px;
            height: 21px;      
        };

        @media (min-width: 414px){
            width: 23px;
            height: 23px;      
        };


        @media (min-width: 481px){
            width: 14px;
            height: 14px;      
        };

        @media (min-width: 1024px){
            width: 18px;
            height: 18px;      
        };

        @media (min-width: 1280px){
            width: 17px;
            height: 17px;
        };

        @media (min-width: 1920px){
            width: 25px;
            height: 25px;
        };
    }

    ::after {
        content: "";
        display: inline-block;
        width: 11px;
        height: 11px;
        position: absolute;
        background: #0070BA;
        border-radius: 50%;
        visibility: hidden;

        @media (min-width: 320px){
            @media (min-height: 480px){
                width: 6px;
                height: 6px;
                left: 6px;
                top: 6px;  
            }
        }

        @media (min-width: 320px){
           width: 6px;
            height: 6px;
            left: 6px;
            top: 6px;      
        };

        @media (min-width: 375px){
            width: 9px;
            height: 9px;
            left: 7px;
            top: 7px;     
        };

        @media (min-width: 414px){
            width: 10px;
            height: 10px;
            left: 7px;
            top: 7px;     
        };


        @media (min-width: 481px){
            width: 6px;
            height: 6px;
            left: 6px;
            top: 6px    
        };

        @media (min-width: 1024px){
            width: 8px;
            height: 8px;
            left: 7px;
            top: 7px;     
        };

        @media (min-width: 1280px){
            width: 8px;
            height: 8px;
            left: 7px;
            top: 7px;
        };

        @media (min-width: 1920px){
            width: 11px;
            height: 11px;
            left: 9px;
            top: 9px;
        };
    }
`;

export const BigCheck = styled.div`
    display: inline-block;
	position: relative; 
	padding: 0 0 0 35px; 
	line-height: 22px; 
    display: flex;


    @media (min-width: 768px) {
        padding: 0 0 0 23px;
        line-height: 15px;
    }
    
    @media (min-width: 1024px) {
        padding: 0 0 0 32px;
        line-height: 20px;
    }

    @media (min-width: 1280px) {
        padding: 0 0 0 35px; 
	    line-height: 22px; 
    }
    
        
    ::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        top: 2px;
        border: 2px solid #0070BA;
        border-radius: 1px;

        @media (min-width: 320px){
            width: 22px;
            height: 22px;      
        };

        @media (min-width: 375px){
            width: 21px;
            height: 21px;      
        };

        @media (min-width: 414px){
            width: 23px;
            height: 23px;      
        };

        @media (max-width: 480px){
            width: 27px;
            height: 27px;      
        };

        @media (min-width: 481px){
            width: 14px;
            height: 14px;      
        };

        @media (min-width: 768px){
            width: 22px;
            height: 22px;      
        };

        @media (min-width: 1280px){
            width: 22px;
            height: 22px;
        };

        @media (min-width: 1920px){
            width: 33px;
            height: 33px;
            border: 3px solid #0070BA;
        };
    }

    ::after {
        content: "";
        display: inline-block;
        width: 11px;
        height: 11px;
        position: absolute;
        background: #0070BA;
        border-radius: 50%;
        visibility: hidden;

        @media (min-width: 320px){
           width: 6px;
            height: 6px;
            left: 7px;
            top: 7px;      
        };

        @media (min-width: 375px){
            width: 9px;
            height: 9px;
            left: 7px;
            top: 7px;     
        };

        @media (min-width: 414px){
            width: 10px;
            height: 10px;
            left: 7px;
            top: 7px;     
        };

        @media (max-width: 480px){
            width: 12px;
            height: 12px;
            left: 9px;
            top: 9px;      
        };

        @media (min-width: 481px){
            width: 6px;
            height: 6px;
            left: 6px;
            top: 6px    
        };

        @media (min-width: 768px){
            width: 10px;
            height: 10px;
            left: 8px;
            top: 10px;     
        };

        @media (min-width: 1920px){
            width: 15px;
            height: 15px;
            left: 12px;
            top: 14px;
        };
    }
`;


export const Label = styled.label`
    display: block;
    margin: 4px 0;
	cursor: pointer;
	user-select: none;
	position: relative;

    ${CheckInput}:checked + ${Check}:after{
        visibility: visible;
    }

    ${CheckInput}:checked + ${BigCheck}:after{
        visibility: visible;
    }

    @media (min-width: 1024px){
        margin: 3px 0;
    };

    @media (min-width: 1280px){
        margin: 2px 0;
    };

    @media (min-width: 1920px){
        margin: 7px 0;
    };

`;

export const Icon = styled.div`
    width: 48px;
    height: 48px;
    margin-left: 30px;
    margin-right: 10px;


    
    @media (min-width: 768px){
        width: 32px;
        height: 32px;
        margin-left: 22px;
        svg {
            width: 32px;
            height: 32px;
        }
    };

    @media (min-width: 1920px){
        width: 48px;
        height: 48px;
        svg {
            width: 48px;
            height: 48px;
        }
    };

`;


