import styled from "styled-components";
import { ITitle } from "./types"


export const Title = styled.div<ITitle>`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #0070BA;
    margin-left: ${({ml}) => ml}px;
    margin-right: ${({mr}) => mr}px;
    margin-top: ${({mt}) => mt}px;
    margin-bottom: ${({mb}) => mb}px;
    @media (max-width: 480px){
           display: none;
    };

    @media (min-width: 480px){
        font-size: 16px;
    }; 

    @media (min-width: 1920px){
        font-size: 24px;
    };  
`;

export const TitleMobile = styled.div<ITitle>`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #0070BA;
    margin-left: ${({ml}) => ml}px;
    margin-right: ${({mr}) => mr}px;
    margin-top: ${({mt}) => mt}px;
    margin-bottom: ${({mb}) => mb}px;

    @media (min-width: 320px){
        @media (min-height: 480px){
            font-size: 12px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            font-size: 14px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            font-size: 15px;
        }
    };

    @media (min-width: 480px){
        display: none;
    }; 

    @media (min-width: 480px){
        @media (min-height: 800px){
            font-size: 18px;
        }
    };
`;

export const BigTitle = styled.div<ITitle>`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #0070BA;
    margin-left: ${({ml}) => ml}px;
    margin-right: ${({mr}) => mr}px;
    margin-top: ${({mt}) => mt}px;
    margin-bottom: ${({mb}) => mb}px;
    @media (max-width: 480px){
           display: none;
    };

    @media (min-width: 480px){
        font-size: 16px;
    }; 


    @media (min-width: 768px){
        font-size: 24px;
    };  

    @media (min-width: 1920px){
        font-size: 36px;
    };  
`;

export const MediumTitle = styled.div<ITitle>`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    color: #0070BA;
    margin-left: ${({ml}) => ml}px;
    margin-right: ${({mr}) => mr}px;
    margin-top: ${({mt}) => mt}px;
    margin-bottom: ${({mb}) => mb}px;
    @media (max-width: 480px){
           display: none;
    };

    @media (min-width: 480px){
        font-size: 16px;
    }; 


    @media (min-width: 768px){
        font-size: 18px;
    };  

    @media (min-width: 1920px){
        font-size: 27px;
    };  
`;