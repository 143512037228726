import {  BATHROOM,
    LIVINGROOM,
    KITCHEN,
    CHILDREN_ROOM,
    BEDROOM,
    HALLWAY,
    BALCONY,
    LOGGIA,
    PALLETE,
    TABLE_TOP_TITLE,
    SKIRTING_TITLE, 
    PAINT,
    WALLPERS,
    TILE,
    TILE_HOT_FLOOR,
    LVT,
    LVT_TILE,
    PARQUET,
    CARPET,
    LAMINATE} from "./components/constants";
import LivingRoomImg from "./static/img/elements-livingroom.jpg";
import LivingRoomWallImg from "./static/img/livingroom-wall.jpg";
import LivingRoomFloorImg from "./static/img/livingroom-floor.jpg";

import TabletopLayersImg from "./static/img/kitchen-table-top.jpg";
import KitchenSkirtingImg from "./static/img/kitchen-skirting.jpg";
import KitchenWallImg from "./static/img/kitchen-wall.jpg";
import KitchenFloorImg from "./static/img/kitchen-floor.jpg";

import KitchenLayersImg from "./static/img/kitchen-layers.jpg";
import HallWayLayersImg from "./static/img/hallway-layers.jpg";
import HallWayWallImg from ".//static/img/hallway-wall.jpg"
import HallWayFloorImg from ".//static/img/hallway-floor.jpg"
import ChildrensroomLayersImg from "./static/img/childrensroom-layers.jpg";
import ChildrensroomLayersFloorImg from "./static/img/elements-children-room-floor.jpg";
import ChildrensroomLayersWallImg from "./static/img/elements-children-room-wall.jpg";
import BedroomLayersImg from "./static/img/bedroom-layers.jpg";
import BedroomFloorImg from "./static/img/bedroom-floor.jpg";
import BedroomWallImg from "./static/img/bedroom-wall.jpg";
import bathWall from "./static/img/bath-wall.jpg";
import bathFloor from "./static/img/bath-floor.jpg";
import palletAll from "./static/img/pallet-all.jpg";
import BalconyLayersImg from "./static/img/balcony-layers.jpg";
import BalconyFloorImg from "./static/img/balkon-floor.jpg";
import BalconyWallImg from "./static/img/balkon-wall.jpg";
import LogiaWallImg from "./static/img/logia-wall.jpg";
import LogiaFloorImg from "./static/img/logia-floor.jpg";
import { TActiveSelectedId } from "./store/elements/types";
import { TActiveRoomSelectedId } from "./store/room-selector/types";
//@ts-ignore
import html2pdf from "html2pdf.js";

interface IWindowHoles {
    windowWidth: string,
    windowHeight: string,
}

interface IDoorHoles {
    doorWidth: string,
    doorHeight: string,
}
interface IgetSquareWall {
    perimeter: number;
    HWall: number;
    doors?: IDoorHoles[];
    windows?: IWindowHoles[];
}

type TParametrs = number | null | undefined;

const PLASTER_THICKNESS = 10;


export const getParams = (params: string[], inputsValues: any, elem: { [x: string]: any; } ): any[] => {
    return params.map((el) => {
        if(el === "coeff"){
            return elem[el] || 1
        }
        if(el === "heatFloor"){
            return elem[el] || false
        }
        if(!Object.keys(inputsValues).includes(el) || !inputsValues[el]){
            return elem[el]
        }
        return inputsValues[el]
    })
}


export const getSelfLevelingFloor = (
    floorSquare: TParametrs, 
    heightDifference: number, 
    floorLength: TParametrs, 
    floorWidth: TParametrs,
    heatFloor: boolean, 
    additionParametr: number = 0,
    coeff: number = 1.8 
) => {
    let heightDifferenceTrue = heatFloor ? heightDifference + additionParametr + 20 : heightDifference + additionParametr;
    if(floorSquare)
        return  floorSquare*coeff*heightDifferenceTrue;
    if (floorLength && floorWidth)
        return floorLength*floorWidth*coeff*heightDifferenceTrue;
}

export const getSelfLevelingFloorForRooms = (
    floorSquare: TParametrs, 
    floorLength: TParametrs, 
    floorWidth: TParametrs, 
    heightDifference: number, 
    coeff: number = 1.8 
) => {
    let heightDifferenceTrue =  heightDifference + 1
    if (floorSquare){
        return floorSquare*coeff*heightDifferenceTrue;
    }
    if (floorLength && floorWidth)
        return floorLength*floorWidth*coeff*heightDifferenceTrue;
}

export const getSelfLevelingFloorForBedRoom = (
    floorSquare: TParametrs, 
    floorLength: TParametrs, 
    floorWidth: TParametrs, 
    heightDifference: number, 
    coeff: number = 1.8 
) => {
    let heightDifferenceTrue =  heightDifference + 3
    if (floorSquare) 
        return floorSquare*coeff*heightDifferenceTrue;
    if (floorLength && floorWidth)
        return floorLength*floorWidth*coeff*heightDifferenceTrue;
    return 0;
}

export const getPlasterWithlevelingLayer = (
    square:  number, 
    levelingLayer: number,
    coeff: number = 1
) => {
    return square*levelingLayer*coeff
}

export const getPriming = (
    square:  TParametrs , 
    width: TParametrs, 
    height:  TParametrs, 
    coeff: number = 1
) => {
    if (square) 
        return square*coeff;
    if (width && height)
        return width*height*coeff;
}

export const getPaint = (square: number, coeff: number = 1) => {
    let count 
    count = square*coeff*2;
    return count;
}

export const getPrimingInHalf = (square: TParametrs, length:TParametrs, width:TParametrs, coeff: number = 1) => {
    if(square) {
        return (square*coeff)/2
    }
    if (width && length)
        return (width*length*coeff)/2;
}

export const getLevelingCompaund = (square: number, coeff: number = 1) => {
    return square*coeff*4;
}

export const getPlaster = (square: number, coeff: number = 1) => {
    return square*coeff*PLASTER_THICKNESS;
}

export const getGlueTailForLogia = (
    floorSquare: number, 
    floorLength: number,
    floorWidth: number,
    tailWidth: number,
    tailHeight: number,
) => {
    const square = floorSquare ? floorSquare : floorLength * floorWidth;
    let count 
    if (tailWidth < 110 && tailHeight < 110){ 
        count = square*2;
        return count;
    }
    if ((tailWidth >= 110 && tailWidth <= 400) || (tailHeight >= 110 && tailHeight <= 400)){ 
        count = square*3;
        return count;
    }
    if ((tailWidth >= 410 && tailWidth <= 600) || (tailHeight >= 410 && tailHeight <= 600)){ 
        count = square*4;
        return count;
    }
    if ((tailWidth < 600 && tailHeight > 600) || (tailHeight < 600 && tailWidth > 600)){ 
        count = square*5;
        return count;
    }
}


export const getGlueForTail = (
    floorSquare: number, 
    floorLength: number,
    floorWidth: number,
    tailWidth: number,
    tailHeight: number,
) => {
    const square = floorSquare ? floorSquare : floorLength * floorWidth;
    let count 
    if (tailWidth < 110 && tailHeight < 110){ 
        count = square*2;
        return count;
    }
    if ((tailWidth >= 110 && tailWidth <= 400) || (tailHeight >= 110 && tailHeight <= 400)){ 
        count = square*3;
        return count;
    }
    if ((tailWidth >= 410 && tailWidth <= 600) || (tailHeight >= 410 && tailHeight <= 600)){ 
        count = square*5
        return count;
    }
    return square*5;
}

export const getSutureFiller = (
    floorSquare: number, 
    floorLength: number,
    floorWidth: number,
    tailWidth: number,
    tailHeight: number,
    tailThickness: number,
    jointWidth: number,
    coeff: number = 1.6
) => {
    const square = floorSquare ? floorSquare : floorLength * floorWidth;
    
    return (((Number(tailWidth) + Number(tailHeight))/(tailWidth * tailHeight))*tailThickness*jointWidth*coeff*square)
}

export const getSquareWall = ({
    perimeter,
    HWall, 
    doors=[],
    windows=[],
}: IgetSquareWall) => {
    let summDoors: number = 0, 
        summWindows: number = 0;
    doors.forEach(el => {
        summDoors += parseFloat(el.doorHeight)* parseFloat(el.doorWidth);
    })
    windows.forEach (el => {
        summWindows +=  parseFloat(el.windowHeight)* parseFloat(el.windowWidth);
    })

    return Math.ceil(perimeter*HWall-(summDoors)-(summWindows));
}

export const getSealantCount = (perimetr: number, coef: number) => perimetr/coef; 

export const getSealantCountSquare = (square: number, width: number, height: number, coef: number) => {
    if (square) {
        return (square*2+2)/coef;
    }
    return ((width+height)*2)/coef; 
} 

export const getSealantCountForSkirt = (height: number , lenght: number) => ((height+lenght)*2)/25;

const WALLS = [PAINT, WALLPERS, TILE];
const FLOORS = [
    LVT,
    TILE_HOT_FLOOR,
    LVT_TILE,
    PARQUET,
    CARPET,
    LAMINATE,
];

export const getImage = (selectedRoom: TActiveRoomSelectedId, selectedElem: TActiveSelectedId) => {
    switch (selectedRoom) {
        case BATHROOM: {
            if (WALLS.includes(selectedElem))
                return bathWall;
            if (FLOORS.includes(selectedElem))
                return bathFloor;
            if (selectedElem === PALLETE) {
                return palletAll;
            }
            return bathWall;
        }
        case LIVINGROOM:
            if (WALLS.includes(selectedElem))
                return LivingRoomWallImg;
            if (FLOORS.includes(selectedElem))
                return LivingRoomFloorImg;
            return LivingRoomImg;
        case KITCHEN:
            if (WALLS.includes(selectedElem))
                return KitchenWallImg;
            if (FLOORS.includes(selectedElem))
                return KitchenFloorImg;
            if (selectedElem === TABLE_TOP_TITLE)
                return TabletopLayersImg;
            if (selectedElem === SKIRTING_TITLE)
                return KitchenSkirtingImg;
            return KitchenLayersImg;
        case CHILDREN_ROOM:
            if (WALLS.includes(selectedElem))
                return ChildrensroomLayersWallImg;
            if (FLOORS.includes(selectedElem))
                return ChildrensroomLayersFloorImg;
            return ChildrensroomLayersImg;   
        case BEDROOM:
            if (WALLS.includes(selectedElem))
                return BedroomWallImg;
            if (FLOORS.includes(selectedElem))
                return BedroomFloorImg;
            return BedroomLayersImg;
        case HALLWAY:
            if (WALLS.includes(selectedElem))
                return HallWayWallImg;
            if (FLOORS.includes(selectedElem))
                return HallWayFloorImg;
            return HallWayLayersImg;
        case BALCONY:
            if (WALLS.includes(selectedElem))
                return BalconyWallImg;
            if (FLOORS.includes(selectedElem))
                return BalconyFloorImg;
            return BalconyLayersImg;
        case LOGGIA:
            if (WALLS.includes(selectedElem))
                return LogiaWallImg;
            if (FLOORS.includes(selectedElem))
                return LogiaFloorImg;
            return BalconyLayersImg;
    }
    return bathWall
}


export const pdfCreator = (roomName: string, elementName: string) => {

    const element = document.getElementById("calculate-tab")
    let opt = {
        margin:       1,
        filename:     `Mapei_расчет_${roomName}_${elementName}.pdf`,
        enableLinks:  true,
        image:        { type: 'jpeg', quality: 0.98 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
      };

     html2pdf().set(opt).from(element,"element").save();
}


export const getGlueInfo = (
    tailWidth: number, 
    tailLength: number
) => {
    if(tailWidth <= 400 ||  tailLength <= 400 ) {
        return {
            material: "Kerabond T-R",
            img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/kerabond_t-r_gray_rus_2021_9bfa6f7b27a5493c9233d1faf958e42f.png?sfvrsn=74d3c31f_0",
            link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/kerabond-t-r"],
        }
    }

    if((tailWidth > 400 && tailLength > 400) && tailWidth <= 600 && tailLength <= 600) {
        return {
            material: "Adesilex P7",
            img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/adesilex-p7-gray_rus_2021_f8a57cf559af4060a107ed7583d2a5d5.png?sfvrsn=800c8368_0",
            link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/adesilex-p7"],
        }
    }

    if((tailWidth > 600 && tailLength > 600) && tailWidth <= 1200 && tailLength <= 1200) {
        return {
            material: "Keraflex Maxi S1",
            img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/keraflexmaxi-2019-gray.png?sfvrsn=44d2cb6f_0",
            link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/keraflex-maxi-s1"],
        }
    }

    return {
        material: "Ultralite S2",
        img: "https://cdnmedia.mapei.com/images/librariesprovider50/products-images/ultralite_s2_rus_meshok_2019.png?sfvrsn=8b126586_0",
        link: ["https://www.mapei.com/ru/ru/produkty/spisok-produktov/opisanie-produkta/ultralite-s2"],
    }
}
