import { 
    INPUT_CHANGE,
    ADD_WINDOW_HOLE,
    REMOVE_WINDOW_HOLE,
    WINDOW_INPUT_CHANGE,
    ADD_DOOR_HOLE,
    REMOVE_DOOR_HOLE,
    DOOR_INPUT_CHANGE
 } from "./sizes-constants";
import { TSizesActions, ISizesState } from "./types"


const defaultState: ISizesState = {
    inputs: {
        square: "0",
        floorWidth: "0",
        floorLength: "0",
        typeOfBase: "0",
        elevationDifferences: "10",
        tailLength: "0",
        tailWidth: "0",
        tailThickness: "0",
        jointWidth: 1,
        wallPerimeter: "0",
        wallHeight: "0",
        wallSquare: 0,
        tableTopWeight: "0",
        tableTopWidth: "0",
        tableTopHeight: "0",
        palleteLength: "0",
        palleteWidth: "0",
        palleteHeight: "0",
        countAngle: "0",
        palleteWallHeight: "0",
        palleteWallPerimetr: "0",
        skirtingHeight: "0",
        skirtingLenght: "0",
        levelingLayer: 5,
    },
    windowHoles: [
        {
            windowWidth: "0",
            windowHeight: "0",
        }
    ],
    doorHoles: [
        {
            doorWidth: "0",
            doorHeight: "0",
        }
    ]
}

export const sizes = (
    state: ISizesState = defaultState,
    action: TSizesActions
) => {
    switch (action.type) {
        case INPUT_CHANGE: {
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    [action.name]: parseFloat(action.value) >= 0 || action.value === "" ? action.value : "0"
                } 
            }
        }
        case ADD_WINDOW_HOLE: {
            return {
                ...state,
                windowHoles: [
                    ...state.windowHoles,
                    {
                        windowWidth: "",
                        windowHeight: "",
                    }
                ]
            }
        }
        case REMOVE_WINDOW_HOLE: {
            const newHoles = [...state.windowHoles];
            newHoles.pop();
            return {
                ...state,
                windowHoles: newHoles
            }
        }
        case WINDOW_INPUT_CHANGE: {
            const newHoles = [...state.windowHoles];
            newHoles[action.id][action.name] =  parseFloat(action.value) > 0 || action.value === "" ? action.value : "0"
            return {
                ...state,
                windowHoles: newHoles,
            }
        }
        case ADD_DOOR_HOLE: {
            return {
                ...state,
                doorHoles: [
                    ...state.doorHoles,
                    {
                        doorWidth: "",
                        doorHeight: "",
                    }
                ]
            }
        }
        case REMOVE_DOOR_HOLE: {
            const newHoles = [...state.doorHoles];
            newHoles.pop();
            return {
                ...state,
                doorHoles: newHoles
            }
        }
        case DOOR_INPUT_CHANGE: {
            const newHoles = [...state.doorHoles];
            newHoles[action.id][action.name] =  parseFloat(action.value) > 0 || action.value === "" ? action.value : "0"
            return {
                ...state,
                doorHoles: newHoles,
            }
        }
        default:
            return state;
    }
};