import React from "react";
import { ISVGProps } from "./types";

export const SizeIcon = ({
    width = 48,
    height = 48,
    fill = "#0070BA",
}:ISVGProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.201 11.6069L31.433 19.375H36.75C37.7856 19.375 38.625 20.2144 38.625 21.25C38.625 22.2856 37.7856 23.125 36.75 23.125H29.7188C27.134 23.125 25.0312 21.0222 25.0312 18.4375V11.4062C25.0312 10.3706 25.8706 9.53125 26.9062 9.53125C27.9419 9.53125 28.7812 10.3706 28.7812 11.4062V16.7233L36.549 8.95557C37.2814 8.22314 38.4686 8.22314 39.2007 8.95557C39.9331 9.68762 39.9331 10.8749 39.201 11.6069ZM21.1875 38.6875C22.2231 38.6875 23.0625 37.8481 23.0625 36.8125V29.7812C23.0625 27.1965 20.9597 25.0938 18.375 25.0938H11.3438C10.3081 25.0938 9.46875 25.9331 9.46875 26.9688C9.46875 28.0044 10.3081 28.8438 11.3438 28.8438H16.6608L8.8927 36.6118C8.16064 37.3439 8.16064 38.5311 8.8927 39.2632C9.25891 39.6294 9.73901 39.8125 10.2188 39.8125C10.6985 39.8125 11.1786 39.6294 11.5448 39.2632L19.3125 31.4955V36.8125C19.3125 37.8481 20.1519 38.6875 21.1875 38.6875ZM46.125 33.25C47.1606 33.25 48 32.4106 48 31.375V7.75C48 3.61438 44.6356 0.25 40.5 0.25H7.5C3.36438 0.25 0 3.61438 0 7.75V40.75C0 44.8856 3.36438 48.25 7.5 48.25H40.5C44.6356 48.25 48 44.8856 48 40.75C48 39.7144 47.1606 38.875 46.125 38.875C45.0894 38.875 44.25 39.7144 44.25 40.75C44.25 42.8176 42.5676 44.5 40.5 44.5H7.5C5.43237 44.5 3.75 42.8176 3.75 40.75V7.75C3.75 5.68237 5.43237 4 7.5 4H40.5C42.5676 4 44.25 5.68237 44.25 7.75V31.375C44.25 32.4106 45.0894 33.25 46.125 33.25Z" fill={fill}/>
        </svg>
    )
}



