import { UPDATE_CALCULATE_TAB } from "./constants"
import { IResultsState,  IResultUpdateAction, } from "./types"


const defaultState: IResultsState = {
    results: [],
}

export const calculate = (
    state: IResultsState = defaultState,
    action: IResultUpdateAction
) => {
    switch (action.type) {
        case UPDATE_CALCULATE_TAB: {
            return {
                ...state,
                results: action.results 
            }
        }
        default:
            return state;
    }
};