import React from "react";
import { ISVGProps } from "./types"

export const WallIcon = ({
    width = 34,
    height = 32,
    fill = "#006FB9",
}:ISVGProps) =>
    <svg width={width} height={height} viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.194 0.126667C17.9644 -0.0424648 17.6514 -0.0421982 17.422 0.127324L0.263635 12.8123C-0.0250307 13.0257 -0.0860405 13.4327 0.127366 13.7214C0.340773 14.01 0.747783 14.071 1.03645 13.8576L17.8091 1.45783L34.6426 13.8583C34.9317 14.0712 35.3386 14.0095 35.5515 13.7205C35.7644 13.4315 35.7027 13.0245 35.4137 12.8116L18.194 0.126667ZM17.8706 20.7194L14.4186 23.5673H23.1398C23.5129 23.5673 23.8153 23.8697 23.8153 24.2428C23.8153 24.6159 23.5129 24.9183 23.1398 24.9183H14.4186L17.8706 27.7662C18.1584 28.0037 18.1992 28.4294 17.9618 28.7172C17.7244 29.005 17.2987 29.0458 17.0109 28.8084L12.1118 24.7667C12.094 24.7521 12.077 24.7367 12.0607 24.7205C12.0427 24.7024 12.0257 24.6834 12.0098 24.6635C11.9178 24.548 11.8629 24.4018 11.8629 24.2428C11.8629 24.0308 11.9606 23.8415 12.1135 23.7177L17.0109 19.6773C17.2987 19.4399 17.7244 19.4807 17.9618 19.7685C18.1992 20.0562 18.1584 20.482 17.8706 20.7194ZM4.69453 14.6817C4.32146 14.6817 4.01903 14.9841 4.01903 15.3572V33.251C4.01903 33.624 4.32146 33.9265 4.69453 33.9265H6.77805H30.9837C31.3567 33.9265 31.6592 33.624 31.6592 33.251V15.3572C31.6592 14.9841 31.3567 14.6817 30.9837 14.6817C30.6106 14.6817 30.3082 14.9841 30.3082 15.3572V32.5755H7.45355V15.3572C7.45355 14.9841 7.15112 14.6817 6.77805 14.6817H4.69453ZM6.10255 16.0327V32.5755H5.37003V16.0327H6.10255Z" fill={fill}/>
    </svg>
