export const TITLE = "Калькулятор расходов материалов";
export const EMAIL = "test@mail.ru";
export const NUMBER = "+7(903) 123-23-34";

export const SELECT_ROOM = "Наведите на картину и выберете нужную вам комнату";
export const SELECT_ROOM_MOBILE = "Выберете нужную Вам комнату";
export const SELECT_ELEMENTS = "Наведите на нужные элементы комнаты и выберете подходящие материалы";
export const SELECT_ELEMENTS_MIN = "Выберете подходящие материалы";

export const ROOMS = "Комната";
export const ELEMENTS = "Элементы";
export const SIZES = "Размеры";
export const LAYERS = "Слои";
export const CALCULATE = "Расчет";

export const BATHROOM = "Ванная комната";
export const LIVINGROOM = "Гостинная комната";
export const KITCHEN = "Кухня";
export const CHILDREN_ROOM = "Детская";
export const BEDROOM = "Спальня";
export const HALLWAY = "Прихожая";
export const BALCONY = "Балкон";
export const LOGGIA = "Лоджия";
export const LEFT = "left";
export const RIGHT = "right";
export const TILE = "Плитка";
export const FLOOR_TILE = "Плиткa для пола";
export const PAINT = "Краска";
export const LVT = "ЛВТ";
export const TILE_HOT_FLOOR = "Плитка + теплый пол";
export const LVT_TILE = "ЛВТ плитка";
export const PALLETE = "Поддон";

export const NEXT = "Далее";

export const SIZES_TITLE = "Введите размеры для расчета материалов пола";
export const SIZE_CHOISE = "Выберете площадь или размер?";
export const SIZE = "Размер";
export const SQUARE = "Площадь";

export const TYPE_BASE = "Тип основания";
export const HEIGHT_DIFFERENCE = "Перепад высоты";
export const LEVELING_LAYER = "Выравнивающий слой";
export const TYPE_TYLE = "Тип плитки";
export const SIZE_TILE = "Размеры плитки";

export const WIDTH = "Длина";
export const HEIGHT = "Ширина";
export const WEIGHT = "Толщина";
export const ELEVATION = "Высота"

export const JOINT_WIDTH = "Ширина шва"

export const WALL_SIZES = "Размер стены"
export const WALL_SIZES_TITLE = "Введите размеры для расчета материалов стены"

export const WALL_PERIMETER = "Периметр стен"
export const WALL_HEIGHT = "Высота стен";
export const WINDOW_HALL = "Оконный проём";
export const WIDTH_HALL = "Ширина проёма";
export const HEIGHT_HALL = "Высота проёма";
export const COUNT_HALL = "Количество проемов"
export const DOOR_SIZES_TITLE = "Введите размеры для расчета двери"
export const DOOR_HALL = "Дверные проемы"

export const PALLET_WATERPROOF_TITLE =  "Гидроизоляция поддона";
export const PALLET_PARAMETRS =  "Параметры поддона";
export const COUNT_UNGLE =  "Количество углов";
export const BOARD_HEIGHT = "Высота борта(м)";

export const TABLE_TOP_TITLE =  "Столешница";
export const SKIRTING_TITLE =  "Фартук";
export const WEIGHT_TABLE_TOP =  "Толщина столешницы";
export const TABLE_TOP_PARAMETRS =  "Параметры столешницы";
export const SKIRTING_PARAMETRS =  "Параметры фартука";

export const SELECT_LAYERS_TITLE = "Выберите подходящие слои"
export const SELECT_ROOM_LABEL = "Выберите комнату";
export const SELECT_MATERIALS = "Выберете интересующие Вас материалы"
export const GROUT = "Затирка";
export const PALETTE_COLOR = "Цвет палитры";

export const FINAL_CALCULATE = "Финальный расчет";
export const REZULTS_CALCULATE = "Результаты расчета";

export const CALCULATE_NEXT_ELEMENT = "Расчитать следующий элемент";
export const SHARE = "Поделиться";
export const WHERE_BUY = "Где купить?";
export const DOWNLOAD_PDF = "Скачать PDF"

export const PARQUET = "Паркет";
export const CARPET = "Ковровое покрытие";
export const LAMINATE = "Ламинат"
export const WALLPERS = "Обои"

export const FLOOR = "Пол"
export const WALLS = "Стены"




