import React from "react";
import { BigTitle, TitleMobile } from "../title";
import { 
    WIDTH,
    HEIGHT,
    WALL_SIZES,
    WALL_PERIMETER,
    WALL_HEIGHT,
    PALLET_WATERPROOF_TITLE,
    PALLET_PARAMETRS,
    COUNT_UNGLE,
    BOARD_HEIGHT
} from "../constants";
import { 
    palleteLength,
    palleteWidth,
    palleteHeight,
    countAngle,
    palleteWallHeight,
    palleteWallPerimetr,
    INPUT_CHANGE,
} from "../../store/sizes/sizes-constants"
import { Box } from "@material-ui/core";
import { BlackText, LabelTextBig } from "../text";
import { TextInput } from "../text-input";
import { useDispatch, useSelector } from "react-redux";
import { getInputsValue } from "../../store/selectors";
import palletAllImg from "../../static/img/pallet-all.jpg";



export const PalletWaterproof = () => {

    const dispatch = useDispatch();
    const inputsValue = useSelector(getInputsValue)

    const inputHandler = (value: string | number, name: string) => {
        dispatch({
            type: INPUT_CHANGE,
            name,
            value,
        })
    } 

    return (
        <>
            <Box alignSelf="center"> <BigTitle mt={10} mb={30}>{PALLET_WATERPROOF_TITLE}</BigTitle></Box>
            <TitleMobile mt={10} mb={30}>{PALLET_WATERPROOF_TITLE}</TitleMobile>
            <Box width="100%" display="flex" flexWrap="wrap-reverce">
                <Box display="flex" flexDirection="column" marginRight="70px">
                    <BlackText mb={9} mr={30}>{PALLET_PARAMETRS}</BlackText>
                    <Box display="flex" justifyContent="flex-end"  alignItems="center" marginBottom="30px">
                            <Box flex="1"><LabelTextBig textAlign="right" mr={15} mb={7}>{BOARD_HEIGHT}</LabelTextBig></Box>  
                            <Box flex="1">
                                <TextInput 
                                    value={inputsValue[palleteHeight]} 
                                    onChange={(value) => inputHandler(value, palleteHeight)}
                                />
                            </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom="30px">
                            <Box flex="1"><LabelTextBig textAlign="right" mr={15} mb={7}>{WIDTH}(м)</LabelTextBig></Box>  
                            <Box flex="1">
                                <TextInput 
                                    value={inputsValue[palleteWidth]} 
                                    onChange={(value) => inputHandler(value, palleteWidth)}
                                />
                            </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom="30px">
                            <Box flex="1"><LabelTextBig textAlign="right" mr={15} mb={7}>{HEIGHT}(м)</LabelTextBig></Box>  
                            <Box flex="1">
                                <TextInput 
                                    value={inputsValue[palleteLength]} 
                                    onChange={(value) => inputHandler(value, palleteLength)}
                                />
                            </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom="30px">
                            <Box flex="1"><LabelTextBig textAlign="right" mr={15} mb={7}>{COUNT_UNGLE}</LabelTextBig></Box>  
                            <Box flex="1">
                                <TextInput 
                                    value={inputsValue[countAngle]} 
                                    onChange={(value) => inputHandler(value, countAngle)}
                                />
                            </Box>
                    </Box>
                    <BlackText mb={9} mr={30}>{WALL_SIZES}</BlackText>
                    <Box display="flex" justifyContent="flex-end"  alignItems="center" marginBottom="30px">
                            <Box flex="1"><LabelTextBig textAlign="right" mr={15} mb={7}>{WALL_PERIMETER}(м)</LabelTextBig></Box>  
                            <Box flex="1">
                                <TextInput 
                                    value={inputsValue[palleteWallPerimetr]} 
                                    onChange={(value) => inputHandler(value, palleteWallPerimetr)}
                                />
                            </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end"  alignItems="center" marginBottom="30px">
                            <Box flex="1"><LabelTextBig textAlign="right" mr={15} mb={7}>{WALL_HEIGHT}(м)</LabelTextBig></Box>  
                            <Box flex="1">
                                <TextInput 
                                    value={inputsValue[palleteWallHeight]} 
                                    onChange={(value) => inputHandler(value, palleteWallHeight)}
                                />
                            </Box>
                    </Box>
                </Box>
                <Box flex="1 1 40%" >
                    <img src={palletAllImg} alt="badhroom pallete" width="100%" />
                </Box>
            </Box>
        </>
    )
    
}