import React from "react";
import { LabelTextBig } from "../text";
import { 
    GROUT,
    PALETTE_COLOR,
} from "../constants";
import { Box } from "@material-ui/core";
import { SelectComponent } from "../select";
import { CheckBlock  } from "../check-block";
import { GROUT_COLORS } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../store/reducers";
import { LAYERS_PAGE__SELECT_COLOR } from "../../store/layers/layers-constants";



export const SelectGrout = () => {

    const dispatch = useDispatch();
    const selectedColorId = useSelector((s: StoreType )=> s.layers.selectedColorId)
    const onChangeColor = (id: string | number) => {
        dispatch({
            type: LAYERS_PAGE__SELECT_COLOR,
            id
        })
    }


    return (
        <Box width="80%" display="flex" flexDirection="column" alignItems="flex-start" marginTop="50px">
            <Box display="flex" justifyContent="center" marginBottom="50px">
                <LabelTextBig mb={7} mr={20}>{GROUT}</LabelTextBig>
                <SelectComponent options={["Затирка1", "Затирка2"]} />
            </Box>
            <Box width="100%" display="flex" justifyContent="center" marginBottom="28px">
                <LabelTextBig mb={7}>{PALETTE_COLOR}</LabelTextBig>
            </Box>
            <Box width="100%" display="flex" flexWrap="wrap">
                {
                    GROUT_COLORS.map((el) => 
                        <CheckBlock 
                            key={el.id} 
                            checked={selectedColorId === el.id} 
                            color={el.color} 
                            name={el.name}
                            onClick={() => onChangeColor(el.id)}
                        />)
                }
            </Box>
        </Box>
            
        
    )
}