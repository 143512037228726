import styled from "styled-components";
import minus from "../../static/img/minus-icon.svg";
import plus from "../../static/img/plus-icon.svg"

export const SmallButton = styled.div`
   
    background-color: #FFFFFF;
    border: 2px solid #0070BA;
    border-radius: 7.5px;

    @media (min-width: 320px){
        width: 20px;
        height: 20px;
        background-size: 12px 12px;
        border-radius: 5px;
    }
    
    @media (min-width: 375px){
        width: 24px;
        height: 24px;
        background-size: 16px 16px;
        border-radius: 5px;
    }

    @media (min-width: 414px){
        width: 27px;
        height: 27px;
        background-size: 18px 18px;
        border-radius: 5px;
    }
    
    @media (min-width: 480px){
        width: 30px;
        height: 30px;
        background-size: 18px 18px;
        border-radius: 5px;
    }

    @media (min-width: 768px){
        width: 30px;
        height: 30px;
        background-size: 18px 18px;
    }
    
    @media (min-width: 1920px){
        width: 45px;
        height: 45px;
        background-size: 27px 27px;
    }
`;

export const PlusButton = styled(SmallButton)`
    background-image: url(${plus});
    background-position: center left 49%;
    background-repeat: no-repeat;
`;

export const MinusButton = styled(SmallButton)`
    background-image: url(${minus});
    background-position: center;
    background-repeat: no-repeat;
`;