import React, { useEffect } from "react";
import { useDispatch, useSelector,  } from "react-redux";
import { MobileElementsWrapper, RoomImage } from "./styled";
import { 
    Wall,
    Floor,
    Tray,
    TableTop,
    Skirting,
    FloorBathroom,
    LivingFloor,
    selectElement,
    CheckboxElement
 } from "../elements";
import { getSelectedRoom, getSelectedId } from "../../store/selectors";
import palleteClear from "../../static/img/pallet-clear.jpg"
import elements_tabletop from "../../static/img/kitchen-furniture-clear.jpg"
import KitchenClearImg from "../../static/img/kitchen-clear.jpg";
import bathClear from "../../static/img/bath-clear.jpg"
import { 
    BATHROOM,
    LIVINGROOM,
    KITCHEN,
    CHILDREN_ROOM,
    BEDROOM,
    HALLWAY,
    BALCONY,
    LOGGIA,
    TABLE_TOP_TITLE,
    SKIRTING_TITLE,
    PALLETE,
    TILE,
    FLOOR_TILE,
    PAINT,
    WALLPERS,
    TILE_HOT_FLOOR,
    LVT_TILE,
    PARQUET,
    CARPET,
    LAMINATE,
    FLOOR,
    WALLS,
    LVT,
} from "../constants";
import { IElement, IElementType } from "./types";
import { Box } from "@material-ui/core";
import { TActiveSelectedId } from "../../store/elements/types";
import { TitleMobile } from "../title";
import { LAYERS_PAGE__RESET_SELECT } from "../../store/layers/layers-constants";
import { getRoomImage } from "./helpers";


const Element = ({
    type,
    solutions
}: IElement) => {
    const elements = {
        wall: <Wall solutions={solutions}/>,
        floor: <Floor solutions={solutions}/>,
        tray: <Tray solutions={solutions}/>,
        table_top:  <TableTop solutions={solutions}/>,
        skirting: <Skirting solutions={solutions}/>,
        floor_bathroom: <FloorBathroom solutions={solutions}/>,
        fllor_living: <LivingFloor solutions={solutions}/>,
    }
    return (
        <>
        {/* @ts-ignore*/}
            {elements[type]}
        </>
    )
}

export const ElementsSelector = () => {
    const dispatch = useDispatch();
    const selectedRoom = useSelector(getSelectedRoom)
    const selectedCheckbox = useSelector(getSelectedId)

    useEffect(()=> {
        dispatch({
            type: LAYERS_PAGE__RESET_SELECT,
        })
    })

    let  roomImage, elements: IElementType;
    switch (selectedRoom){
        case BATHROOM: {
            elements = {
                "wall": [ TILE, PAINT ],
                "floor_bathroom": [ TILE_HOT_FLOOR, LVT_TILE ],
                "tray": [ PALLETE ],
            }
            roomImage = selectedCheckbox === PALLETE ? palleteClear : bathClear;
            break
        }
        case BALCONY:{
            elements = {
                "wall": [PAINT],
                "floor": [LVT_TILE],
            }
            roomImage = getRoomImage(BALCONY, selectedCheckbox);
            break
        }
        case LOGGIA: {
            elements = {
                "wall": [PAINT],
                "floor": [FLOOR_TILE],
            }
            roomImage = getRoomImage(LOGGIA, selectedCheckbox);
            break
        } 
        case LIVINGROOM: {
            elements = {
                "wall": [ WALLPERS, PAINT ],
                "fllor_living": [
                    TILE_HOT_FLOOR, 
                    LVT_TILE,
                    PARQUET,
                    CARPET,
                    LAMINATE,
                ],
            }
            roomImage = getRoomImage(LIVINGROOM, selectedCheckbox);
            break
        }
        case KITCHEN: {
            elements = {
                "wall": [ WALLPERS, PAINT ],
                "floor": [TILE_HOT_FLOOR, LVT_TILE ],
                "table_top": [TABLE_TOP_TITLE],
                "skirting": [SKIRTING_TITLE],
            }
            roomImage = selectedCheckbox === TABLE_TOP_TITLE 
                || selectedCheckbox === SKIRTING_TITLE ? elements_tabletop : KitchenClearImg;
            break
        }
        case CHILDREN_ROOM: {
            elements = {
                "wall": [ WALLPERS, PAINT ],
                "fllor_living": [
                    PARQUET,
                    CARPET,
                    LAMINATE,
                    LVT
                ],
            }
            roomImage = getRoomImage(CHILDREN_ROOM, selectedCheckbox);
            break
        }
        case BEDROOM: {
            elements = {
                "wall": [ WALLPERS, PAINT ],
                "fllor_living": [
                    TILE_HOT_FLOOR, 
                    LVT_TILE,
                    PARQUET,
                    CARPET,
                    LAMINATE,
                ],
            }
            roomImage = getRoomImage(BEDROOM, selectedCheckbox);
            break
        }
        case HALLWAY: {
            elements = {
                "wall": [ TILE, PAINT ],
                "floor": [TILE_HOT_FLOOR, LVT_TILE ],
            }
            roomImage = getRoomImage(HALLWAY, selectedCheckbox);
            break
        }
    }


    return (
        <Box display="flex" flexDirection="column">
            <RoomImage image={roomImage} >
                {/* @ts-ignore*/}
                {Object.keys(elements).map((el: string) => <Element type={el} solutions={elements[el]} />)}
            </RoomImage>
            <MobileElementsWrapper marginTop="10px">
                {/* @ts-ignore*/}
                {Object.keys(elements).map((el: string) => <MobileElement type={el} solutions={elements[el]} />)}
            </MobileElementsWrapper>
        </Box>
    )
}

const MobileElement = ({
    type,
    solutions
}:IElement ) => {
    const dispatch = useDispatch();
    const selected = useSelector(getSelectedId);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => 
        dispatch(selectElement(event.target.value as TActiveSelectedId));

    const titles = {
        tray: PALLETE,
        wall: WALLS,
        floor: FLOOR,
        table_top:  TABLE_TOP_TITLE,
        skirting: SKIRTING_TITLE,
        floor_bathroom: FLOOR,
        fllor_living: FLOOR,
    }

    return (
        <Box 
            display="flex" 
            flex="1 1 100px" 
            marginRight="15px" 
            flexDirection="column"
            flexWrap="wrap"
            maxWidth="140px"
        >
            <Box alignSelf="center"><TitleMobile mb={5}>{titles[type]}</TitleMobile></Box>
            {
                solutions.map(el => <CheckboxElement 
                    key={el}
                    value={el} 
                    selected={selected}
                    changeHandler={changeHandler}
                />)  
            }
        </Box>
    )
}

