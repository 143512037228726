import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
    WallWrapper,
    FloorWrapper,
    TrayWrapper,
    Icon,
    TableTopWrapper,
    SkirtingWrapper,
    FloorWrapperBathroom,
    LivingFloorWrapper
} from "./styled"
import { LabelTextSmall } from "../text"
import WallIcon from "../../static/img/wall.svg";
import FloorIcon from "../../static/img/floor.svg"
import SkirtingIcon from "../../static/icons/skirting-icon.svg"
import { CheckBox } from "../check-box";
import { Block } from "../block";
import { 
    TILE_HOT_FLOOR,
    LVT_TILE,
    CARPET,
    LAMINATE,
    PARQUET,
    WALLPERS,
    PAINT,
    TILE,
    LVT,
} from "../constants";
import { ELEMENTS_PAGE__CHOOSE_SELECT } from "../../store/elements/elements-constants";
import { IElementSelectAction, TActiveSelectedId } from "../../store/elements/types";
import { getSelectedId } from "../../store/selectors";
import { IElementProps } from "./types"
import icon_heatfloor from "../../static/icons/Icon-heatfloor.svg";
import icon_plitka from "../../static/icons/Icon-plitka.svg";
import icon_carpet from "../../static/icons/Icon-kovrolin.svg";
import icon_parket from "../../static/icons/Icon-parket.svg";
import icon_oboi from "../../static/icons/Icon-oboi.svg"
import icon_kraska from "../../static/icons/Icon-kraska.svg"
import icon_lvt from "../../static/icons/ico-nlvt.svg"
import icon_tabletop from "../../static/icons/icon-stoleshnica.svg";

export const selectElement = (elementId: TActiveSelectedId): IElementSelectAction => ({
    type: ELEMENTS_PAGE__CHOOSE_SELECT,
    selectedId: elementId
});


const getFloorIcon = (selectId: TActiveSelectedId) => {
    switch (selectId) {
        case TILE_HOT_FLOOR:
            return icon_heatfloor
        case LVT_TILE:
            return icon_plitka
        case CARPET: 
            return icon_carpet
        case LVT: 
            return icon_lvt
        case LAMINATE:
        case PARQUET:
            return icon_parket
        default: 
            return FloorIcon
    } 
}

const getWallIcon = (selectId: TActiveSelectedId) => {
    switch (selectId) {
        case WALLPERS:
            return icon_oboi
        case PAINT:
            return icon_kraska
        case TILE: 
            return icon_plitka
        default: 
            return WallIcon
    } 
}

export const CheckboxElement = ({
    value,
    selected,
    changeHandler
}: {
    value: string;
    selected: TActiveSelectedId;
    changeHandler: (event:React.ChangeEvent<HTMLInputElement>) => void;
}) => {

    return  <CheckBox 
        value={value} 
        text={<LabelTextSmall>{value}</LabelTextSmall>} 
        checked={selected === value} 
        onChange={changeHandler}/>
    }


export const Wall = ({
    solutions
}: IElementProps) => {
    const dispatch = useDispatch();

    const selected = useSelector(getSelectedId)
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => 
        dispatch(selectElement(event.target.value as TActiveSelectedId));

    return (
        <WallWrapper>
            <Icon src={getWallIcon(selected)}/>
            <Block ml={10} flex flexDirection="column" justifyContent="space-between">
                {solutions.map((el) => <CheckboxElement 
                    value={el} 
                    selected={selected}
                    changeHandler={changeHandler}
                    />)
                }
            </Block>
        </WallWrapper>
    )
}

export const Floor = ({
    solutions
}: IElementProps) => {

    const dispatch = useDispatch();
    const selected = useSelector(getSelectedId);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => 
        dispatch(selectElement(event.target.value as TActiveSelectedId));

     return (
        <FloorWrapper>
            <Icon src={getFloorIcon(selected)}/>
            <Block ml={8} flex flexDirection="column" justifyContent="space-between"> 
                {solutions.map((el) => <CheckboxElement 
                    value={el} 
                    selected={selected}
                    changeHandler={changeHandler}
                    />)
                }
            </Block>
        </FloorWrapper>
    )
}

export const LivingFloor = ({
    solutions
}: IElementProps) => {

    const dispatch = useDispatch();
    const selected = useSelector(getSelectedId);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => 
        dispatch(selectElement(event.target.value as TActiveSelectedId));

     return (
        <LivingFloorWrapper>
            <Icon src={getFloorIcon(selected)}/>
            <Block ml={8} flex flexDirection="column" justifyContent="space-between"> 
                {solutions.map((el) => <CheckboxElement 
                    value={el} 
                    selected={selected}
                    changeHandler={changeHandler}
                    />)
                }
            </Block>
        </LivingFloorWrapper>
    )
}

export const FloorBathroom = ({
    solutions
}: IElementProps) => {

    const dispatch = useDispatch();
    const selected = useSelector(getSelectedId);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => 
        dispatch(selectElement(event.target.value as TActiveSelectedId));

     return (
        <FloorWrapperBathroom>
            <Icon src={getFloorIcon(selected)}/>
            <Block ml={8} flex flexDirection="column" justifyContent="space-between"> 
                {solutions.map((el) => <CheckboxElement 
                    value={el} 
                    selected={selected}
                    changeHandler={changeHandler}
                    />)
                }
            </Block>
        </FloorWrapperBathroom>
    )
}

export const Tray = ({
    solutions
}: IElementProps) => {
    const dispatch = useDispatch();
    const selected = useSelector(getSelectedId);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => 
        dispatch(selectElement(event.target.value as TActiveSelectedId));

    return (
        <TrayWrapper>
            <Icon src={FloorIcon}/>
            <Block ml={10} flex flexDirection="column" justifyContent="center"> 
                {solutions.map((el) => <CheckboxElement 
                    value={el} 
                    selected={selected}
                    changeHandler={changeHandler}
                    />)
                }
            </Block>
        </TrayWrapper>
    )
}

export const TableTop = ({
    solutions
}: IElementProps) => {
    const dispatch = useDispatch();
    const selected = useSelector(getSelectedId);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => 
        dispatch(selectElement(event.target.value as TActiveSelectedId));

    return (
        <TableTopWrapper>
            <Icon src={icon_tabletop}/>
            <Block ml={10} flex flexDirection="column" justifyContent="center"> 
                {solutions.map((el) => <CheckboxElement 
                    value={el} 
                    selected={selected}
                    changeHandler={changeHandler}
                    />)
                }
            </Block>
        </TableTopWrapper>
    )
}

export const Skirting = ({
    solutions
}: IElementProps) => {
    const dispatch = useDispatch();
    const selected = useSelector(getSelectedId);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => 
        dispatch(selectElement(event.target.value as TActiveSelectedId));

    return (
        <SkirtingWrapper>
            <Icon src={SkirtingIcon}/>
            <Block ml={10} flex flexDirection="column" justifyContent="center"> 
                {solutions.map((el) => <CheckboxElement 
                    value={el} 
                    selected={selected}
                    changeHandler={changeHandler}
                    />)
                }
            </Block>
        </SkirtingWrapper>
    )
}