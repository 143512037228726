import React, { memo } from "react";
import { TableRow } from "./table-row";
import { TableHeader } from "./table-header";
import { TableWrapper } from "./styled"; 

import { IResultRow } from "../../store/calculate/types";



export const Table = memo(({
    results,
}: {
    results: IResultRow[],
}) => {
    return (
        <TableWrapper id="calculate-tab">
            <TableHeader />
            {results?.map(el => (
                <TableRow 
                    key={el.uid}
                    {...el}
                />
            ))}
        </TableWrapper>
    )
})