import { Box } from "@material-ui/core";
import styled from "styled-components";


export const SizesBox = styled(Box)`
    margin-top:12px;
    margin-bottom: 25px;
    display: flex;

    @media (min-width: 320px){
        margin-left: 0;    
    };

    @media (min-width: 768px){
        margin-left: 60px;    
    };
`;

export const SliderBox = styled(Box)`
    @media (min-width: 320px){
        width: auto;   
    };

    @media (min-width: 768px){
        width: 50%;    
    };
`;