import React, { useCallback, useEffect } from "react";
import { MediumTitle, TitleMobile } from "../title";
import { 
    SELECT_LAYERS_TITLE,
    SELECT_MATERIALS,
} from "../constants";
import { 
    LAYERS_PAGE__LAYER_SELECT,
    LAYERS_PAGE__LAYER_UNSELECT,
} from "../../store/layers/layers-constants"
import { Box } from "@material-ui/core";
import { MaterialItem } from "../material-item";
import { LayresImage, SelectItemsBox, SelectItemsContainer } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { getCheckedLayers, getInputsValue, getSelectedId, getSelectedRoom } from "../../store/selectors";
import { data } from "../data";
import { getGlueInfo, getImage, getParams } from "../../utils";
import { UPDATE_CALCULATE_TAB } from "../../store/calculate/constants";




export const SelectLayers = () => {
    const dispatch = useDispatch();
    const checkedLayers = useSelector(getCheckedLayers)
    const selectedElem = useSelector(getSelectedId);
    const selectedRoom = useSelector(getSelectedRoom);
    const inputsValues = useSelector(getInputsValue);
    const image = getImage(selectedRoom,selectedElem);

    const calculate = useCallback(() => {
        //@ts-ignore
        return data[selectedRoom][selectedElem]?.filter(el => checkedLayers.includes(String(el.uid)))?.map((el, i) => {
            if (el.subMaterial){
                //@ts-ignore
                return  el.subMaterial.map((subel,i) => ({
                    name: `${subel.type} ${subel.material}`,
                    count: subel.formula(...getParams(subel.params, inputsValues, subel))?.toFixed(3) ,
                    id: el.id,
                    uid: el.uid,
                    link: subel.link
                }))
            }
            if(el.glue){
                const info = getGlueInfo( parseInt(inputsValues.tailWidth), parseInt(inputsValues.tailLength))
                return {
                    name: `${el.type} ${info.material}`,
                    count: el.formula(...getParams(el.params,inputsValues, el))?.toFixed(3) ,
                    id: el.id,
                    uid: el.uid,
                    link: info.link
                }
            }
            return {
                name: `${el.type} ${el.material}`,
                count: el.formula(...getParams(el.params,inputsValues, el))?.toFixed(3) ,
                id: el.id,
                uid: el.uid,
                link: el.link
            }
        }).flat();
    },[checkedLayers, selectedRoom, selectedElem, inputsValues]);

    useEffect(() => {
        dispatch({
            type: UPDATE_CALCULATE_TAB,
            results: calculate()
        })
    },[calculate, dispatch])
    

    const toggleCheck = (id: string) => {
        checkedLayers.includes(id) 
            ? dispatch({
                type: LAYERS_PAGE__LAYER_UNSELECT,
                selectedId: id
            })
            : dispatch({
                type: LAYERS_PAGE__LAYER_SELECT,
                selectedId: id
            })
    }

    return (
        <Box width="100%" display="flex" flexWrap="wrap">
            <Box marginRight="60px">
                <Box alignSelf="center"> <MediumTitle mt={10} mb={30}>{SELECT_LAYERS_TITLE}</MediumTitle></Box>
                <TitleMobile mt={10} mb={30}>{SELECT_LAYERS_TITLE}</TitleMobile>
                <LayresImage img={image} />
            </Box>
            <SelectItemsContainer>
                <Box alignSelf="center"> <MediumTitle mt={10} mb={30}>{SELECT_MATERIALS}</MediumTitle></Box>
                <TitleMobile mt={10} mb={30}>{SELECT_MATERIALS}</TitleMobile>
                <SelectItemsBox >
                    {
                        //@ts-ignore
                        !data[selectedRoom][selectedElem] && "нет формул и материалов"}
                    {
                        //@ts-ignore
                        data[selectedRoom][selectedElem]?.map((el, i) => {
                            if(el.glue){
                                const info = getGlueInfo( parseInt(inputsValues.tailWidth), parseInt(inputsValues.tailLength))
                                return <MaterialItem 
                                    value={String(el.uid)} 
                                    checked={checkedLayers.includes(String(el.uid))} 
                                    onChange={toggleCheck}
                                    name={info.material} 
                                    description={info.material} 
                                    title = {el.type}
                                    key={el.id} 
                                    number={i+1}
                                    img={info.img}
                                />
                            }

                            return <MaterialItem 
                                value={String(el.uid)} 
                                checked={checkedLayers.includes(String(el.uid))} 
                                onChange={toggleCheck}
                                name={el.material} 
                                description={el.material} 
                                title = {el.type}
                                key={el.id} 
                                number={i+1}
                                img={el.img}
                            />
                        })
                    }
                </SelectItemsBox>
            </SelectItemsContainer>
        </Box>
    )
    
}