export const INPUT_CHANGE: "INPUT_CHANGE" = "INPUT_CHANGE";
export const ADD_WINDOW_HOLE: "ADD_WINDOW_HOLE" = "ADD_WINDOW_HOLE";
export const REMOVE_WINDOW_HOLE: "REMOVE_WINDOW_HOLE" = "REMOVE_WINDOW_HOLE";
export const ADD_DOOR_HOLE: "ADD_DOOR_HOLE" = "ADD_DOOR_HOLE";
export const REMOVE_DOOR_HOLE: "REMOVE_DOOR_HOLE" = "REMOVE_DOOR_HOLE";
export const WINDOW_INPUT_CHANGE: "WINDOW_INPUT_CHANGE" = "WINDOW_INPUT_CHANGE";
export const DOOR_INPUT_CHANGE: "DOOR_INPUT_CHANGE" = "DOOR_INPUT_CHANGE";
export const square: "square" = "square";
export const wallSquare: "wallSquare" = "wallSquare";
export const levelingLayer: "levelingLayer" = "levelingLayer";
export const typeOfBase: "typeOfBase" = "typeOfBase";
export const elevationDifferences: "elevationDifferences" = "elevationDifferences";
export const tailLength: "tailLength" = "tailLength";
export const tailWidth: "tailWidth" = "tailWidth";
export const tailThickness: "tailThickness" = "tailThickness";
export const jointWidth: "jointWidth" = "jointWidth";
export const windowWidth: "windowWidth" = "windowWidth";
export const windowHeight: "windowHeight" = "windowHeight";
export const doorWidth: "doorWidth" = "doorWidth";
export const doorHeight: "doorHeight" = "doorHeight";
export const floorLength: "floorLength" = "floorLength";
export const floorWidth: "floorWidth" = "floorWidth";
export const palleteLength: "palleteLength" = "palleteLength";
export const palleteWidth: "palleteWidth" = "palleteWidth";
export const palleteHeight: "palleteHeight" = "palleteHeight";
export const countAngle: "countAngle" = "countAngle";
export const palleteWallHeight: "palleteWallHeight" = "palleteWallHeight";
export const palleteWallPerimetr: "palleteWallPerimetr" = "palleteWallPerimetr";
export const tableTopWeight: "tableTopWeight" = "tableTopWeight";
export const tableTopWidth: "tableTopWidth" = "tableTopWidth";
export const tableTopHeight: "tableTopHeight" = "tableTopHeight";
export const skirtingHeight: "skirtingHeight" = "skirtingHeight";
export const skirtingLenght: "skirtingLenght" = "skirtingLenght";