import React from "react";
import { useSelector } from "react-redux";
import { PageWrapper } from "../../components/page-wrapper";
import { Content } from "../../components/page-content";
import { FloorSizes } from "../../components/floor-sizes";
import { WallSizes } from "../../components/wall-sizes";
import{ PalletWaterproof } from "../../components/pallet-waterproof";

import { 
    TILE,
    PAINT,
    LVT,
    TILE_HOT_FLOOR,
    LVT_TILE,
    PALLETE,
    TABLE_TOP_TITLE,
    SKIRTING_TITLE,
    CARPET,
    LAMINATE,
    PARQUET,
    WALLPERS,
    FLOOR_TILE
} from "../../components/constants";
import { getSelectedId } from "../../store/selectors"
import { TableTop } from "../../components/table-top";
import { Skirting } from "../../components/skirting";
import { ButtonNext } from "../../components/button-next";
import { Box } from "@material-ui/core";

export const SizesPage = () => {
    const selectedElement = useSelector(getSelectedId)
    const sizesElement = (() => {
        switch (selectedElement) {
            case WALLPERS: 
            case TILE:
            case PAINT: return <WallSizes/>;
            case LVT:
            case LAMINATE:
            case PARQUET:
            case CARPET:
            case TILE_HOT_FLOOR: 
            case FLOOR_TILE: 
            case LVT_TILE : return <FloorSizes/>;
            case PALLETE : return <PalletWaterproof/>;
            case TABLE_TOP_TITLE : return <TableTop/>;
            case SKIRTING_TITLE : return <Skirting/>;
        } 
    })
    return (
        <PageWrapper>
            <Content>
                {sizesElement()}
                <Box display="flex" justifyContent="stretch" width="100%">
                    <ButtonNext path="/layers"/>
                    <Box flex={1}/>
                    <Box flex={1}/>
                </Box>
            </Content>
        </PageWrapper>
    )
}