import React from "react";
import { Content } from "./styled";
import { PageWrapper } from "../../components/page-wrapper";
import { Title, TitleMobile } from "../../components/title";
import { SELECT_ELEMENTS, SELECT_ELEMENTS_MIN } from "../../components/constants";
import { ElementsSelector } from "../../components/elements-selector"
import { ButtonNext } from "../../components/button-next";
import { Box } from "@material-ui/core";

export const ElementsPage = () => {
    return (
        <PageWrapper>
            <Content>
                <Title mt={10} mb={30}>{SELECT_ELEMENTS}</Title>
                <TitleMobile mt={10} mb={30}>{SELECT_ELEMENTS_MIN}</TitleMobile>
                <ElementsSelector/>
                <Box display="flex" justifyContent="stretch" width="100%">
                    <ButtonNext path="/sizes"/>
                    <Box flex={1}/>
                    <Box flex={1}/>
                </Box>
            </Content>
        </PageWrapper>
    )
}