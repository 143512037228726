import React from "react";
import { ITextInputProps } from "./types";
import { Input } from "./styled";


export const TextInput = ({
    value,
    onChange,
    width,
    height,
    name,
    type = "number", 
}: ITextInputProps) => {
    return (
        <>
            <Input 
                name={name} 
                width={width} 
                height={height} 
                type={type} 
                value={value} 
                onChange={(e) => onChange(e.target.value)}
                placeholder="Введите параметры"
                min="0,01"
            />
        </>
        
    )

}