import styled from "styled-components";
import { 
    INavItem,
    INavText
 } from "./types"

export const Wrapper = styled.nav`
    background-color: #0070BA;
    height: 936px;
    width: 102px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    left: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 57px 0;
    color: #FFFFFF;
    z-index: 1;

    @media (min-width: 320px){
        @media (min-height: 480px){
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 0;
            flex-direction: row;
            width: 100%;
            height: 60px;
            bottom: 0;
            padding: 0 4px;
            position: fixed;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 0;
            flex-direction: row;
            width: 100%;
            height: 70px;
            bottom: 0;
            padding: 0 4px;
            position: fixed;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 0;
            flex-direction: row;
            width: 100%;
            height: 77px;
            bottom: 0;
            padding: 0 4px;
            position: fixed;
        }
    };


    @media (min-width: 480px){
        top: calc(15% + 20px);
        font-size: 10px;
        width: 68px;
        height: 680px;
        flex-direction: column;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 0;
        padding: 57px 0;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 0;
            flex-direction: row;
            width: 100%;
            height: 90px;
            top: auto;
            bottom: 0;
            padding: 0 4px;
            position: fixed;
        }
    };

    @media (min-width: 768px){
        top: calc(15% + 20px);
        font-size: 10px;
        width: 68px;
        height: 680px;
        flex-direction: column;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 0;
        padding: 58px 0;
    };

    @media (min-width: 1280px){
        top: calc(15% + 20px);
        font-size: 10px;
        width: 68px;
        height: 624px;
        flex-direction: column;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 0;
        padding: 38px 0;
    };

    @media (min-width: 1920px){
        top: calc(15% + 20px);
        font-size: 15px;
        width: 102px;
        height: 936px;
        flex-direction: column;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 0;
        padding: 57px 0;
    };
`;

export const NavItem = styled.div<INavItem>`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    background-color: ${(p) => p.selected ? "#FFFFFF" : "#0070BA"};

    @media (min-width: 320px){
        @media (min-height: 480px){
            height: 100%;
            padding: 0 4px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            height: 100%;
            padding: 0 4px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            padding: 0 5px;
        }
    };


    @media (min-width: 480px){
        padding: 10px 0;
    };
    @media (min-width: 480px){
        @media (min-height: 800px){
            padding: 0 6px;
        }
    };

    @media (min-width: 768px){
        padding: 11px 0;
    };

    @media (min-width: 1920){
        padding: 18px 0;
    };
    
`;

export const Text = styled.span<INavText>`
    color:  ${(p) => p.color || "#FFFFFF" };
    @media (min-width: 320px){
        @media (min-height: 480px){
            font-size: 10px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            font-size: 12px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            font-size: 13px;
        }
    };


    @media (min-width: 480px){
        font-size: 15px;
    };
    @media (min-width: 480px){
        @media (min-height: 800px){
            font-size: 15px;
        }
    };

    @media (min-width: 768px){
        font-size: 10px;
    };

    @media (min-width: 1920){
        font-size: 15px;
    };
    
`;