import React from "react";
import { BigTitle, TitleMobile } from "../title";
import { 
    WIDTH,
    HEIGHT,
    TABLE_TOP_TITLE,
    WEIGHT_TABLE_TOP,
    TABLE_TOP_PARAMETRS,
} from "../constants";
import { Box } from "@material-ui/core";
import { BlackText, LabelTextBig } from "../text";
import { TextInput } from "../text-input";
import { getInputsValue } from "../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { INPUT_CHANGE } from "../../store/sizes/sizes-constants";
import TableTopImg from "../../static/img/kitchen-furniture-clear.jpg"



export const TableTop = () => {
    const dispatch = useDispatch();

    const inputsValue = useSelector(getInputsValue)

    const inputHandler = (value: string | number, name: string) => {
        dispatch({
            type: INPUT_CHANGE,
            name,
            value,
        })
    } 

    return (
        <>
            <Box alignSelf="center"> <BigTitle mt={10} mb={30}>{TABLE_TOP_TITLE}</BigTitle></Box>
            <TitleMobile mt={10} mb={30}>{TABLE_TOP_TITLE}</TitleMobile>
            <Box width="100%" display="flex">
                <Box display="flex" flexDirection="column" marginRight="70px">
                    <BlackText mb={9} mr={30}>{TABLE_TOP_PARAMETRS}</BlackText>
                    <Box display="flex" justifyContent="flex-end"  alignItems="center" marginBottom="30px">
                            <Box flex="1"><LabelTextBig textAlign="right" mr={15} mb={7}>{WEIGHT_TABLE_TOP}(мм)</LabelTextBig></Box>  
                            <Box flex="1">
                                <TextInput 
                                    value={inputsValue.tableTopWeight} 
                                    type="number"
                                    onChange={(value) => inputHandler(value, "tableTopWeight")}/>
                            </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom="30px">
                            <Box flex="1"><LabelTextBig textAlign="right" mr={15} mb={7}>{WIDTH}(м)</LabelTextBig></Box>  
                            <Box flex="1">
                                <TextInput 
                                    value={inputsValue.tableTopWidth} 
                                    type="number"
                                    onChange={(value) => inputHandler(value, "tableTopWidth")}/>
                                </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom="30px">
                            <Box flex="1"><LabelTextBig textAlign="right" mr={15} mb={7}>{HEIGHT}(м)</LabelTextBig></Box>  
                            <Box flex="1">
                                <TextInput 
                                    value={inputsValue.tableTopHeight} 
                                    type="number"
                                    onChange={(value) => inputHandler(value, "tableTopHeight")}/>
                            </Box>
                    </Box>
                </Box>
                <Box width="70vw" height="60vh" >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        background: `url(${TableTopImg}) no-repeat center`,
                        backgroundSize: "100%"
                    }} />
                </Box>
            </Box>
        </>
    )
    
}