import React from "react";
import { ISVGProps } from "./types"


export const PlusIcon = ({
    width = 18,
    height = 18,
    fill = "#006FB9",
}:ISVGProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.023 0.0378383L10.4915 0.037839L10.4211 10.2342L0.295057 10.2342V12.7658L10.4211 12.7658L10.4915 22.9622L13.023 22.9622L12.9527 12.7658L23.0787 12.7658V10.2342L12.9527 10.2342L13.023 0.0378383Z" fill="#0070BA"/>
    </svg>

    )
}