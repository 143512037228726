import { Slider } from '@material-ui/core';
import styled from "styled-components";

export const PrettoSlider = styled(Slider)`

    & .MuiSlider-marked {
        margin-bottom: 45px;
    }
    & .MuiSlider-rail {
        height: 5px;
        background: #0070BA;
        opacity: 1;
    }
    & .MuiSlider-mark {
        width: 13px;
        height: 13px; 
        background-color: #0070BA;
        border-radius: 50%;
        opacity: 1;
        top: 8px;
    }
    & .MuiSlider-thumb {
        width: 39px;
        height: 39px;
        background-color: #FFFFFF;
        border: 2px solid #0070BA;
        margin-top: -17px;
        margin-left: -12px;
        opacity: 1;
        &:focus, &:hover, &.Mui-active, &.Mui-focusVisible {
            box-shadow: inherit;
        };
        &:after {
            width: 15px;
            height: 15px; 
            background-color: #0070BA;
            border-radius: 50%;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        };
    };
    & .MuiSlider-markLabel {
        line-height: 1.2;
        font-size: 20px;
        padding: 0;
        width: 32px;
        height: 32px;
        margin-top: 18px;
        margin-left: 5px;
        transform: translateX(-35%);
        opacity: 1;
        color: #0070BA;
    };

    &.MuiSlider-marked {
        margin-bottom: 35px;
    }

    @media (min-width: 320px){

        & .MuiSlider-rail {
            height: 2px;
        }


        & .MuiSlider-mark {
            width: 5px;
            height: 5px;
            top: 12px;
        }

        & .MuiSlider-thumb {
            width: 20px;
            height: 20px;
            margin-top: -9px;
            margin-left: -9px;

            &:after {
                width: 8px;
                height: 8px; 
            }
        }

        & .MuiSlider-markLabel {
            line-height: 1.2;
            font-size: 10px;
            margin-top: 0;
        }
    };

    @media (min-width: 768px){
        & .MuiSlider-rail {
            height: 2px;
        }


        & .MuiSlider-mark {
            width: 13px;
            height: 13px; 
            top: 8px;
        }

        & .MuiSlider-thumb {
            width: 39px;
            height: 39px;
            margin-top: -17px;
            margin-left: -12px;

            &:after {
                width: 15px;
                height: 15px; 
            }
        }

        & .MuiSlider-markLabel {
            line-height: 1.2;
            font-size: 20px;
            margin-top: 18px;
        }
    };
`