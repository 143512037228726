import React from "react";
import { INextButton } from "./types";
import { NavLink } from 'react-router-dom';
import { Button } from "../button";
import { ButtonText } from "../text";
import { NEXT } from "../constants";
import { Box } from "@material-ui/core";

export const ButtonNext = ({
    path="/"
}: INextButton) => {
    return (
        <Box flex={1}  width="100%" margin="10px 0">
            <NavLink to={path}>
                <Button><ButtonText>{NEXT}</ButtonText></Button>
            </NavLink>
        </Box>
    )
}