import styled from "styled-components";
import allRooms from "../../static/img/all_room.png";
import bathroom from "../../static/img/bathroom.png";
import bedroom from "../../static/img/bedroom.png";
import balcony from "../../static/img/balcony.png";
import living_room from "../../static/img/living_room.png";
import kitchen from "../../static/img/kitchen.png";
import hallway from "../../static/img/hallway.png";
import childrens_room from "../../static/img/childrens_room.png";
import loggia from "../../static/img/loggia.png";
import { SelectComponent } from "../select";
import { Box } from "@material-ui/core";
import { IRoomImg } from "./types";


export const MobileBox = styled(Box)`
    @media (min-width: 480px){
        display: none !important;
    }; 
`;

export const RoomSelectorWrapper = styled(Box)`
    display: flex;
    flex-direction: column;


    @media (min-width: 320px){
        @media (min-height: 480px){
            margin-top: -55px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
        }
    };


    @media (min-width: 480px){

    };

    @media (min-width: 480px){
        @media (min-height: 800px){
 
        }
    };

    @media (min-width: 768px){

    };

    @media (min-width: 1024px){
    
    };

    @media (min-width: 1280px){

    };

    @media (min-width: 1920px){

    };
`;


export const Wrapper = styled.div`
    position: relative;
    top: 20px;
    width: 1270px;
    height: 940px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 330px;
            height: 244px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 367px;
            height: 272px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 406px;
            height: 300px;
        }
    };


    @media (min-width: 480px){
        width: 700px;
        height: 518px;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 471px;
            height: 349px;
        }
    };

    @media (min-width: 768px){
        width: 700px;
        height: 518px;
    };

    @media (min-width: 1024px){
        width: 945px;
        height: 699px;      
    };

    @media (min-width: 1280px){
        width: 848px;
        height: 627px;
    };

    @media (min-width: 1920px){
        width: 1273px;
        height: 942px;
    };
`;

export const SelectorStyled = styled(SelectComponent)`
    background: #F9F9F9;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
`;

export const Apartment = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background:  url(${allRooms}) center/100% no-repeat;
    filter: brightness(50%);
`;


const RoomImg = styled.div<IRoomImg>`
    position: absolute;
    opacity: 0;
    @media (max-width: 480px){
        opacity: ${({hovered}) => hovered ? 1 : 0};
    }; 
    @media (min-width: 480px){
        :hover {
            opacity: 1;
        }
    }
`;

export const Bathroom = styled(RoomImg)`
    background: url(${bathroom}) center/100% no-repeat; 

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 79px;
            height: 69px;
            top: 150px;
            left: 148px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 88px;
            height: 76px;
            top: 168px;
            left: 165px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 97px;
            height: 84px;
            top: 185px;
            left: 182px;
        }
    };

    @media (min-width: 480px){
        width: 168px;
        height: 146px;
        top: 318px;
        left: 315px;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 113px;
            height: 98px;
            top: 215px;
            left: 211px;
        }
    };

    @media (min-width: 768px){
        width: 168px;
        height: 146px;
        top: 318px;
        left: 315px;
    };

    @media (min-width: 1024px){
        width: 227px;
        height: 196px;
        top: 430px;
        left: 424px;
    };  

    @media (min-width: 1280px){
        width: 204px;
        height: 176px;
        top: 386px;
        left: 380px;
    };  

    @media (min-width: 1920px){
        width: 305px;
        height: 265px;
        top: 580px;
        left: 572px;
    };  
`;

export const Bedroom = styled(RoomImg)`
    background: url(${bedroom}) center/100% no-repeat; 

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 127px;
            height: 107px;
            top: 114px;
            left: 181px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 141px;
            height: 121px;
            top: 126px;
            left: 202px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 156px;
            height: 132px;
            top: 140px;
            left: 223px;
        }
    };

    @media (min-width: 480px) {
        width: 270px;
        height: 227px;
        top: 242px;
        left: 384px;
    }

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 182px;
            height: 155px;
            top: 163px;
            left: 258px;
        }
    };

    @media (min-width: 768px) {
        width: 270px;
        height: 227px;
        top: 242px;
        left: 384px;
    }

    @media (min-width: 1024px) {
        width: 363px;
        height: 282px;
        top: 338px;
        left: 519px;
    }

    @media (min-width: 1280px){
        width: 327px;
        height: 255px;
        top: 303px;
        left: 465px;
    };  

    @media (min-width: 1920px){
        width: 489px;
        height: 393px;
        top: 450px;
        left: 699px;
    };  
`;

export const Balcony = styled(RoomImg)`
    background: url(${balcony}) center/100% no-repeat; 

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 32px;
            height: 160px;
            top: 58px;
            left: 24px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 36px;
            height: 176px;
            top: 66px;
            left: 27px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 40px;
            height: 195px;
            top: 72px;
            left: 30px;
        }
    };

    @media (min-width: 480px){
        width: 68px;
        height: 401px;
        top: 91px;
        left: 52px;
    };  

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 46px;
            height: 270px;
            top: 63px;
            left: 35px;
        }
    };

    @media (min-width: 768px){
        width: 68px;
        height: 401px;
        top: 91px;
        left: 52px;
    };  

    @media (min-width: 1024px){
        width: 92px;
        height: 423px;
        top: 182px;
        left: 71px;
    };  

    @media (min-width: 1280px){
        width: 83px;
        height: 400px;
        top: 154px;
        left: 63px;
    };  

    @media (min-width: 1920px){
        width: 124px;
        height: 630px;
        top: 216px;
        left: 94px;
    };  
`;

export const LivingRoom = styled(RoomImg)`
    background: url(${living_room}) center/100% no-repeat; 

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 109px;
            height: 127px;
            top: 99px;
            left: 42px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 121px;
            height: 142px;
            top: 110px;
            left: 46px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 134px;
            height: 158px;
            top: 121px;
            left: 51px;
        }
    };

    @media (min-width: 480px){
        width: 230px;
        height: 259px;
        top: 215px;
        left: 88px;
    };  

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 154px;
            height: 181px;
            top: 143px;
            left: 59px;
        }
    };

    @media (min-width: 768px){
        width: 230px;
        height: 259px;
        top: 215px;
        left: 88px;
    };  

    @media (min-width: 1024px){
        width: 311px;
        height: 386px;
        top: 272px;
        left: 118px;
    };  

    @media (min-width: 1280px){
        width: 280px;
        height: 386px;
        top: 225px;
        left: 106px;
    };  

    @media (min-width: 1920px){
        width: 418px;
        height: 458px;
        top: 398px;
        left: 160px;
    };  
`;

export const Kitchen = styled(RoomImg)`
    background: url(${kitchen}) center/100% no-repeat; 

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 110px;
            height: 86px;
            top: 22px;
            left: 42px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 124px;
            height: 95px;
            top: 25px;
            left: 46px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 137px;
            height: 104px;
            top: 28px;
            left: 52px;
        }
    };

    @media (min-width: 480px){
        width: 236px;
        height: 204px;
        top: 36px;
        left: 89px;
    };  

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 158px;
            height: 121px;
            top: 33px;
            left: 60px;
        }
    };

    @media (min-width: 768px){
        width: 236px;
        height: 204px;
        top: 36px;
        left: 89px;
    };  

    @media (min-width: 1024px){
        width: 318px;
        height: 264px;
        top: 54px;
        left: 120px;
    };  

    @media (min-width: 1280px){
        width: 288px;
        height: 270px;
        top: 32px;
        left: 106px;
    };  

    @media (min-width: 1920px){
        width: 429px;
        height: 313px;
        top: 94px;
        left: 161px;
    };  

`;

export const Hallway = styled(RoomImg)`
    background: url(${hallway}) center/100% no-repeat; 

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 36px;
            height: 127px;
            top: 24px;
            left: 148px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 40px;
            height: 141px;
            top: 27px;
            left: 164px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 44px;
            height: 156px;
            top: 30px;
            left: 182px;
        }
    };

    @media (min-width: 480px){
        width: 76px;
        height: 269px;
        top: 51px;
        left: 313px;
    };  

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 51px;
            height: 184px;
            top: 34px;
            left: 211px;
        }
    };

    @media (min-width: 768px){
        width: 76px;
        height: 269px;
        top: 51px;
        left: 313px;
    };  

    @media (min-width: 1024px){
        width: 102px;
        height: 319px;
        top: 91px;
        left: 423px;
    };  

    @media (min-width: 1280px){
        width: 92px;
        height: 267px;
        top: 91px;
        left: 379px;
    };  

    @media (min-width: 1920px){
        width: 137px;
        height: 488px;
        top: 94px;
        left: 569px;
    };  
`;

export const CildrensRoom = styled(RoomImg)`
    background: url(${childrens_room}) center/100% no-repeat;

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 129px;
            height: 71px;
            top: 51px;
            left: 179px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 143px;
            height: 80px;
            top: 56px;
            left: 200px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 159px;
            height: 87px;
            top: 62px;
            left: 221px;
        }
    };


    @media (min-width: 480px){
        width: 274px;
        height: 159px;
        top: 103px;
        left: 380px;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 184px;
            height: 102px;
            top: 72px;
            left: 256px;
        }
    };

    @media (min-width: 768px){
        width: 274px;
        height: 159px;
        top: 103px;
        left: 380px;
    }; 
    
    @media (min-width: 1024px){
        width: 368px;
        height: 228px;
        top: 132px;
        left: 515px;
    };  

    @media (min-width: 1280px){
        width: 330px;
        height: 222px;
        top: 110px;
        left: 461px;
    };  

    @media (min-width: 1920px){
        width: 496px;
        height: 275px;
        top: 193px;
        left: 692px;
    };  
`;

export const Loggia = styled(RoomImg)`
    background: url(${loggia}) center/100% no-repeat; 

    @media (min-width: 320px){
        @media (min-height: 480px){
            width: 33px;
            height: 159px;
            top: 49px;
            left: 291px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            width: 37px;
            height: 167px;
            top: 60px;
            left: 323px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            width: 41px;
            height: 187px;
            top: 65px;
            left: 358px;
        }
    };

    @media (min-width: 480px){
        width: 70px;
        height: 342px;
        top: 102px;
        left: 617px;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            width: 48px;
            height: 206px;
            top: 82px;
            left: 415px;
        }
    };

    @media (min-width: 768px){
        width: 70px;
        height: 342px;
        top: 102px;
        left: 617px;
    };  

    @media (min-width: 1024px){
        width: 95px;
        height: 461px;
        top: 138px;
        left: 832px;
    };  
    
    @media (min-width: 1280px){
        width: 85px;
        height: 377px;
        top: 143px;
        left: 747px;
    };  

    @media (min-width: 1920px){
        width: 127px;
        height: 566px;
        top: 213px;
        left: 1122px;
    };  
`;