import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Apartment,
    Wrapper,
    Bathroom,
    Bedroom,
    Balcony,
    LivingRoom,
    Kitchen,
    Hallway,
    CildrensRoom,
    Loggia,
    RoomSelectorWrapper,
    MobileBox,    
} from "./styled";
import { ToolTip } from "../tooltip";
import balconyIcon  from "../../static/img/balcony-icon.svg";
import livingRoomIcon  from "../../static/img/living_room-icon.svg";
import bathroomIcon  from "../../static/img/bathroom-icon.svg";
import childrensRoom  from "../../static/img/childrens_room-icon.svg"
import kitchenIcon  from "../../static/img/kitchen-icon.svg"
import hallwayIcon  from "../../static/img/hallway-icon.svg"
import bedroomIcon  from "../../static/img/bedroom-icon.svg"
import {
    BATHROOM,
    LIVINGROOM,
    KITCHEN,
    CHILDREN_ROOM,
    BEDROOM,
    HALLWAY,
    BALCONY,
    LOGGIA,
    SELECT_ROOM_LABEL
} from "../constants";
import { ROOMS_SELECT } from "../../store/room-selector/rooms-constants";
import {
    IRoomSelectorProps
} from "./types";
import { TActiveRoomSelectedId } from "../../store/room-selector/types";
import { NavLink } from 'react-router-dom';
import { SelectComponent } from "../select";
import { Box } from "@material-ui/core";
import { LabelTextBig } from "../text";
import { getSelectedRoom } from "../../store/selectors";

const RoomSelectorComponent = ({
    prop
}: IRoomSelectorProps ) => {

    const dispatch = useDispatch();
    const room = useSelector(getSelectedRoom)
    const clickHandler = (id:TActiveRoomSelectedId ) => {
        dispatch({
            type: ROOMS_SELECT,
            selectedId: id,
        });

    } 

    return(
        <RoomSelectorWrapper >
            <Wrapper>
                <Apartment />
                <NavLink to={"/elements"}>
                    <Balcony onClick={() => clickHandler(BALCONY)} hovered={room===BALCONY}>
                        <ToolTip image={balconyIcon} title={BALCONY}/>
                    </Balcony>
                    <LivingRoom onClick={() => clickHandler(LIVINGROOM)} hovered={room===LIVINGROOM}>
                        <ToolTip image={livingRoomIcon} title={LIVINGROOM}/>
                    </LivingRoom>
                    <Loggia onClick={() => clickHandler(LOGGIA)} hovered={room===LOGGIA}>
                        <ToolTip position="left" image={balconyIcon} title={LOGGIA}/>
                    </Loggia >
                    <Bedroom onClick={() => clickHandler(BEDROOM)} hovered={room===BEDROOM}>
                        <ToolTip position="left" image={bedroomIcon} title={BEDROOM}/>
                    </Bedroom>
                    <Bathroom onClick={() => clickHandler(BATHROOM)} hovered={room===BATHROOM}>
                        <ToolTip image={bathroomIcon} title={BATHROOM}/>
                    </Bathroom>
                    <Kitchen onClick={() => clickHandler(KITCHEN)} hovered={room===KITCHEN}>
                        <ToolTip image={kitchenIcon} title={KITCHEN}/>
                    </Kitchen>
                    <Hallway onClick={() => clickHandler(HALLWAY)} hovered={room===HALLWAY}>
                        <ToolTip position="left" image={hallwayIcon} title={HALLWAY}/>
                    </Hallway>
                    <CildrensRoom onClick={() => clickHandler(CHILDREN_ROOM)} hovered={room===CHILDREN_ROOM}>
                        <ToolTip position="left" image={childrensRoom} title={CHILDREN_ROOM}/>
                    </CildrensRoom>
                </NavLink>
            </Wrapper>
            <MobileBox display="flex" marginLeft="35px" marginTop="25px" alignItems="center" marginRight="10px">
                <LabelTextBig>{SELECT_ROOM_LABEL}</LabelTextBig>
                <Box flex="1">
                    <SelectComponent 
                        options={[
                            BALCONY,
                            LIVINGROOM,
                            LOGGIA,
                            BEDROOM,
                            BATHROOM,
                            KITCHEN,
                            HALLWAY,
                            CHILDREN_ROOM,
                        ]} 
                        value={room}
                        onChange={(e)=> clickHandler(e.target.value as TActiveRoomSelectedId)}
                    />
                </Box>
            </MobileBox>
        </RoomSelectorWrapper>
    )
}




export const RoomSelector = memo(RoomSelectorComponent)
