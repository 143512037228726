import styled from "styled-components";
import { ITextProps } from "./types";

export const Text = styled.div<ITextProps>`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #0070BA;
    padding-right: ${({pr}) => pr ? pr : 0 }px;
    padding-left: ${({pl}) => pl ? pl : 0 }px;
    padding-top: ${({pt}) => pt ? pt : 0 }px;
    padding-bottom: ${({pb}) => pb ? pb : 0 }px;
    margin-right: ${({mr}) => mr ? mr : 0 }px;
    margin-left: ${({ml}) => ml ? ml : 0 }px;
    margin-top: ${({mt}) => mt ? mt : 0 }px;
    margin-bottom: ${({mb}) => mb ? mb : 0 }px;
    text-align: ${({textAlign}) => textAlign ? textAlign : "left" };

    @media (min-width: 320px){
        @media (min-height: 480px){
            font-weight: 400;
            font-size: 10px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            font-weight: 400;
            font-size: 11px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            font-weight: 400;
            font-size: 12px;
        }
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            font-weight: 400;
            font-size: 14px;
        }
    };

    @media (min-width: 768px){
        font-weight: 400;
        font-size: 16px;
    }; 

    @media (min-width: 1920px){
        font-weight: 400;
        font-size: 24px;
    };  
`;

export const LabelTextBig = styled(Text)`
    font-size: 12px;
    font-weight: 500;

    @media (min-width: 320px){
        font-size: 12px;  
    };

    @media (min-width: 375px){
        font-size: 14px;
        margin-top: 8px
    };

    @media (min-width: 414px){
        font-size: 16px;  
    };

    @media (min-width: 480px){
        font-size: 18px;
        margin-top: 8px
    };

    @media (min-width: 768px){
        font-size: 16px;  
    };

    @media (min-width: 1920px){
        font-size: 27px;
        margin-top: 8px
    };
`;

export const LabelTextSmall = styled(Text)`
    font-size: 12px;

    @media (min-width: 1024px){
        font-size: 15px; 
    };

    @media (min-width: 1280px){
        font-size: 14px;  
    };

    @media (min-width: 1920px){
        font-size: 21px;  
    };
`;



export const ButtonText = styled.div<ITextProps>`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
    padding-right: ${({pr}) => pr ? pr : 0 }px;
    padding-left: ${({pl}) => pl ? pl : 0 }px;
    padding-top: ${({pt}) => pt ? pt : 0 }px;
    padding-bottom: ${({pb}) => pb ? pb : 0 }px;
    margin-right: ${({mr}) => mr ? mr : 0 }px;
    margin-left: ${({ml}) => ml ? ml : 0 }px;
    margin-top: ${({mt}) => mt ? mt : 0 }px;
    margin-bottom: ${({mb}) => mb ? mb : 0 }px;

    @media (min-width: 320px){
        @media (min-height: 480px){
            font-size: 14px;
        }
    };



    @media (min-width: 768px){
        font-size: 12px;
    }; 

    @media (min-width: 1024px){
        font-size: 15px;
    }; 

    @media (min-width: 1280px){
        font-size: 14px;
    }; 

    @media (min-width: 1920px){
        font-size: 21px;
    };  
`;

export const BlackText = styled.div<ITextProps>`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    color: #1F1F1F;
    padding-right: ${({pr}) => pr ? pr : 0 }px;
    padding-left: ${({pl}) => pl ? pl : 0 }px;
    padding-top: ${({pt}) => pt ? pt : 0 }px;
    padding-bottom: ${({pb}) => pb ? pb : 0 }px;
    margin-right: ${({mr}) => mr ? mr : 0 }px;
    margin-left: ${({ml}) => ml ? ml : 0 }px;
    margin-top: ${({mt}) => mt ? mt : 0 }px;
    margin-bottom: ${({mb}) => mb ? mb : 0 }px;
    @media (min-width: 320px){
        font-size: 12px;  
    };

    @media (min-width: 375px){
        font-size: 14px;
        margin-top: 8px
    };

    @media (min-width: 414px){
        font-size: 16px;  
    };

    @media (min-width: 480px){
        font-size: 18px;
        margin-top: 8px
    };

    @media (min-width: 768px){
        font-size: 18px;
    };  

    @media (min-width: 1920px){
        font-size: 27px;
    };  
`;