import React from "react";
import { IMaterialItemProps } from "./types";
import {
    Image,
    BorderBox,
    DescriptionBlock,
    Title,
    Description,
} from "./styled"
import { Box } from "@material-ui/core";

export const AddonItem = ({
    title,
    description,
    img
}: IMaterialItemProps) => {

    return (
        <Box paddingTop="7px" marginBottom="15px" marginRight="15px" width="fit-content" >
                <BorderBox>
                    <Image src={img}/>
                    <DescriptionBlock>
                        <Title>{ title }</Title>
                        <Description>{ description }</Description>
                    </DescriptionBlock>
                </BorderBox>
        </Box>
        
    )
}