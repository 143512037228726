import styled from "styled-components";

export const Wrapper = styled.header`
    background-color: #0070BA;
    height: 102px;
    width: 100%;
    display: flex;
    justify-content: space-between;


    @media (min-width: 320px){
        @media (min-height: 480px){
            height: 53px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            height: 62px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            height: 69px;
        }
    };


    @media (min-width: 480px){
        height: 68px;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            height: 80px;
        }
    };

    @media (min-width: 768px){
        height: 68px;
    };

    @media (min-width: 1920px){
        height: 102px;
    };
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    padding: 15px 30px;
    img {

        @media (min-width: 320px){
            @media (min-height: 480px){
                width: 106px;
            }
        };

        @media (min-width: 375px){
            @media (min-height: 667px){
                width: 124px;
            }
        };

        @media (min-width: 414px){
            @media (min-height: 736px){
                width: 137px;
            }
        };


        @media (min-width: 480px){
            width: 159px;
        };

        @media (min-width: 480px){
            @media (min-height: 800px){
                width: 159px;
            }
        };

        @media (min-width: 768px){
            width: 163px;
        };

        @media (min-width: 1920px){
            width: 244px;
        };
    };
`;

export const Title = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    padding: 10px;
    color: #FFFFFF;
    @media (max-width: 480px) {
        display: none;
    }
`;

export const Сontacts = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 20px;
    font-size: 20px;
    @media (max-width: 1920px) {
        font-size: 20px;
    }
    @media (max-width: 1280px) {
        font-size: 20px;
    }
    @media (max-width: 768px) {
        font-size: 15px;
    }
    @media (max-width: 415px) {
        padding-right: 20px;
        font-size: 16px;
    }
    @media (max-width: 375px) {
        font-size: 15px;
    }
    @media (max-width: 320px) {
        font-size: 12px;
    }
`;

export const СontactItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;
    color: #FFFFFF;
`;

export const ContactImg = styled.img`
    margin-right: 12px;
`;
