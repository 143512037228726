import {createStore, combineReducers, compose } from "redux";
import { elements } from "./elements";
import { rooms } from "./room-selector";
import { layers } from "./layers"
import { sizes } from "./sizes";
import { calculate } from "./calculate"

let reducers = combineReducers({
    elements,
    rooms,
    layers,
    sizes,
    calculate
});

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(reducers,
compose(composeEnhancers && composeEnhancers())
);

export default store; 

export type StoreType = ReturnType<typeof reducers>