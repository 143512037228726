import { LAYERS_PAGE__LAYER_SELECT, LAYERS_PAGE__LAYER_UNSELECT, LAYERS_PAGE__SELECT_COLOR, LAYERS_PAGE__RESET_SELECT } from "./layers-constants";
import { ILayersState, TLayersActions } from "./types"


const defaultState: ILayersState = {
    layerSelectIds: [],
    selectedColorId: "1",
}

export const layers = (
    state: ILayersState = defaultState,
    action: TLayersActions
) => {
    switch (action.type) {
        case LAYERS_PAGE__LAYER_SELECT: {
            return {
                ...state,
                layerSelectIds: [...state.layerSelectIds, action.selectedId ]
            }
        }
        case LAYERS_PAGE__LAYER_UNSELECT: {
            return {
                ...state,
                layerSelectIds: state.layerSelectIds.filter(el => el !== action.selectedId)
            }
        }
        case LAYERS_PAGE__RESET_SELECT: {
            return {
                ...state,
                layerSelectIds: []
            }
        }
        case LAYERS_PAGE__SELECT_COLOR: {
            return {
                ...state,
                selectedColorId: action.id
            }
        }
        default:
            return state;
    }
};