import React from "react";
import { Label, CheckInput, Check, Icon, BigCheck } from "./styled";
import { LabelTextSmall, LabelTextBig } from "../text";
import { ICheckBoxProps, IBigCheckBoxProps } from "./types";

export const CheckBox = ({
    text,
    onChange,
    checked,
    value,
    icon
}: ICheckBoxProps) => {

    return (
        <Label>
            <CheckInput type="checkbox" value={value} checked={checked} onChange={onChange}/>
            <Check>
                {icon ? <Icon>{icon}</Icon> : null}
                { typeof text === "string" ? <LabelTextSmall mt={2}>{text}</LabelTextSmall> : text}
                
            </Check>
        </Label>
    )
}

export const BigCheckBox = ({
    text,
    onChange,
    checked,
    value,
    icon
}: IBigCheckBoxProps) => {

    return (
        <Label>
            <CheckInput type="checkbox" value={value} checked={checked} onChange={(e) => onChange(e.target.checked) }/>
            <BigCheck>
                {icon ? <Icon>{icon}</Icon> : null}
                { typeof text === "string" ? <LabelTextBig mt={2}>{text}</LabelTextBig> : text}
                
            </BigCheck>
        </Label>
    )
}