import React from "react";
import { RoomSelector } from "../../components/room-selector";
import { Title, TitleMobile } from "../../components/title";
import { SELECT_ROOM, SELECT_ROOM_MOBILE } from "../../components/constants";
import { MobileButton, RoomPageWrapper } from "./styled";
import { Box } from "@material-ui/core";
import { ButtonNext } from "../../components/button-next";

export const RoomPage = ({match}:any) => {
    return (
        <RoomPageWrapper>
            <Title ml={65} mb={30}>{SELECT_ROOM}</Title>
            <TitleMobile ml={65} mb={30}>{SELECT_ROOM_MOBILE}</TitleMobile>
            <RoomSelector/>
            <MobileButton display="flex" justifyContent="stretch" width="100%" padding="0 10px">
                <Box flex={1}/>
                <Box flex={1}/>
                <ButtonNext path="/elements"/>
            </MobileButton>
        </RoomPageWrapper>
    )
}
