import { Box } from "@material-ui/core";
import React, { useState, memo } from "react";
import { LabelTextSmall } from "../text";
import { Marker, RowWrapper } from "./styled";
import { DeleteXIcon, PlusIcon } from "../../static/icons"; 

const Link = ({
    name,
    link
}: {
    name: string;
    link: string[] | undefined;
}) => {
    return (
        <>
            {link && name.split("/").map((el,i, arr) =>  {
                return i !== arr.length-1
                    ? <><a key={link[i]} target="_blank" rel="noreferrer" href={link[i]}>{el}</a>/</>
                    : <a key={link[i]} target="_blank" rel="noreferrer" href={link[i]}>{el}</a>
            })}
            {!link && name.split("/").map((el,i, arr) =>  {
                return i !== arr.length-1
                    ? <><a key={el} href="#">{el}</a>/</>
                    : <a key={el} href="#">{el}</a>
            })}
        </> 
    )
}



export const TableRow = memo(({
    name,
    count,
    id,
    link
}:{
    name: string
    count: string
    id: string | number
    link: string[]
}) => {

    const [selected, setSelected] = useState(true)

    return (
        <RowWrapper>
            <Box display="flex" alignItems={"center"} >
                <Marker />
                <LabelTextSmall><Link name={name} link={link}/></LabelTextSmall>
            </Box>
            <Box display="flex" justifyContent="center" alignItems={"center"}>
                <LabelTextSmall>{count}</LabelTextSmall>
            </Box>
            <Box display="flex" justifyContent="center" alignItems={"center"}>
                <LabelTextSmall>{id}</LabelTextSmall>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems={"center"}>
                {selected 
                ? <Box onClick={()=> setSelected(false)} ><DeleteXIcon /></Box> 
                : <Box onClick={()=> setSelected(true)} ><PlusIcon /></Box>}
            </Box>
           
        </RowWrapper>
    )
})