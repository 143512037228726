import styled from "styled-components";
import { IColor, IBlock } from "./types";

export const Color = styled.div<IColor>`
    flex: 3;
    background-color: ${({color}) => color};
    width: 100%;
`;

export const Name = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #1F1F1F;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-top: 2px solid #EDEDED;
`;

export const Block = styled.div<IBlock>`
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    box-shadow: 6px 6px 9px rgba(0, 0, 0, 0.25);
    ${({checked}) => checked && "border: 2px solid #0070BA"};
    width: 167px;
    height: 222px;
    margin-right: 50px;
    margin-bottom: 50px;

    :hover {
        border: 2px solid #0070BA;
    }
`;