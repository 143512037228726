import styled from "styled-components";

export const PageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;   

    @media (min-width: 320px){
        @media (min-height: 480px){
            left: 0;
            top: 53px;
            padding-bottom: 100px;
        }
    };

    @media (min-width: 375px){
        @media (min-height: 667px){
            left: 0;
            top: 62px;
            padding-bottom: 100px;
        }
    };

    @media (min-width: 414px){
        @media (min-height: 736px){
            left: 0;
            top: 69px;
            padding-bottom: 100px;
        }
    };


    @media (min-width: 480px){
        top: 80px;
        padding-left: 68px;
    };

    @media (min-width: 480px){
        @media (min-height: 800px){
            left: 0;
            top: 80px;
            padding-bottom: 100px;
        }
    };

    @media (min-width: 768px){
        top: 68px;
        padding-left: 68px;
    };

    @media (min-width: 1024px){
        top: 68px;
        padding-left: 68px;    
    };

    @media (min-width: 1280px){
        top: 68px;
        padding-left: 68px;
    };

    @media (min-width: 1920px){
        top: 138px;
        padding-left: 102px;
    };
`;